/*eslint-disable*/
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import downloadXlsx from "config/xlsx";
import { setNotification, useMaterialUIController } from "context";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { formatDate } from "utils/formatDate";

export default function Step5({ planId, nextStep }) {
  const [controller, dispatch] = useMaterialUIController();
  const [expandedRows, setExpandedRows] = useState([]);
  const [plan, setPlan] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [data, setData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceListAux, setServiceListAux] = useState([]);
  const [hasPlanId, setHasPlanId] = useState(false);
  const [program, setProgram] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const axios = Axios();
  const dateInputRefs = useRef([]);

  const idPlan = id || planId;

  const handleDateInitial = (serviceId, event) => {
    setServiceListAux((prevServiceList) => {
      const newServiceList = prevServiceList.map((service) => {
        if (service.idUnique === serviceId) {
          const dateInitial = parseInt(event.target.value, 10) || 0;
          const dateFinish = service.dateFinish ? parseInt(service.dateFinish, 10) : 0;
          const previousIndexSum =
            service.index > 0 ? prevServiceList[service.index - 1].indexSum : 0;
          const sum = dateInitial + dateFinish + previousIndexSum;

          return {
            ...service,
            dateInitial: event.target.value,
            indexSum: sum,
          };
        }
        return service;
      });

      const updatedServiceList = updateIndexes(newServiceList);

      setServiceListAux(updatedServiceList);

      return updatedServiceList;
    });
  };

  const handleDateFinish = (serviceId, event) => {
    setServiceListAux((prevServiceList) => {
      const newServiceList = prevServiceList.map((service) => {
        if (service.idUnique === serviceId) {
          const dateInitial = service.dateInitial ? parseInt(service.dateInitial, 10) : 0;
          const dateFinish = parseInt(event.target.value, 10) || 0;
          const previousIndexSum =
            service.index > 0 ? prevServiceList[service.index - 1].indexSum : 0;
          const sum = dateInitial + dateFinish + previousIndexSum;

          return {
            ...service,
            dateFinish: event.target.value,
            indexSum: sum,
          };
        }
        return service;
      });

      const updatedServiceList = updateIndexes(newServiceList);

      setServiceListAux(updatedServiceList);

      return updatedServiceList;
    });
  };

  useEffect(() => {
    if (serviceListAux) {
      updateIndexes(serviceListAux);
    }
  }, [serviceListAux]);

  const updateIndexes = (serviceList) => {
    const baseServiceIndex = serviceList.findIndex((service) => service.defaultService);

    if (baseServiceIndex !== -1) {
      let previousIndex = 0;

      const updatedServiceList = serviceList.map((service, index) => {
        if (index >= baseServiceIndex) {
          const dateInitial = service.dateInitial ? parseInt(service.dateInitial, 10) : 0;
          const dateFinish = service.dateFinish ? parseInt(service.dateFinish, 10) : 0;
          const sum = dateInitial;

          const updatedService = {
            ...service,
            dateInitial: dateInitial.toString(),
            dateFinish: dateFinish.toString(),
            indexSum: previousIndex + sum,
          };

          previousIndex = updatedService.indexSum;
          return updatedService;
        } else {
          const dateInitial = service.dateInitial ? parseInt(service.dateInitial, 10) : 0;
          const dateFinish = service.dateFinish ? parseInt(service.dateFinish, 10) : 0;
          const sum = dateInitial;

          return {
            ...service,
            dateInitial: dateInitial.toString(),
            dateFinish: dateFinish.toString(),
            indexSum: sum,
          };
        }
      });

      return updatedServiceList;
    }

    return serviceList;
  };

  function handleCheckboxChange(idUnique) {
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const newSelectedCheckboxes = prevSelectedCheckboxes.map((isSelected, index) => {
        return serviceListAux[index].idUnique === idUnique;
      });
      return newSelectedCheckboxes;
    });

    setServiceListAux((prevServiceList) => {
      const newServiceList = prevServiceList.map((service) => {
        if (service.idUnique === idUnique) {
          return {
            ...service,
            dateInitial: "0",
            dateFinish: "0",
          };
        }
        return service;
      });
      return newServiceList;
    });
  }

  useEffect(() => {
    const updatedServiceList = serviceListAux.map((service, index) => {
      const selectedCheckbox = selectedCheckboxes[index];

      return {
        ...service,
        defaultService: selectedCheckbox ? selectedCheckbox : false,
      };
    });

    const checkUpdatedSum = updateIndexes(updatedServiceList);
    setServiceListAux(checkUpdatedSum);
  }, [selectedCheckboxes]);

  useEffect(() => {
    axios
      .get(`/v1/plans/${idPlan}`)

      .then((res) => {
        if (res) {
          setPlan(res.data);
          const services = res.data.services;
          setServiceList(services);
          setServiceListAux(services);

          const initialSelectedCheckboxes = services.map((service) =>
            service.defaultService !== undefined ? service.defaultService : false
          );
          setSelectedCheckboxes(initialSelectedCheckboxes);

          if (services && services.length === 1) {
            setExpandedRows([0]);
          }
        }
      });

    axios.get(`/v1/plans/${idPlan}`).then((res) => {
      setData(res.data);
    });
  }, [idPlan]);

  useEffect(() => {
    axios.get(`/v1/program/${idPlan}/plan`).then((res) => {
      if (res.data.length > 0) {
        setProgram(true);
      }
    });
  }, [idPlan]);

  const handleRowClick = (index) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  const downloadExcel = () => {
    const rows = [];
    for (const f of plan.services) {
      for (const p of f.products) {
        const prods = rows.map((r) => r.product);
        const indexProd = prods.indexOf(p.name);
        if (indexProd !== -1) {
          rows[indexProd].perHectare += parseInt(p.perHectare, 10);
        } else {
          rows.push({
            category: p.category,
            product: p.name,
            unit: p.unit,
            perHectare: parseInt(p.perHectare, 10) || 0,
          });
        }
      }
    }

    downloadXlsx(plan, rows);
  };

  const isAnyCheckboxSelected = () => {
    return selectedCheckboxes.some((isSelected) => isSelected);
  };

  const submitPlan = () => {
    if (!isAnyCheckboxSelected()) {
      setNotification(dispatch, {
        open: true,
        title: "Selecione pelo menos um serviço base",
        severity: "error",
      });

      return;
    }

    axios
      .patch(`/v1/plans/status/${idPlan}`, {
        status: "OS",
        services: serviceListAux,
      })
      .then(() => {
        setNotification(dispatch, {
          open: true,
          title: "Plano salvo",
          color: "success",
          icon: "success",
          severity: "success",
        });
        nextStep();
      });
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;

    const updateServiceList = Array.from(serviceList);
    const [draggedServiceList] = updateServiceList.splice(droppedItem.source.index, 1);
    updateServiceList.splice(droppedItem.destination.index, 0, draggedServiceList);

    setServiceList(updateServiceList);

    const updatedServiceListAux = Array.from(serviceListAux);
    const [draggedServiceAux] = updatedServiceListAux.splice(droppedItem.source.index, 1);
    updatedServiceListAux.splice(droppedItem.destination.index, 0, draggedServiceAux);

    const updatedIndexes = updateIndexes(updatedServiceListAux);

    const updatedSelectedCheckboxes = Array.from(selectedCheckboxes);
    const [draggedCheckbox] = updatedSelectedCheckboxes.splice(droppedItem.source.index, 1);
    updatedSelectedCheckboxes.splice(droppedItem.destination.index, 0, draggedCheckbox);

    setServiceListAux(updatedIndexes);
    setSelectedCheckboxes(updatedSelectedCheckboxes);
  };

  return (
    <>
      <TableContainer style={{ marginBottom: "1em" }}>
        <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
              <TableCell style={{ color: "inherit" }} colSpan={5} align="center">
                {plan.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="20%">ID: PLAN-00{plan?.id}</TableCell>
              <TableCell width="20%">Cliente: {data?.customer?.name}</TableCell>
              <TableCell width="20%">Fazenda: {data?.farm?.name} </TableCell>
              <TableCell width="20%">Cultura: {data?.crop?.name}</TableCell>
              <TableCell width="20%">Data de Criação: {formatDate(plan?.startDate)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {!isAnyCheckboxSelected() ? (
        <h5 style={{ marginBottom: "1em", color: "#5D5D5D", fontWeight: 300 }}>
          Selecione um serviço base para habilitar os parametros de data.
        </h5>
      ) : (
        ""
      )}

      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
              {serviceListAux.map((service, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <TableContainer
                      key={index}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={{
                        border: selectedCheckboxes[index] ? "5px solid #7D9D4A" : "none",
                        marginBottom: "1rem",
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Table
                        sx={{
                          minWidth: 650,
                        }}
                        aria-label="caption table"
                      >
                        <TableHead style={{ display: "contents" }}>
                          <TableRow
                            style={{
                              backgroundColor: "white",
                              color: "black",
                            }}
                          >
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "inherit",
                                textTransform: "uppercase",
                              }}
                              colSpan={7}
                              align="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => handleRowClick(index)}
                              >
                                <span style={{ marginRight: "8px" }}>{service?.name}</span>
                                {expandedRows.includes(index) ? (
                                  <KeyboardArrowUpIcon fontSize="medium" strokeWidth={2} />
                                ) : (
                                  <KeyboardArrowDownIcon fontSize="medium" strokeWidth={2} />
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2rem",
                                  justifyContent: "flex-end",
                                  marginTop: "-1rem",
                                }}
                              >
                                {/* Conditional rendering for Date Inicial */}
                                {!selectedCheckboxes[index] && (
                                  <MDInput
                                    style={{
                                      width: "15%",
                                      margin: "0px !important",
                                      padding: "0px",
                                      borderRadius: "7px",
                                    }}
                                    value={service?.dateInitial || ""}
                                    type="number"
                                    label="Parâmetro Data"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                    disabled={!isAnyCheckboxSelected() ? true : false}
                                    onChange={(event) => {
                                      handleDateInitial(service?.idUnique, event);
                                    }}
                                  />
                                )}

                                {!selectedCheckboxes[index] && (
                                  <MDInput
                                    style={{
                                      width: "15%",
                                      margin: "0px !important",
                                      padding: "0px",
                                      borderRadius: "7px",
                                    }}
                                    disabled={!isAnyCheckboxSelected() ? true : false}
                                    type="number"
                                    label="Intervalo"
                                    value={service?.dateFinish || ""}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                    onChange={(event) => {
                                      if (event.target.value < 0) {
                                        return;
                                      }

                                      handleDateFinish(service?.idUnique, event);
                                    }}
                                  />
                                )}

                                {!selectedCheckboxes[index] && (
                                  <MDInput
                                    style={{
                                      width: "15%",
                                      margin: "0px !important",
                                      padding: "0px",
                                      borderRadius: "7px",
                                    }}
                                    type="number"
                                    label="Indice"
                                    value={service?.indexSum || ""}
                                    disabled
                                  />
                                )}

                                <FormControlLabel
                                  style={{ marginTop: "1rem" }}
                                  control={
                                    <Checkbox
                                      checked={selectedCheckboxes[index] || false}
                                      onChange={(event) => {
                                        event.stopPropagation();
                                        handleCheckboxChange(service?.idUnique);
                                      }}
                                      name="checkedAll"
                                      color="primary"
                                      required
                                      disabled={program === true ? true : false}
                                    />
                                  }
                                  label="Serviço Base"
                                  onClick={(event) => event.stopPropagation()}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ padding: "0px" }}>
                              <Collapse
                                in={expandedRows.includes(index)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Table>
                                  <TableBody>
                                    <TableRow
                                      style={{ backgroundColor: "#40404099", color: "white" }}
                                    >
                                      <TableCell style={{ color: "inherit" }}>Produto</TableCell>
                                      <TableCell style={{ color: "inherit" }}>Categoria</TableCell>
                                      <TableCell style={{ color: "inherit" }}>Unidade</TableCell>
                                      <TableCell style={{ color: "inherit" }}>Área</TableCell>
                                      <TableCell style={{ color: "inherit" }}>Dose/ha</TableCell>
                                    </TableRow>
                                    {service?.products?.map((product, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{product?.name}</TableCell>
                                        <TableCell>{product?.category}</TableCell>
                                        <TableCell>{product?.unit}</TableCell>
                                        <TableCell>{plan?.area}</TableCell>
                                        <TableCell>{product?.perHectare}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} textAlign="end">
          <Stack direction="row-reverse" spacing={2} style={{ textAlign: "end" }}>
            <MDButton variant="gradient" color="dark" onClick={submitPlan}>
              continuar planejamento
            </MDButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
