/*eslint-disable*/
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import { setIsLoading, useMaterialUIController } from "context";
import Axios from "utils/axiosInstance";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step5 from "./steps/step5";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step6 from "./steps/step6";
import Step7 from "./steps/step7";

const steps = [
  "Cadastro do Cabeçalho",
  "Cadastro Serviço e Produtos",
  "Cadastro de Preço",
  "Orçamento",
  "Cronograma de Serviço",
  "Programa por Gleba",
  "Data Base",
];


const status = ["HEADER", "SERVICES_AND_PRODUCTS", "SET_PRICES", "FINISH", "CHECKUP", "OS", "BASE"];

export default function CreatePlan() {
  const [idPlan, setIdPlan] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { isLoading } = controller;
  const [activeStep, setActiveStep] = useState(0);
  const axios = Axios();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsLoading(dispatch, !isLoading);
      axios.get(`/v1/plans/${id}`).then((res) => {
        setActiveStep(status.indexOf(res.data.status));
        setIsLoading(dispatch, false);
      });
    }
  }, [id]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const nextStep = (planId = null) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (planId) {
      setIdPlan(planId);
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Step1 planId={idPlan} nextStep={nextStep} />;
      case 1:
        return <Step2 planId={idPlan} nextStep={nextStep} />;
      case 2:
        return <Step3 planId={idPlan} nextStep={nextStep} />;
      case 3:
        return <Step4 planId={idPlan} nextStep={nextStep} />;
      case 4:
        return <Step5 planId={idPlan} nextStep={nextStep} />;
      case 5:
        return <Step6 planId={idPlan} nextStep={nextStep} />;
      case 6:
        return <Step7 planId={idPlan} nextStep={nextStep} />;
      default:
        return "Unknown step";
    }
  };

  return (
    <Box p={3} sx={{ width: "100%" }}>
      <Stepper style={{ marginBottom: "2em" }} activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {getStepContent()}
    </Box>
  );
}
