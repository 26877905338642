/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import bgImage from "assets/images/login-background.jpg";
import logo from "assets/images/logo.png";

import { useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const axios = Axios(false);
  // const [rememberMe, setRememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const submitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/v1/auth/login", { email, password })
      .then((res) => {
        setLoading(false);
        const user = res.data;
        localStorage.setItem("user", JSON.stringify(user));
        if (user.accountVerified === false) {
          navigate("/auth/resetPassword");
        } else if (user?.role === "admin") {
          navigate("/administradores");
        } else if (user?.role === "manager" || user?.role === "owner") {
          navigate(`/agenda/${user?.customer.id}`);
        } else {
          navigate("/page404");
        }
      })
      .catch(() => {
        setLoading(false);
        // setNotificationError(true);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <div style={{ width: "100%", textAlign: "center" }}>
          <MDBox component="img" src={logo} alt="Brand" width="15rem" alignContent="center" />
        </div>
        <form onSubmit={submitLogin}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Chave de acesso"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  required
                  fullWidth
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={loading}
                  type="submit"
                  fullWidth
                >
                  {loading && (
                    <CircularProgress color="white" size="1.5em" style={{ marginRight: "1em" }} />
                  )}{" "}
                  Entrar
                </MDButton>
              </MDBox>
              {/* <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Não tem uma conta?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    registrar
                  </MDTypography>
                </MDTypography>
              </MDBox> */}
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
