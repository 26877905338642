/*eslint-disable*/
import React from "react";

import { Button } from "@mui/material";
import { useMaterialUIController } from "context";
import { quoteGenerateXlxs } from "layouts/reports/quote";
import { useEffect, useState } from "react";

export default function QuoteReports() {
  const [controller] = useMaterialUIController();
  const { reportContent } = controller;

  const [datesInitial, setDatesInitial] = useState([]);
  const [services, setServices] = React.useState([]);
  const [sproducts, setProducts] = React.useState([]);
  const [suppliers, setSuppliers] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [farm, setFarm] = React.useState([]);
  const [userName, setUserName] = useState();

  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
  }, []);

  useEffect(() => {
    if (reportContent) {
      const getReportsInitial = reportContent.flatMap((report) => {
        return report.program_service.newDateInitial;
      });

      const getServices = reportContent.flatMap((report) => {
        return {
          ...report.program_service,
          plan: report.plan,
          soil: report.soil,
        };
      });

      const getProducts = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {
          return product;
        });
      });

      const productsConsolidated = getProducts.reduce((acc, product) => {
        const productIndex = acc.findIndex((item) => item.name === product.name);
        if (productIndex === -1) {
          return [...acc, product];
        } else {
          const productFound = acc[productIndex];

          const productFoundHectare = productFound.perHectare.toString().includes(",")
            ? productFound.perHectare.replace(",", ".")
            : productFound.perHectare;
          const productHectare = product.perHectare.includes(",")
            ? product.perHectare.replace(",", ".")
            : product.perHectare;

          const productUpdated = {
            ...productFound,
            perHectare: parseFloat(productFoundHectare) + parseFloat(productHectare),
          };
          return [...acc.slice(0, productIndex), productUpdated, ...acc.slice(productIndex + 1)];
        }
      }, []);

      const getCategory = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => product.category);
      });

      const getSuppliers = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => product.supplier);
      });

      const getFarm = reportContent.flatMap((report) => {
        return report.plan.farm.name;
      });

      setFarm(removeDuplicateDates(getFarm));
      setDatesInitial(removeDuplicateDates(getReportsInitial));
      setServices(getServices, reportContent);
      setProducts(productsConsolidated);
      setSuppliers(removeDuplicateDates(getSuppliers));
      setCategories(removeDuplicateDates(getCategory));
    }
  }, [reportContent]);

  const handleGenerateXLSX = () => {
    quoteGenerateXlxs(reportContent, farm, services, sproducts, suppliers, categories, userName);
  };
  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        variant="contained"
        textColor="white"
        style={{ color: "white" }}
        onClick={handleGenerateXLSX}
      >
        Gerar Planilha
      </Button>
    </div>
  );
}
