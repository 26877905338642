/* eslint-disable */

import logoImg from "assets/images/logo_nuagro.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

export const generatePDF = (service) => {
  const totalPerHectare = service.products.reduce(
    (acc, product) => Number(acc) + Number(product.perHectare),
    0
  );

  if (service.pump && service.pump.enable && service.pump.pump) {
    if (service.pump.pump && service.pump.pump.includes(",")) {
      service.pump.pump = service.pump.pump.replace(",", ".");

      service.pump.pump = Number(service.pump.pump);
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));

  const doc = new jsPDF();

  autoTable(doc, {
    theme: "plain",
    didDrawCell: function (data) {
      if (data.row.index === data.table.body.length - 1) {
        doc.setLineWidth(0.5);
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }
    },
    body: [
      [
        {
          content: service.plan.customer.name,

          styles: {
            fontStyle: "bold",
            fontSize: 11,
          },
        },
        {
          content: service.plan.farm.name,

          styles: {
            halign: "right",
            fontSize: 11,
          },
        },
      ],
    ],
  });

  doc.setFont("helvetica", "bold");

  doc.setFontSize(14);

  var img = new Image();
  img.src = logoImg;
  doc.addImage(img, "png", 87, 25, 30, 10);

  doc.text("Ordem de Serviço", 105, 40, null, null, "center");

  doc.setFont("helvetica", "normal");

  doc.setFontSize(9);

  doc.text(
    `gerado em ${new Date().toLocaleDateString("pt-BR")} às ${new Date().toLocaleTimeString(
      "pt-BR"
    )} por ${user.name}`,
    105,
    45,
    null,
    null,
    "center"
  );

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 25,
    theme: "plain",
    styles: {
      fontSize: 11,
    },
    didDrawCell: function (data) {
      if (data.row.index === 0) {
        doc.setLineWidth(0.5);
        doc.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
      }
    },
    body: [
      [
        {
          content: service.plan.harvestTime.name,
        },
      ],
      [
        {
          content: service.plan.harvest.name,
        },
      ],
      [
        {
          content: service.plan.crop.name,
        },
      ],
    ],
  });

  autoTable(doc, {
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 10,
    didDrawCell: function (data) {
      // desenha a borda inferior na ultima celula da linha
      if (data.row.index === data.table.body.length - 1) {
        doc.setLineWidth(0.5);
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }
    },
    body: [
      [
        {
          content: `${service.soil.name} - ${service.name}`,

          styles: {
            fontStyle: "bold",
            fontSize: 12,
          },
        },
        {
          content: `${Number(service.soil.area).toFixed(2)} ha`,

          styles: {
            halign: "right",
            fontSize: 12,
            fontStyle: "bold",
          },
        },
      ],
    ],
  });

  autoTable(doc, {
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 5,
    body: [
      [
        {
          content: `de ${moment(service.start).format("DD/MM/YYYY")} até ${moment(
            service.end
          ).format("DD/MM/YYYY")}`,
          styles: {
            fontSize: 10,
          },
        },
      ],
      service.pump !== null &&
        service.pump.pump !== undefined &&
        service.pump.enable && [
          {
            content: `autonomia: ${service.pump.pump} ha/tanque`,
            styles: {
              fontSize: 10,
              fontStyle: "bold",
            },
          },
        ],
    ],
  });

  autoTable(doc, {
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 5,
    styles: {
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    didParseCell: function (data) {
      if (data.column.index === data.table.columns.length - 2) {
        data.cell.styles.halign = "center";
      }

      if (data.column.index === data.table.columns.length - 1) {
        data.cell.styles.fillColor = [209, 209, 209];
        data.cell.styles.halign = "center";
      }
    },
    head: [["CATEGORIA", "PRODUTO", "UNIDADE", "DOSE/HA", "TOTAL DE PRODUTO/TANQUE"]],
    body: service.products.map((product) => {
      const pHectare = product.perHectare.includes(",")
        ? product.perHectare.replace(",", ".")
        : product.perHectare;

      return [
        product.category,
        product.name,
        product.unit,
        pHectare,
        service.pump && service.pump.enable && service.pump.pump
          ? (Number(pHectare) * Number(service.pump.pump)).toFixed(2)
          : "",
      ];
    }),
  });

  autoTable(doc, {
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 10,
    startX: doc.lastAutoTable.finalX - 50,
    styles: {
      halign: "center",
    },
    didParseCell: function (data) {
      if (data.column.index === data.table.columns.length - 1) {
        data.cell.styles.halign = "center";
      }
    },
    head: [["PRODUTO", "DEMANDA TOTAL", "TOTAL GASTO"]],
    body: service.products.map((product) => {
      const pHectare = product.perHectare.includes(",")
        ? product.perHectare.replace(",", ".")
        : product.perHectare;

      const pSoilArea = service.soil.area.includes(",")
        ? service.soil.area.replace(",", ".")
        : service.soil.area;

      return [
        product.name,
        Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
        }).format(parseFloat(pHectare) * parseFloat(pSoilArea).toFixed(2)),
        "(                                         )",
      ];
    }),
  });

  if (service.observations) {
    autoTable(doc, {
      theme: "plain",
      startY: doc.lastAutoTable.finalY + 10,
      head: [["OBSERVAÇÕES:"]],
      body: [
        [
          {
            content: service.observations,
            styles: {
              fontSize: 10,
            },
          },
        ],
      ],
    });
  }

  doc.save(`${service.name} - ${service.soil.name} - ${service.plan.farm.name}.pdf`);
};
