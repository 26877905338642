/* eslint-disable */

import {
  Autocomplete,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { setNotification, useMaterialUIController } from "context";
import OrderListLayout from "examples/LayoutContainers/OrdersLayout";
import moment from "moment";
import "moment/locale/pt-br.js";
import { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { generatePDF } from "utils/pdf";
import { customSort } from "utils/sort";
import Toolbar from "./components/agenda/components/toolbar";
import "./components/agenda/style.css";
moment.locale("pt-br");
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const statusList = [
  {
    id: "PENDING",
    name: "Pendente",
    color: "#007aa2",
  },
  {
    id: "foreseen",
    name: "Previsto",
    color: "#7D9D4A",
  },
  {
    id: "foreseen_delayed",
    name: "Previsto Atrasado",
    color: "#F9B208",
  },
  {
    id: "done",
    name: "Finalizado",
    color: "#666666",
  },
  {
    id: "done_delayed",
    name: "Finalizado Atrasado",
    color: "#4a4a4a",
  },
];

export default function Agenda() {
  const [_, dispatch] = useMaterialUIController();
  const defaultDate = new Date();
  const [open, setOpen] = useState(false);
  const [openDragConfirm, setOpenDragConfirm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openReleaseConfirm, setOpenReleaseConfirm] = useState(false);
  const [openDoneConfirm, setOpenDoneConfirm] = useState(false);
  const [openRestoreStatusConfirm, setOpenRestoreStatus] = useState(false);
  const [dragEvent, setDragEvent] = useState({
    start: null,
    end: null,
    allDay: null,
    event: null,
    diff: null,
  });
  const [event, setEvent] = useState(null);
  const [servicesOs, setServicesOs] = useState([]);
  const [openLateDialog, setOpenLateDialog] = useState(false);
  const [lateServiceOs, setLateServiceOs] = useState([]);
  const [filteredServicesOs, setFilteredServicesOs] = useState([]);
  const [orders, setOrders] = useState([]);
  const [originalSoils, setOriginalSoils] = useState([]);
  const [soils, setSoils] = useState([]);
  const [plans, setPlans] = useState([]);
  const [farms, setFarms] = useState([]);
  const [observations, setObservations] = useState("");
  const [servicesOsFilterList, setServicesOsFilterList] = useState([]);

  const [filterPlan, setFilterPlan] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filterSoil, setFilterSoil] = useState();
  const [filterFarm, setFilterFarm] = useState();
  const [filterService, setFilterService] = useState();
  const [sprayerPerPump, setSprayerPerPump] = useState({
    enable: null,
    pump: 0,
  });

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarOnView, setCalendarOnView] = useState("agenda");
  const [agendaEvents, setAgendaEvents] = useState([]);
  const [reloadPrograms, setReloadPrograms] = useState(false);

  const { id } = useParams();
  const axios = Axios();

  const user = JSON.parse(localStorage.getItem("user"));

  const setStatus = (service) => {
    if (!service.status) {
      return "PENDING";
    }

    if (moment(service.end).isBefore(new Date()) && service.status === "foreseen") {
      return "foreseen_delayed";
    }

    if (moment(service.newDateFinish).isBefore(new Date()) && service.status === "foreseen") {
      return "foreseen_delayed";
    }

    if (moment(service.end).isAfter(new Date()) && service.status === "foreseen_delayed") {
      return "foreseen";
    }

    if (
      moment(service.newDateFinish).isAfter(new Date()) &&
      service.status === "foreseen_delayed"
    ) {
      return "foreseen";
    }

    return service.status;
  };

  function formatDateObj(date, isEndDate = false) {
    const dateObj = new Date(date);

    if (isEndDate) {
      return new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        dateObj.getHours(),
        dateObj.getMinutes(),
        dateObj.getSeconds()
      );
    }

    return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate());
  }

  function servicesForAgenda(services) {
    return services.map((service) => {
      const start = moment(service.start);
      const end = moment(service.end);

      const diff = end.diff(start, "days");

      if (diff === 0) {
        return {
          ...service,
          allDay: false,
          title: `${service.title} - (dia 1/1)`,
        };
      }

      const events = [];

      for (let i = 0; i <= diff; i += 1) {
        const newStart = moment(service.start).add(i, "days");
        const newEnd = moment(newStart)
          .hours(moment(service.end).hours())
          .minutes(moment(service.end).minutes())
          .seconds(moment(service.end).seconds())
          .toDate();

        events.push({
          ...service,
          start: newStart.toDate(),
          end: newEnd,
          allDay: false,
          title: `${service.title} - (dia ${i + 1}/${diff + 1})`,
        });
      }

      return events;
    });
  }

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const handleFilter = (services) => {
    const servicesList = services || servicesOs;

    const filtered = servicesList.filter((service) => {
      const serviceSoilId = service.soil !== null ? service.soil.id : service.soil;

      const statusMatch = !filterStatus || service.status === filterStatus.id;
      const soilMatch =
        !filterSoil ||
        filterSoil.length === 0 ||
        filterSoil.map((soil) => soil.id).includes(serviceSoilId);
      const farmMatch = !filterFarm || service.farm.id === filterFarm.id;
      const serviceMatch = !filterService || service.name === filterService.name;
      const planMatch =
        !filterPlan ||
        filterPlan.length === 0 ||
        filterPlan.map((plan) => plan.id).includes(service.plan.id);

      return statusMatch && soilMatch && farmMatch && serviceMatch && planMatch;
    });

    if (calendarOnView === "agenda") {
      const eventsForAgenda = servicesForAgenda(filtered);
      setCalendarEvents(eventsForAgenda.flat());
    } else {
      setFilteredServicesOs(filtered);
      setCalendarEvents(filtered);
    }
  };

  useEffect(() => {
    if (calendarOnView !== "agenda") {
      setCalendarEvents(filteredServicesOs);
    } else {
      setCalendarEvents(agendaEvents);
    }
    if (filterStatus || filterSoil || filterFarm || filterService || filterPlan) {
      handleFilter();
    }
  }, [calendarOnView, reloadPrograms]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/v1/program/${id}/customer`)
        .then((res) => {
          const services = res.data
            .flatMap((data) =>
              data.services.map((service, index) => ({
                ...service,
                farm: data.plan.farm,
                title: `${data.plan.farm.acronym} - ${data.soil ? data.soil.name : ""} - ${
                  service.name
                }`,
                start: formatDateObj(service.newDateInitial, false, service),
                end: formatDateObj(service.newDateFinish, true, service),
                status: setStatus(service),
                defaultService: service.defaultService,
                products: service.products,
                plan: data.plan,
                soil: data.soil,
                ordID: data.id,
                serviceOrder: index,
                base: service.defaultService,
                uniqueID: `${data.id}-${service.id}`,
                allDay: true,
                observations: service.observations ?? "",
                pump: service.pump ?? null,
                dateDone: service.dateDone ?? null,
              }))
            )
            .sort((a, b) => new Date(a.start) - new Date(b.start));

          // remover items duplicados que tenham o mesmo nome
          const uniqueServices = services.filter(
            (service, index, self) =>
              index === self.findIndex((s) => s.name === service.name && s.id === service.id)
          );

          const eventsForClients =
            user.role !== "admin"
              ? services.filter((service) => service.status !== "PENDING")
              : services;

          const eventsForAgenda = servicesForAgenda(eventsForClients);
          setAgendaEvents(eventsForAgenda.flat());
          setServicesOs(eventsForClients);
          setServicesOsFilterList(uniqueServices);

          if (calendarOnView === "agenda") {
            setCalendarEvents(eventsForAgenda.flat());
          } else {
            setCalendarEvents(eventsForClients);
          }

          const lateServices = eventsForClients.filter(
            (service) =>
              moment(service.end).isBefore(new Date()) &&
              service.status !== "done" &&
              service.status !== "done_delayed" &&
              service.status !== "PENDING"
          );

          setLateServiceOs(lateServices);

          setFilteredServicesOs(eventsForClients);
          setOrders(res.data);

          if (filterStatus || filterSoil || filterFarm || filterService || filterPlan) {
            handleFilter(eventsForClients);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`/v1/farm/${id}/customer`)
        .then((res) => {
          setFarms(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [reloadPrograms]);

  useEffect(() => {
    if (filterFarm) {
      axios
        .get(`/v1/soil/${filterFarm.id}/farm`)
        .then((res) => {
          setSoils(res.data);
          setOriginalSoils(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`/v1/plans/${filterFarm.id}/farm`)
        .then((res) => {
          setPlans(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [filterFarm]);

  useEffect(() => {
    if (filterPlan || Array.isArray(filterPlan)) {
      const soilIds = new Set(); // Usaremos um Set para evitar IDs duplicados

      Promise.all(filterPlan.map((plan) => axios.get(`/v1/program/${plan.id}/plan`)))
        .then((results) => {
          // Agora nós temos todos os resultados das chamadas API
          console.log("results", results.data);
          results.forEach((result) => {
            result.data.forEach((planData) => {
              soilIds.add(planData.soil.id); // Adiciona cada soil id ao Set
            });
          });

          // Filtramos os soils verificando se seu id está no Set de soilIds
          const soilsByPlan = originalSoils.filter((soil) => soilIds.has(soil.id));
          console.log("soilsByPlan", soilsByPlan);
          // Se você quiser atualizar o estado com essa nova lista filtrada
          setSoils(soilsByPlan);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados dos planos:", error);
        });
    } else {
      setSoils(originalSoils);
    }
  }, [filterPlan]);

  useEffect(() => {
    handleFilter();
  }, [filterStatus, filterSoil, filterFarm, filterService, filterPlan]);

  const eventStyleGetter = (event) => {
    let backgroundColor = "#57AOD3";
    if (event.status === "PENDING") {
      backgroundColor = "#007aa2";
    } else if (event.status === "done") {
      backgroundColor = "#666666";
    } else if (event.status === "done_delayed") {
      backgroundColor = "#4a4a4a";
    } else if (event.status === "foreseen") {
      backgroundColor = "#7D9D4A";
    } else if (event.status === "foreseen_delayed") {
      backgroundColor = "#F9B208";
    }

    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "#fff",
      border: "0px",
      display: "block",
      padding: "0 10px",
      textDecoration: event.defaultService ? "underline" : "none",
    };

    return style;
  };

  const handleSelectEvent = useCallback((event) => {
    setOpen(true);
    setEvent(event);
    setObservations(event.observations);
    setSprayerPerPump({
      enable: event.pump ? true : false,
      pump: event.pump?.pump,
    });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setEvent(null);
    setSprayerPerPump({
      enabled: null,
      pump: 0,
    });
  }, []);

  const handleReleaseService = (data) => {
    const newOrder = orders.filter((order) => order.id === data.ordID)[0];

    const newServices = newOrder.services.map((service) => {
      if (service.id === data.id) {
        if (moment(service.newDateFinish).isBefore(new Date())) {
          return {
            ...service,
            status: "foreseen_delayed",
          };
        }

        return {
          ...service,
          status: "foreseen",
        };
      }

      return service;
    });

    setServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);

      if (moment(existing.end).isBefore(new Date())) {
        return [
          ...filtered,
          {
            ...existing,
            status: "foreseen_delayed",
          },
        ];
      }

      return [
        ...filtered,
        {
          ...existing,
          status: "foreseen",
        },
      ];
    });

    setFilteredServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);

      if (moment(existing.end).isBefore(new Date())) {
        return [
          ...filtered,
          {
            ...existing,
            status: "foreseen_delayed",
          },
        ];
      }

      return [
        ...filtered,
        {
          ...existing,
          status: "foreseen",
        },
      ];
    });

    setCalendarEvents((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);
      const existingMoreDays = prev.filter((ev) => ev.uniqueID === data.uniqueID);

      if (existingMoreDays.length === 1) {
        if (moment(existing.end).isBefore(new Date())) {
          return [
            ...filtered,
            {
              ...existing,
              status: "foreseen_delayed",
            },
          ];
        }

        return [
          ...filtered,
          {
            ...existing,
            status: "foreseen",
          },
        ];
      } else {
        return [
          ...filtered,
          ...existingMoreDays.map((ev) => {
            if (moment(ev.end).isBefore(new Date())) {
              return {
                ...ev,
                status: "foreseen_delayed",
              };
            }

            return {
              ...ev,
              status: "foreseen",
            };
          }),
        ];
      }
    });

    setOrders((prev) => {
      const existing = prev.find((ev) => ev.id === data.ordID) ?? {};
      const filtered = prev.filter((ev) => ev.id !== data.ordID);

      return [
        ...filtered,
        {
          ...existing,
          services: newServices,
        },
      ];
    });

    axios
      .patch(`/v1/program/${newOrder.id}`, {
        services: newServices.sort((a, b) => a.serviceOrder - b.serviceOrder),
      })
      .then((res) => {
        handleClose();
        setOpenReleaseConfirm(false);
        setReloadPrograms((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDoneService = (data) => {
    const newOrder = orders.find((order) => order.id === data.ordID);

    if (!newOrder) {
      console.log("Order not found");
      return;
    }

    const newServices = newOrder.services.map((service) => {
      if (service.id === data.id) {
        if (moment(service.end).isBefore(new Date())) {
          return {
            ...service,
            status: "done_delayed",
            dateDone: moment().format("YYYY-MM-DD"),
          };
        } else {
          return {
            ...service,
            status: "done",
            dateDone: moment().format("YYYY-MM-DD"),
          };
        }
      }

      return service;
    });

    setFilteredServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);

      if (moment(existing.end).isBefore(new Date())) {
        return [
          ...filtered,
          {
            ...existing,
            status: "done_delayed",
            dateDone: moment().format("YYYY-MM-DD"),
          },
        ];
      }

      return [
        ...filtered,
        {
          ...existing,
          status: "done",
          dateDone: moment().format("YYYY-MM-DD"),
        },
      ];
    });

    setServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);

      if (moment(existing.end).isBefore(new Date())) {
        return [
          ...filtered,
          {
            ...existing,
            status: "done_delayed",
            dateDone: moment().format("YYYY-MM-DD"),
          },
        ];
      }

      return [
        ...filtered,
        {
          ...existing,
          status: "done",
          dateDone: moment().format("YYYY-MM-DD"),
        },
      ];
    });

    setCalendarEvents((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === data.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== data.uniqueID);
      const existingMoreDays = prev.filter((ev) => ev.uniqueID === data.uniqueID);

      if (existingMoreDays.length === 1) {
        if (moment(existing.end).isBefore(new Date())) {
          return [
            ...filtered,
            {
              ...existing,
              status: "done_delayed",
              dateDone: moment().format("YYYY-MM-DD"),
            },
          ];
        }

        return [
          ...filtered,
          {
            ...existing,
            status: "done",
            dateDone: moment().format("YYYY-MM-DD"),
          },
        ];
      } else {
        return [
          ...filtered,
          ...existingMoreDays.map((ev) => {
            if (moment(ev.end).isBefore(new Date())) {
              return {
                ...ev,
                status: "done_delayed",
                dateDone: moment().format("YYYY-MM-DD"),
              };
            }

            return {
              ...ev,
              status: "done",
              dateDone: moment().format("YYYY-MM-DD"),
            };
          }),
        ];
      }
    });

    // setOrders((prev) => {
    //   const existing = prev.find((ev) => ev.id === data.ordID) ?? {};
    //   const filtered = prev.filter((ev) => ev.id !== data.ordID);

    //   return [
    //     ...filtered,
    //     {
    //       ...existing,
    //       services: newServices,
    //       dateDone: moment().format("YYYY-MM-DD"),
    //     },
    //   ];
    // });

    axios
      .patch(`/v1/program/${newOrder.id}`, {
        services: newServices.sort((a, b) => a.serviceOrder - b.serviceOrder),
      })
      .then((res) => {
        handleClose();
        setOpenDoneConfirm(false);
        setReloadPrograms((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay: originalAllDay } = event;

      if (event.status === "done" || event.status === "done_delayed" || user.role !== "admin")
        return;

      const allDay = !originalAllDay && droppedOnAllDaySlot ? true : originalAllDay;

      const eventStart = moment(event.start);
      const dragStart = moment(start);

      let diff = eventStart.diff(dragStart, "days");

      if (diff > 0) {
        diff *= -1;
      } else {
        diff = Math.abs(diff);
      }

      setDragEvent({ start, end, allDay, event, diff });
      setOpenDragConfirm(true);
    },
    [setServicesOs]
  );

  const handleConfirmDrag = () => {
    const existing = servicesOs.find((ev) => ev.uniqueID === dragEvent.event.uniqueID) ?? {};
    let filtered = servicesOs.filter((ev) => ev.uniqueID !== dragEvent.event.uniqueID);

    const updateFiltered = filtered.map((service) => {
      if (
        service.soil.id !== dragEvent.event.soil.id ||
        service.defaultService ||
        service.status === "done" ||
        service.status === "done_delayed" ||
        moment(service.start).isBefore(dragEvent.start) ||
        service.plan.id !== dragEvent.event.plan.id ||
        service.ordID !== dragEvent.event.ordID
      ) {
        return service;
      }

      const diffItem = moment(service.end)
        .startOf("day")
        .diff(moment(service.start).startOf("day"), "days");

      const newStart = moment(service.start).add(dragEvent.diff, "days");
      const newEnd = moment(newStart)
        .add(diffItem, "days")
        .hours(moment(service.end).hours())
        .minutes(moment(service.end).minutes())
        .seconds(moment(service.end).seconds())
        .toDate();

      return {
        ...service,
        start: newStart.toDate(),
        end: newEnd,
        newDateInitial: newStart.toDate(),
        newDateFinish: newEnd,
        dateServiceBase: existing.defaultService ? dragEvent.start : service.dateServiceBase,
      };
    });

    let status;

    if (moment(dragEvent.end).isBefore(new Date()) && existing.status === "foreseen") {
      status = "foreseen_delayed";
    } else if (
      moment(dragEvent.end).isAfter(new Date()) &&
      existing.status === "foreseen_delayed"
    ) {
      status = "foreseen";
    } else {
      status = existing.status;
    }

    const programs = updateFiltered.filter(
      (service) => service.soil.id === existing.soil.id && service.plan.id === existing.plan.id
    );

    programs.push({
      ...existing,
      start: dragEvent.start,
      end: compareDates(dragEvent.start, dragEvent.end)
        ? dragEvent.end
        : moment(dragEvent.end).subtract(1, "days").toDate(),
      newDateInitial: dragEvent.start,
      newDateFinish: compareDates(dragEvent.start, dragEvent.end)
        ? dragEvent.end
        : moment(dragEvent.end).subtract(1, "days").toDate(),
      allDay: dragEvent.allDay,
      status: status,
    });

    axios
      .patch(`/v1/program/${existing.ordID}`, {
        services: programs.sort((a, b) => a.serviceOrder - b.serviceOrder),
      })
      .then((res) => {
        setReloadPrograms((prev) => !prev);
      })
      .finally(() => {
        setOpenDragConfirm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleObservationsAndPump = (event) => {
    let pump = null;

    if (sprayerPerPump.pump !== null) {
      if (sprayerPerPump && sprayerPerPump.enable === true && sprayerPerPump.pump === 0) {
        pump = event.pump;
      } else if (sprayerPerPump.enable === false) {
        pump = sprayerPerPump;
      } else if (sprayerPerPump.null) {
        pump = null;
      } else {
        pump = sprayerPerPump;
      }
    }

    const newOrder = orders.find((order) => order.id === event.ordID);

    if (!newOrder) {
      console.log("Order not found");
      return;
    }

    const newServices = newOrder.services.map((service) => {
      if (service.id === event.id) {
        return {
          ...service,
          observations: observations !== "" ? observations : "",
          pump: pump,
        };
      }

      return service;
    });

    setOrders((prev) => {
      const existing = prev.find((ev) => ev.id === event.ordID) ?? {};
      const filtered = prev.filter((ev) => ev.id !== event.ordID);

      return [
        ...filtered,
        {
          ...existing,
          services: newServices,
        },
      ];
    });

    setCalendarEvents((prev) => {
      const indexToUpdate = prev.findIndex((ev) => ev.uniqueID === event.uniqueID);

      if (indexToUpdate !== -1) {
        const updatedEvent = {
          ...prev[indexToUpdate],
          observations: observations !== "" ? observations : "",
          pump: pump,
        };

        const updatedPrev = [...prev];
        updatedPrev[indexToUpdate] = updatedEvent;

        return updatedPrev;
      }

      return prev;
    });

    setEvent({
      ...event,
      observations: observations !== "" ? observations : "",
      pump: pump,
    });

    axios
      .patch(`/v1/program/${newOrder.id}`, {
        services: newServices,
      })
      .then((res) => {
        setNotification(dispatch, {
          open: true,
          title: "Salvo com sucesso!",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteEvent = (event) => {
    const newOrder = orders.find((order) => order.id === event.ordID);

    if (!newOrder) {
      console.log("Order not found");
      return;
    }

    const newServices = newOrder.services.filter((service) => service.id !== event.id);

    setServicesOs((prev) => prev.filter((ev) => ev.uniqueID !== event.uniqueID));
    setFilteredServicesOs((prev) => prev.filter((ev) => ev.uniqueID !== event.uniqueID));
    setCalendarEvents((prev) => prev.filter((ev) => ev.uniqueID !== event.uniqueID));

    axios
      .patch(`/v1/program/${newOrder.id}`, {
        services: newServices.sort((a, b) => a.serviceOrder - b.serviceOrder),
      })
      .then((res) => {
        setReloadPrograms((prev) => !prev);
        setOpenDelete(false);
        setNotification(dispatch, {
          open: true,
          title: "Serviço removido com sucesso!",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGoBackStep6 = (event) => {
    setNotification(dispatch, {
      open: true,
      title: "Redirecionando...",
      color: "success",
      icon: "success",
      severity: "success",
    });

    axios
      .patch(`/v1/plans/status/${event.plan.id}`, {
        status: "OS",
      })
      .then(() => {
        window.location.href = `/planejamentos/${event.plan.id}?soil=${event.soil.id}&service=${event.id}`;
      });
  };

  function compareDates(date1, date2) {
    return moment(date1).format("DD/MM/YYYY") === moment(date2).format("DD/MM/YYYY");
  }

  function handleRestoreStatusEvent(event) {
    const newOrder = orders.find((order) => order.id === event.ordID);

    if (!newOrder) {
      console.log("Order not found");
      return;
    }

    const newServices = newOrder.services.map((service) => {
      if (service.id === event.id) {
        return {
          ...service,
          status: "PENDING",
        };
      }

      return service;
    });

    setServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === event.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== event.uniqueID);

      return [
        ...filtered,
        {
          ...existing,
          status: "PENDING",
        },
      ];
    });

    setFilteredServicesOs((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === event.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== event.uniqueID);

      return [
        ...filtered,
        {
          ...existing,
          status: "PENDING",
        },
      ];
    });

    setCalendarEvents((prev) => {
      const existing = prev.find((ev) => ev.uniqueID === event.uniqueID) ?? {};
      const filtered = prev.filter((ev) => ev.uniqueID !== event.uniqueID);

      return [
        ...filtered,
        {
          ...existing,
          status: "PENDING",
        },
      ];
    });

    axios
      .patch(`/v1/program/${newOrder.id}`, {
        services: newServices.sort((a, b) => a.serviceOrder - b.serviceOrder),
      })
      .then((res) => {
        setReloadPrograms((prev) => !prev);

        setOpenRestoreStatus(false);
        setNotification(dispatch, {
          open: true,
          title: "Status restaurado com sucesso!",
          color: "success",
          icon: "success",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  console.log("calendarEvents", calendarEvents);

  return (
    <OrderListLayout
      style={{
        padding: "1em",
      }}
    >
      <TableContainer style={{ marginBottom: "1em" }}>
        <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
              <TableCell style={{ color: "inherit" }} colSpan={4} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="20%">
                Cliente: {orders[0]?.plan?.customer?.name ? orders[0].plan.customer.name : ""}
              </TableCell>

              {/* <TableCell width="20%">
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Data de Plantio"
                    inputFormat="dd/MM/yyyy"
                    value={
                      orders[0]?.services[0]?.plantingDate
                        ? new Date(orders[0].services[0].plantingDate)
                        : null
                    }
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "dd/mm/aaaa",
                        }}
                        style={{ width: "57%" }}
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: "flex", gap: "1em", margin: "1em" }}>
        <Autocomplete
          options={farms}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          onChange={(_, value) => setFilterFarm(value)}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setFilterFarm(null);
              setSoils([]);
              setFilterSoil(null);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Fazendas" variant="outlined" />}
        />

        <Autocomplete
          multiple
          options={plans}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          onChange={(_, value) => setFilterPlan(value)}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setFilterPlan([]);
            }
          }}
          disabled={!filterFarm}
          renderInput={(params) => <TextField {...params} label="Planejameto" variant="outlined" />}
          renderTags={(value, getTagProps) =>
            filterPlan.length > 1 ? (
              <Chip label={`${filterPlan.length} planejamentos selecionadas`} />
            ) : (
              <Chip label={`${filterPlan.length} planejamento selecionada`} />
            )
          }
        />

        <Autocomplete
          options={servicesOsFilterList}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          onChange={(_, value) => setFilterService(value)}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setFilterService(null);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Serviços" variant="outlined" />}
        />
        <Autocomplete
          multiple
          options={customSort(soils)}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          onChange={(_, value) => setFilterSoil(value)}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setFilterSoil(null);
            }
          }}
          disabled={!filterFarm}
          renderInput={(params) => <TextField {...params} label="Glebas" variant="outlined" />}
          renderTags={(value, getTagProps) =>
            filterSoil !== null && filterSoil.length > 1 ? (
              <Chip label={`${filterSoil.length} glebas selecionadas`} />
            ) : (
              <Chip label={`${filterSoil.length} gleba selecionada`} />
            )
          }
        />
        <Autocomplete
          options={statusList}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          value={filterStatus}
          onChange={(_, value) => setFilterStatus(value)}
          onInputChange={(event, value, reason) => {
            if (reason === "clear") {
              setFilterStatus(null);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                // onClick={() => setFilterStatus(option)}
              >
                {option.name}
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: option.color,
                    marginLeft: "10px",
                  }}
                />
              </li>
            );
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          margin: "0 1em",
          backgroundColor: "#edbd39",
          gap: "10px",
        }}
      >
        {lateServiceOs.length > 0 && (
          <>
            <h4>Existem {lateServiceOs.length} serviços atrasados!</h4>
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setOpenLateDialog(true)}
            >
              Clique aqui para ver
            </span>
          </>
        )}
      </div>

      {defaultDate ? (
        <DnDCalendar
          onView={setCalendarOnView}
          longPressThreshold={20}
          defaultDate={defaultDate}
          localizer={localizer}
          dayLayoutAlgorithm={"no-overlap"}
          events={calendarEvents}
          defaultView="agenda"
          startAccessor={(event) => event.start}
          endAccessor={(event) =>
            compareDates(event.start, event.end)
              ? event.end
              : addDays(event.start, parseInt(event.dateFinish) + 1)
          }
          views={["agenda", "week", "month"]}
          onSelectEvent={handleSelectEvent}
          doShowMoreDrillDown={false}
          // eventPropGetter={eventStyleGetter}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: "transparent",
            },
          })}
          onEventDrop={moveEvent}
          style={{
            height: "100vh",
            padding: "20px",
          }}
          popup
          messages={{
            allDay: "Dia todo",
            previous: "Anterior",
            next: "Próximo",
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
            agenda: "Agenda",
            date: "Data",
            time: "Hora",
            event: "Evento",
            noEventsInRange: "Não há serviços nesse período",
            showMore: (total) => `+ ${total} serviços`,
          }}
          components={{
            noEventsInRange: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  fontSize: "1em",
                }}
              >
                <h1>Não há serviços nesse período.</h1>
              </div>
            ),
            popup: (props) => <div style={{ zIndex: 1000 }}>{props.children}</div>,
            toolbar: (props) => <Toolbar {...props} />,
            header: ({ label }) => (
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2>{label}</h2>
              </div>
            ),
            event: ({ event }) => (
              <span
                style={{
                  ...eventStyleGetter(event),
                  cursor: "pointer",
                }}
              >
                {event.title}
              </span>
            ),
            timeGutterHeader: ({ label }) => (
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2>{label}</h2>
              </div>
            ),
            agenda: {
              event: ({ event }) => {
                return (
                  <span
                    style={{
                      ...eventStyleGetter(event),
                      cursor: "pointer",
                    }}
                  >
                    {event.title}
                  </span>
                );
              },
              date: ({ day }) => {
                const date = moment(day).format("DD MMM. ddd.");
                const dateArr = date.split(" ");

                return (
                  <div className="rbc-agenda-event-cell-date">
                    <span className="day">{dateArr[0]}</span>
                    <span className="month">{dateArr[1].toLocaleUpperCase()},</span>
                    <span className="day-name">{dateArr[2].toLocaleUpperCase()}</span>
                  </div>
                );
              },
            },
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "1em",
          }}
        >
          <h1>Não há serviços cadastrados.</h1>
        </div>
      )}
      <Dialog open={open} onClose={() => handleClose()} maxWidth={100} fullWidth>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {event?.title}
          <div
            style={{
              display:
                event?.status === "done" || event?.status === "done_delayed" ? "flex" : "none",
              gap: "10px",
            }}
          >
            <MDInput label="Indice Base" value={event?.dateFinish} disabled />
            <MDInput
              label="Indice Realizado"
              value={moment(event?.dateDone).diff(moment(event?.dateServiceBase), "days")}
              disabled
            />
          </div>

          {/* <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data"
              inputFormat="dd/MM/yyyy"
              // value={event?.start ? parseISO(event.start).toISOString() : null}
              onChange={(newValue) => {
                setServicesOs((prev) => {
                  const existing = prev.find((ev) => ev.id === event.id) ?? {};
                  const filtered = prev.filter((ev) => ev.id !== event.id);

                  setEvent({
                    ...existing,
                    start: moment(newValue).format("YYYY-MM-DD"),
                    end: moment(newValue).format("YYYY-MM-DD"),
                  });

                  return [
                    ...filtered,
                    {
                      ...existing,
                      start: moment(newValue).format("YYYY-MM-DD"),
                      end: moment(newValue).format("YYYY-MM-DD"),
                    },
                  ];
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "dd/mm/aaaa",
                  }}
                />
              )}
            />
          </LocalizationProvider> */}
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead style={{ display: "contents" }}>
                <TableRow style={{ backgroundColor: "#40404099", color: "white" }}>
                  <TableCell style={{ color: "inherit" }}>Produto</TableCell>
                  <TableCell style={{ color: "inherit" }}>Categoria</TableCell>
                  <TableCell style={{ color: "inherit" }}>Unidade</TableCell>
                  <TableCell style={{ color: "inherit" }}>Área</TableCell>
                  <TableCell style={{ color: "inherit" }}>Dose/ha</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {event?.products.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product?.name}</TableCell>
                    <TableCell>{product?.category}</TableCell>
                    <TableCell>{product?.unit}</TableCell>
                    <TableCell>{event?.soil?.area}</TableCell>
                    <TableCell>{product?.perHectare}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <h3
            style={{
              marginTop: "1em",
            }}
          >
            Observações: (Opcional)
          </h3>
          <TextareaAutosize
            style={{ width: "100%", padding: "3em" }}
            value={observations}
            onChange={(e) => {
              setObservations(e.target.value);
            }}
          />

          {/* <MDButton variant="gradient" color="dark" onClick={() => handleObservations(event)}>
            Salvar Observações
          </MDButton> */}

          <h3
            style={{
              marginTop: "1em",
            }}
          >
            Deseja informar quantos hectares seu pulverizador faz por bomba?*
          </h3>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Opções</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={event?.pump != null ? event?.pump.enable : null}
              label="Opções"
              onChange={(e) => {
                if (e.target.value !== null) {
                  setSprayerPerPump({
                    ...sprayerPerPump,
                    enable: e.target.value,
                    pump: e.target.value > 0 ? "" : 0,
                  });

                  setEvent({
                    ...event,
                    pump: {
                      enable: e.target.value,
                      pump: 0,
                    },
                  });
                } else {
                  setSprayerPerPump(null);

                  setEvent({
                    ...event,
                    pump: null,
                  });
                }
              }}
              disabled={event?.status === "done" || event?.status === "done_delayed"}
            >
              <MenuItem value={null}>Não Informar</MenuItem>
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>
          {event?.pump !== null && (
            <>
              <MDInput
                label="Quantos hectares seu pulverizador faz por bomba?"
                disabled={!event?.pump.enable}
                value={sprayerPerPump?.pump ?? event?.pump?.pump}
                onChange={(e) => {
                  const inputValue = e.target.value;

                  if (isNaN(inputValue) || inputValue < 0 || inputValue.includes(",")) {
                    return;
                  }

                  setSprayerPerPump({ ...sprayerPerPump, pump: inputValue });
                }}
              />
              {/* <MDButton
                style={{
                  display: "block",
                  marginTop: "1em",
                }}
                variant="gradient"
                color="dark"
                onClick={() => handlePump(event)}
              >
                Salvar
              </MDButton> */}
            </>
          )}

          <MDButton
            style={{
              display: "block",
              marginTop: "1em",
            }}
            variant="gradient"
            color="dark"
            onClick={() => handleObservationsAndPump(event)}
            //disabled={event?.status === "done" || event?.status === "done_delayed"}
          >
            Salvar
          </MDButton>
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => handleClose()}>
            Fechar
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => handleGoBackStep6(event)}
            style={{
              display: user.role === "admin" ? "block" : "none",
            }}
          >
            Ir para o planejamento
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            disabled={event?.status === "done" || event?.status === "done_delayed"}
            onClick={() => setOpenDelete(true)}
            style={{
              display: user.role === "admin" ? "block" : "none",
            }}
          >
            Excluir
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={() => generatePDF(event)}>
            Exportar
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            disabled={event?.status === "PENDING"}
            onClick={() => setOpenRestoreStatus(true)}
            style={{
              display: user.role === "admin" ? "block" : "none",
            }}
          >
            Restaurar Status
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            disabled={event?.status !== "PENDING"}
            onClick={() => setOpenReleaseConfirm(true)}
            style={{
              display: user.role === "admin" ? "block" : "none",
            }}
          >
            Liberar Serviço
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            disabled={
              event?.status === "done" ||
              event?.status === "done_delayed" ||
              event?.status === "PENDING"
            }
            onClick={() => setOpenDoneConfirm(true)}
          >
            Finalizar Serviço
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDragConfirm} onClose={() => setOpenDragConfirm(false)}>
        <DialogTitle>
          Essa alteração irá refletir na programação dos demais serviços, deseja continuar?
        </DialogTitle>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenDragConfirm(false)}>
            Cancelar
          </MDButton>

          <MDButton variant="gradient" color="dark" onClick={() => handleConfirmDrag()}>
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>
          Tem certeza que deseja excluir esse serviço? Essa ação não poderá ser desfeita.
        </DialogTitle>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenDelete(false)}>
            Cancelar
          </MDButton>

          <MDButton variant="gradient" color="dark" onClick={() => handleDeleteEvent(event)}>
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openReleaseConfirm} onClose={() => setOpenReleaseConfirm(false)}>
        <DialogTitle>
          Tem certeza que deseja liberar esse serviço? Essa ação não poderá ser desfeita.
        </DialogTitle>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenReleaseConfirm(false)}>
            Cancelar
          </MDButton>

          <MDButton variant="gradient" color="dark" onClick={() => handleReleaseService(event)}>
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDoneConfirm} onClose={() => setOpenDoneConfirm(false)}>
        <DialogTitle>
          Tem certeza que deseja finalizar esse serviço? Essa ação não poderá ser desfeita.
        </DialogTitle>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenDoneConfirm(false)}>
            Cancelar
          </MDButton>

          <MDButton variant="gradient" color="dark" onClick={() => handleDoneService(event)}>
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openRestoreStatusConfirm} onClose={() => setOpenRestoreStatus(false)}>
        <DialogTitle>
          Tem certeza que deseja restaurar o status desse serviço? Essa ação não poderá ser
          desfeita.
        </DialogTitle>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenRestoreStatus(false)}>
            Cancelar
          </MDButton>

          <MDButton variant="gradient" color="dark" onClick={() => handleRestoreStatusEvent(event)}>
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openLateDialog} onClose={() => setOpenLateDialog(false)}>
        <DialogTitle>Existem {lateServiceOs.length} serviços atrasados.</DialogTitle>
        <DialogContent>
          {lateServiceOs.map((service, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "5px 0",
                borderBottom: "1px solid #cecece",
              }}
            >
              <h4
                style={{
                  color: "#888888",
                }}
              >
                {service.title}
              </h4>
              <span
                style={{
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() => handleSelectEvent(service)}
              >
                Visualizar
              </span>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenLateDialog(false)}>
            Fechar
          </MDButton>
        </DialogActions>
      </Dialog>
    </OrderListLayout>
  );
}
