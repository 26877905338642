export function cellphoneMask(cellphone) {
  cellphone = cellphone.replace(/\D/g, "");
  cellphone = cellphone.replace(/^(\d{2})(\d)/g, "($1) $2");
  cellphone = cellphone.replace(/(\d)(\d{4})$/, "$1-$2");
  return cellphone;
}

export function documentMask(document) {
  document = document.replace(/\D/g, "");
  if (document.length === 14) {
    document = document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
  } else {
    document = document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
  return document;
}

export function decimalMask(num) {
  if (Number.isNaN(num)) return 0.0;
  return num.toLocaleString("pt-br", { minimumFractionDigits: 2 });
}
