/* eslint-disable */

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import Grid from "@mui/material/Grid";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link } from "react-router-dom";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import "./styles.css";

export default function CenteredTabs() {
  const [controller] = useMaterialUIController();
  const { layout } = controller;
  const [value, setValue] = React.useState(1);

  const user = JSON.parse(localStorage.getItem("user"));

  React.useEffect(() => {
    if (layout === "dashboard") {
      setValue(0);
    } else if (layout === "planning") {
      setValue(1);
    } else if (layout === "orders") {
      setValue(2);
    } else if (layout === "reports") {
      setValue(3);
    }
  }, [layout]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "#104732" }} className="tabs">
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={12} lg={12}>
          <DashboardNavbar />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={3} alignItems="center" style={{ backgroundColor: "#e9e9e9" }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0" }}
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                textColor="primary"
                indicatorColor="primary"
                centered
              >
                <Tab
                  label="Cadastros"
                  component={Link}
                  style={{
                    fontWeight: "500",
                    display: user?.role === "admin" ? "flex" : "none",
                  }}
                  to="/clientes"
                />
                <Tab
                  label="Planejamentos"
                  component={Link}
                  style={{
                    fontWeight: "500",
                    display: user?.role === "admin" ? "flex" : "none",
                  }}
                  to="/planejamentos"
                />

                <Tab
                  component={Link}
                  label="agenda"
                  style={{ fontWeight: "500" }}
                  to={`/agenda${user?.role === "admin" ? "" : "/" + user?.customer.id}`}
                />
                <Tab
                  label="Relatórios"
                  component={Link}
                  style={{ fontWeight: "500" }}
                  to="/relatorios"
                />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
