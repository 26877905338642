import * as React from "react";
import Paper from "@mui/material/Paper";
import CollaboratorLayout from "examples/LayoutContainers/CollaboratorLayout";

import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DatePicker, { registerLocale } from "react-datepicker";
import TextField from "@mui/material/TextField";
import { ptBR } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";

import Axios from "utils/axiosInstance";
import { useMaterialUIController, setIsLoading } from "context";
import ServiceCard from "./components/serviceCard";

// import StyledMenu from "./components/headTable";

export default function CollaboratorSoilView() {
  const [, dispatch] = useMaterialUIController();
  const [startDate, setStartDate] = React.useState("");
  const [services, setServices] = React.useState([]);
  const axios = Axios();
  registerLocale("pt-BR", ptBR);

  // const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = React.useState(null);
  const changeStartDate = (dates) => {
    console.log(dates);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start === null) {
      setIsLoading(dispatch, true);
      axios
        .get(`v1/users/collaborator/soils`)
        .then((res) => {
          setServices(res.data.data);
          setIsLoading(dispatch, false);
        })
        .catch(() => {
          setIsLoading(dispatch, false);
        });
    } else if (end) {
      setIsLoading(dispatch, true);
      axios
        .get(`v1/users/collaborator/soils?start=${start.toISOString()}&end=${end.toISOString()}`)
        .then((res) => {
          setServices(res.data.data);
          setIsLoading(dispatch, false);
        })
        .catch(() => {
          setIsLoading(dispatch, false);
        });
    }
  };

  React.useEffect(() => {
    setIsLoading(dispatch, true);
    axios
      .get(`v1/users/collaborator/soils`)
      .then((res) => {
        setServices(res.data.data);
        setIsLoading(dispatch, false);
      })
      .catch(() => {
        setIsLoading(dispatch, false);
      });
  }, []);

  return (
    <CollaboratorLayout>
      <Grid container spacing={2} pb={3} pl={1} pr={1}>
        <Grid item xs={12} md={2} sm={2}>
          <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <DatePicker
              selected={startDate}
              onChange={changeStartDate}
              startDate={startDate}
              endDate={endDate}
              locale="pt-BR"
              selectsRange
              dateFormat="dd/MM/yyyy"
              isClearable
              customInput={
                <TextField
                  label="Periodo"
                  inputProps={{ placeholder: "dd/mm/aaaa - dd/mm/aaaa" }}
                  fullWidth
                />
              }
            />
            {/* <DatePicker
              label="Período"
              value={startDate}
              inputFormat="dd/MM/yyyy"
              onChange={changeStartDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                  style={{ width: "100%" }}
                  disabled
                />
              )}
            /> */}
          </LocalizationProvider>
        </Grid>
      </Grid>
      {services.map((data) => (
        <Paper sx={{ width: "100%", marginBottom: "1em" }}>
          <Grid xs={12}>
            <ServiceCard {...data} />
          </Grid>
        </Paper>
      ))}
      {services.length === 0 && "Nenhum serviço encontrado"}
    </CollaboratorLayout>
  );
}
