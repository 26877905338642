/* eslint-disable */

export function customSort(array) {
  function extractNumber(str) {
    var match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : NaN;
  }

  array.sort(function (a, b) {
    var numA = extractNumber(a.name);
    var numB = extractNumber(b.name);

    var lowerA = String(a.name).toLowerCase();
    var lowerB = String(b.name).toLowerCase();

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    } else if (!isNaN(numA)) {
      return -1;
    } else if (!isNaN(numB)) {
      return 1;
    } else {
      return lowerA.localeCompare(lowerB);
    }
  });

  return array;
}
