/* eslint-disable */
import { ButtonGroup } from "@mui/material";
import MDButton from "components/MDButton";

const Toolbar = ({ label, onNavigate, onView, views }) => {
  const handleNavigate = (action) => {
    onNavigate(action);
  };

  const handleView = (view) => {
    onView(view);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <ButtonGroup>
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={() => handleNavigate("TODAY")}
        >
          Hoje
        </MDButton>
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={() => handleNavigate("PREV")}
        >
          Anterior
        </MDButton>
        <MDButton
          type="button"
          variant="gradient"
          color="dark"
          onClick={() => handleNavigate("NEXT")}
        >
          Próximo
        </MDButton>
      </ButtonGroup>

      <span className="rbc-toolbar-label">{label}</span>

      <ButtonGroup>
        {views.map((view) => (
          <MDButton
            type="button"
            variant="gradient"
            color="dark"
            onClick={() => handleView(view)}
            key={view}
          >
            {view === "month" ? "Mês" : view === "week" ? "Semana" : "Agenda"}
          </MDButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Toolbar;
