/*eslint-disable*/
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import logoImg from "assets/images/logo_nuagro.png";
import { useMaterialUIController } from "context";
import parseISO from "date-fns/parseISO";
import React, { Fragment, useEffect, useState } from "react";

export default function ReportActivities() {
  const [controller] = useMaterialUIController();
  // const { reportContent } = controller;
  const { reportContentDate } = controller;

  const [datesInitial, setDatesInitial] = useState([]);
  const [services, setServices] = useState([]);
  const [sproducts, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [farm, setFarm] = useState([]);
  const [userName, setUserName] = useState();
  const [reportContent, setReportContent] = useState([]);
  const [productsConsolidate, setProductsConsolidate] = useState([]);

  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
    try {
      const reports = JSON.parse(localStorage.getItem("reports"));
      setReportContent(reports);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }, []);

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
    const [datePart, timePart] = formattedDate.split(", ");
    return `${datePart} às ${timePart}`;
  }

  useEffect(() => {
    if (reportContent) {
      const getReportsInitial = reportContent.flatMap((report) => {
        return report.program_service.newDateInitial;
      });

      const getServices = reportContent.flatMap((report) => {
        return {
          ...report.program_service,
          plan: report.plan,
          soil: report.soil,
        };
      });

      const getProducts = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {
          const pHectare = product.perHectare.includes(",")
            ? product.perHectare.replace(",", ".")
            : product.perHectare;

          return {
            ...product,
            soil: report.soil,
            total: parseFloat(pHectare) * parseFloat(report.soil.area),
          };
        });
      });

      const productsConsolidated = getProducts.reduce((acc, product) => {
        const existingProductIndex = acc.findIndex((item) => item.name === product.name);

        if (existingProductIndex === -1) {
          // Produto não existe no array acumulado, adiciona diretamente
          return [...acc, product];
        } else {
          // Produto já existe, atualiza os valores
          const existingProduct = acc[existingProductIndex];
          const updatedProduct = {
            ...existingProduct,
            perHectare: parseFloat(existingProduct.perHectare) + parseFloat(product.perHectare),
            total: parseFloat(existingProduct.total) + parseFloat(product.total),
          };

          return [
            ...acc.slice(0, existingProductIndex),
            updatedProduct,
            ...acc.slice(existingProductIndex + 1),
          ];
        }
      }, []);

      const getSuppliers = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => product.supplier);
      });

      const getFarm = reportContent.flatMap((report) => {
        return report.plan.farm.name;
      });

      const datesInititalOrder = removeDuplicateDates(getReportsInitial).sort((a, b) =>
        a.localeCompare(b)
      );

      setFarm(removeDuplicateDates(getFarm));
      setDatesInitial(datesInititalOrder);
      setServices(getServices, reportContent);
      setProducts(getProducts);
      setProductsConsolidate(productsConsolidated);
      setSuppliers(removeDuplicateDates(getSuppliers));
    }
  }, [reportContent]);

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

  function calculateSupplierTotal(supplier) {
    const supplierProducts = sproducts.filter((product) => product.supplier === supplier);
    const total = supplierProducts.reduce((sum, product) => {
      const productValue = parseFloat(product.perHectare) * parseFloat(product.unitPrice);
      return sum + productValue;
    }, 0);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(total);
  }

  function handlePrintButtonClick() {
    window.print();
  }

  const totalProduto = (perHectare, unitPrice) => {
    const pHectare = perHectare.includes(",") ? perHectare.replace(",", ".") : perHectare;
    return (pHectare * unitPrice).toFixed(2);
  };

  return (
    <Container
      style={{
        marginTop: 20,
      }}
    >
      <style>
        {`
          .MuiTableCell-root {
            border-bottom: 1px solid black;
            padding: 0px;
          }
          .MuiTable-root {
            border-collapse: collapse;
            width: 100%;

          }
        `}
      </style>
      <Button
        id="demo-customized-button"
        variant="contained"
        textColor="white"
        style={{ color: "white", marginBottom: "30px" }}
        className="no-print"
        onClick={handlePrintButtonClick}
      >
        Exportar PDF
      </Button>

      <TableContainer
        style={{
          borderRadius: 0,
          boxShadow: "none",
          padding: "20px",
          margin: "auto",
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "flex", justifyContent: "space-between" }}>
            <TableHead>
              <TableRow style={{ display: "flex", justifyContent: "space-between" }}>
                <TableCell
                  colSpan={12}
                  style={{
                    border: "none",
                    fontWeight: "normal",
                    fontSize: "14px",
                  }}
                >
                  {reportContent[0]?.plan?.customer?.name}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {farm.map((nome, index) => (
                <TableRow key={index}>
                  <TableCell
                    colSpan={22}
                    style={{
                      border: "none",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {nome}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </TableHead>
        </Table>
        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                colSpan={12}
                style={{ textAlign: "center", width: "100%", padding: "0px" }}
              ></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table sx={{ minWidth: 650 }}>
          {/* <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell>
                <img src={logoImg} alt="logo" style={{ width: "100px" }} />
              </TableCell>
            </TableRow>
          </TableHead> */}

          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                style={{
                  borderBottom: "none",
                  textAlign: "center",
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={logoImg} alt="logo" style={{ width: "100px", marginTop: "20px" }} />
                ATIVIDADES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                colSpan={12}
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  textAlign: "center",
                  width: "100%", // Adiciona esta linha para estender a célula
                }}
              >
                Gerado em {formatDate(new Date())}, por {userName}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell
                style={{ border: "none", display: "flex", justifyContent: "space-between" }}
                colSpan={4}
              >
                <div>
                  {" "}
                  {reportContent[0]?.filter?.status?.name} entre{" "}
                  {reportContent[0]?.filter?.startDate} até {""}
                  {reportContent[0]?.filter?.finishDate}
                </div>
                <div>{services.length} serviços</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {datesInitial.map((date, dateIndex) => (
          <Table
            key={dateIndex}
            sx={{ minWidth: 650 }}
            // style={{
            // breakInside: "avoid",
            // }}
          >
            <TableHead style={{ display: "contents" }}>
              <TableRow>
                <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableHead style={{ display: "contents" }}>
              <TableRow>
                <TableCell colSpan={5} style={{ paddingTop: 35, border: "none" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableHead style={{ display: "contents" }}>
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{
                    backgroundColor: "#D8D8D8",
                    border: "none",
                  }}
                >
                  {parseISO(date).toLocaleDateString()}
                </TableCell>
              </TableRow>
            </TableHead>

            {services
              .filter((service) => service.newDateInitial === date)
              .map((service, serviceIndex) => (
                <>
                  <Fragment key={serviceIndex}>
                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell colSpan={4} style={{ paddingTop: "35px" }}>
                          {`${service.soil.name.toUpperCase()} - ${service.name.toUpperCase()}`}
                        </TableCell>
                        <TableCell style={{ paddingTop: "35px" }}>
                          {service.plan.farm.name}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ display: "flex", flexDirection: "column" }}>
                        <TableCell
                          style={{ borderBottom: "none", fontWeight: "normal" }}
                          colSpan={9}
                        >
                          até {parseISO(service?.newDateFinish).toLocaleDateString()}{" "}
                        </TableCell>

                        <TableCell colSpan={4} style={{ border: "none", fontWeight: "normal" }}>
                          {" "}
                          {service?.soil?.area} ha
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell>Unid.</TableCell>
                        <TableCell>Dose/Ha</TableCell>
                        <TableCell>Total produto</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {service.products
                        .sort((a, b) => a.category.localeCompare(b.category))
                        .map((product, productIndex) => (
                          <TableRow
                            key={productIndex}
                            style={{
                              borderBottom: "dashed 1px black",
                            }}
                          >
                            <TableCell style={{ borderBottom: "none", padding: 0 }}>
                              {product.category}
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>{product.name}</TableCell>
                            <TableCell style={{ borderBottom: "none" }}>{product.unit}</TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              {product.perHectare}
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                              {totalProduto(product.perHectare, service?.soil?.area)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Fragment>
                </>
              ))}
          </Table>
        ))}

        <Table
          sx={{ minWidth: 650 }}
          //style={{
          // breakInside: "avoid",
          // }}
        >
          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell colSpan={5} style={{ paddingTop: 35, border: "none" }}></TableCell>
            </TableRow>
          </TableHead>

          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell
                colSpan={6}
                style={{
                  backgroundColor: "#D8D8D8",
                  border: "none",
                  marginTop: "20px !important",
                }}
              >
                DEMANDA DE PRODUTOS
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "none", fontWeight: "normal" }} colSpan={12}>
                de {reportContent[0]?.filter?.startDate} até {reportContent[0]?.filter?.finishDate}
              </TableCell>
            </TableRow>
          </TableHead>

          {suppliers.map((supplier, index) => (
            <Fragment key={index}>
              <TableHead style={{ display: "contents" }}>
                <TableRow>
                  <TableCell colSpan={5} style={{ paddingTop: 35 }}>
                    {supplier === null || supplier === undefined || supplier === ""
                      ? "Sem Fornecedor"
                      : supplier.toUpperCase()}
                  </TableCell>
                  <TableCell style={{ paddingTop: 35 }}>
                    {" "}
                    {calculateSupplierTotal(supplier)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead style={{ display: "contents" }}>
                <TableRow>
                  <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableHead style={{ display: "contents" }}></TableHead>
              <TableHead style={{ display: "contents" }}>
                <TableRow>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Unid.</TableCell>
                  <TableCell>Demanda de produto</TableCell>
                  <TableCell>Ref. de preço</TableCell>
                  <TableCell>Valor total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsConsolidate
                  .filter((product) => product.supplier === supplier)
                  .sort((a, b) => a.category.localeCompare(b.category))
                  .map((product, sproductIndex) => (
                    <TableRow
                      key={sproductIndex}
                      style={{
                        borderBottom: "dashed 1px black",
                      }}
                    >
                      <TableCell style={{ borderBottom: "none" }}>{product.category}</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>{product.name}</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>{product.unit}</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {product.total.toFixed(2)}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(product.unitPrice)}{" "}
                      </TableCell>

                      <TableCell style={{ borderBottom: "none" }}>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(parseFloat(product.total) * parseFloat(product.unitPrice))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Fragment>
          ))}
        </Table>
      </TableContainer>
    </Container>
  );
}
