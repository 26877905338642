/*eslint-disable*/

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { cellphoneMask } from "utils/masks";
import StyledMenu from "./components/headTable";

const columns = [
  { accessor: "name", Header: "Nome do Funcionário", width: 300 },
  { accessor: "customer.name", Header: "Cliente", width: 300 },
  { accessor: "email", Header: "Email", width: 200 },
  { accessor: "cellphone", Header: "Telefone", width: 200 },
];

const filterOptions = ["Nome", "Fazenda"];
function translateOption(option) {
  switch (option) {
    case "Nome":
      return "name";
    case "Fazenda":
      return "farms.name";
    default:
      return option;
  }
}

export default function CollaboratorsList() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [fieldName, setFieldName] = React.useState(filterOptions[0]);
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const axios = Axios();

  React.useEffect(() => {
    setLoading(true);
    setRows([]);
    axios
      .get(
        `/v1/collaborator?current=${page + 1}&pageSize=${rowsPerPage}&filter=${translateOption(
          fieldName
        )}&value=${filter}`
      )
      .then((res) => {
        const users = res.data.data.map((row) => ({
          ...row,
          name: <Link to={`/funcionarios/${row.id}`}>{row.name}</Link>,
          cellphone: cellphoneMask(row.cellphone),
          farms: row.farms.map((farm) => farm.name).join(", "),
        }));
        setRows(users);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [page, rowsPerPage, filter]);

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Lista de Funcionários
          </h3>
        </Typography>

        <TableContainer>
          <StyledMenu
            filterLabel="Filtrar funcionário por"
            filterOptions={filterOptions}
            fieldName={fieldName}
            setFieldName={setFieldName}
          />
          <form style={{ display: "inline-block" }}>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              onChange={handleFilterChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          <Button
            id="demo-customized-button"
            variant="contained"
            textColor="white"
            style={{ marginRight: "24px", color: "white", float: "right" }}
            onClick={() => navigate("/funcionarios/novo")}
          >
            Cadastrar Novo
          </Button>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
    </DashboardLayout>
  );
}
