import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import Axios from "utils/axiosInstance";
import { decimalMask } from "utils/masks";

export default function ServiceCard({
  orderId,
  harvestName,
  harvestTimeName,
  soilName,
  farmName,
  id,
  area,
  name,
  startDate,
  conclusionDate,
  products,
  execution,
}) {
  const [createLoading, setCreateLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [mArea, setMArea] = React.useState("?");
  const [mStartDate, setMstartDate] = React.useState("?");
  const [mConclusionDate, setMconclusionDate] = React.useState("?");
  const [mExecution, setMExecution] = React.useState({});
  const axios = Axios();

  React.useEffect(() => {
    if (execution) setMExecution(execution);
    if (area) setMArea(area);
    if (startDate && conclusionDate) {
      const sDate = new Date(startDate);
      const cDate = new Date(conclusionDate);
      setMstartDate(sDate.toLocaleDateString("pt-BR"));
      setMconclusionDate(cDate.toLocaleDateString("pt-BR"));
    }
  }, []);

  const executeService = (e) => {
    e.preventDefault();
    const mdate = startDate.split("/");
    const today = new Date();
    const start = new Date(mdate[2], mdate[1], mdate[0]);
    if (today > start) {
      axios.post(`/v1/orders/${orderId}/execute/${id}`).then((res) => {
        execution = res.data.execution;
      });
    } else {
      setOpenDialog(true);
    }
  };

  const confirmExecution = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios.post(`/v1/orders/${orderId}/execute/${id}`).then((res) => {
      setMExecution(res.data.execution);
      setOpenDialog(false);
      setCreateLoading(false);
    });
  };

  const copyServices = () => {
    let text = `*${farmName}*\n`;
    text += `${harvestName} • ${harvestTimeName} • ${soilName} • ${name}\n`;
    text += `De ${mStartDate} até ${mConclusionDate}\n`;
    text += `Produtos:\n`;
    for (const p of products) {
      text += `  (${p.formulation}) ${p.name} ${p.perHectare} ${p.unit} `;
      text += `(${decimalMask(p.perHectare * mArea)} ${p.unit})\n`;
    }
    navigator.clipboard.writeText(text);
  };

  const isFuture = () => {
    const today = new Date();
    const start = new Date(startDate);
    return start > today;
  };

  return (
    <>
      <Card sx={{ display: "flex" }}>
        <CardHeader
          title={
            <h3 style={{ color: "white" }}>
              {harvestName} &bull; {harvestTimeName} &bull; {soilName} &bull; {name}
            </h3>
          }
          sx={{ backgroundColor: "#195222" }}
        />
        <CardContent>
          <p style={{ marginBottom: "1em" }}>
            De {mStartDate} até {mConclusionDate}
          </p>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ backgroundColor: "#7d9d4a" }}
            >
              <Typography style={{ color: "white" }}>Produtos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {products.map((p) => (
                <Typography>
                  ({p.formulation}) {p.name} {p.perHectare}
                  {p.unit}/ha ({decimalMask(p.perHectare * mArea)} {p.unit})
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <CardActions disableSpacing style={{ display: "flex", justifyContent: "flex-end" }}>
          <MDButton
            // variant="text"
            style={{ float: "right", marginRight: "1.2em", marginBottom: "1em" }}
            color="dark"
            onClick={copyServices}
          >
            copiar
          </MDButton>
          <MDButton
            // variant="text"
            style={{ float: "right", marginRight: "1.2em", marginBottom: "1em" }}
            color="dark"
            disabled={mExecution?.executionDate || isFuture()}
            onClick={executeService}
          >
            {mExecution?.executionDate ? "Serviço Realizado" : "Realizar Serviço"}
          </MDButton>
        </CardActions>
      </Card>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Serviço realizado em atraso, deseja continuar?
        </DialogTitle>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
            fechar
          </MDButton>
          <MDButton
            variant="contained"
            color="error"
            onClick={confirmExecution}
            autoFocus
            disabled={createLoading}
          >
            confirmar
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
