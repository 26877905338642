import React from "react";
import MoneyInput from "@rschpdr/react-money-input/build";
import MDInput from "components/MDInput";

export default function RealInput(props) {
  return (
    <MoneyInput
      customInput={MDInput}
      currencyConfig={{
        locale: "pt-BR",
        currencyCode: "BRL",
        currencyDisplay: "symbol",
      }}
      {...props}
    />
  );
}
