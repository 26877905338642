// Material Dashboard 2 React contexts
import axios from "axios";
import REACT_APP_MAIN_API_URL from "config/mainConfig";
import { setIsLoading, setNotification, useMaterialUIController } from "context";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Axios = (withAuthorization = true) => {
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const headers = { "content-type": "application/json" };
  if (withAuthorization) {
    const user = JSON.parse(localStorage.getItem("user"));
    headers.Authorization = `Bearer ${user?.accessToken}`;
  }

  const instance = axios.create({
    baseURL: REACT_APP_MAIN_API_URL,
    headers,
    responseType: "json",
  });

  const errHandler = (err) => {
    const errorMessage = err?.data?.erro;
    if (err?.status === 500) {
      if (err?.data?.description) {
        return { title: err?.data?.description };
      }
      return { title: "Não foi possível completar sua requisição" };
    }

    if (err?.status === 400 && errorMessage) return { title: errorMessage };
    if (err?.status === 400 && err.data?.message) {
      if (err.data?.message === "INVALID_PASSWORD") {
        return "Email ou Senha incorreta.";
      }
      return { title: err.data?.message };
    }
    if (err?.status === 404 || err?.status === 0) {
      return { title: err?.data?.error?.msg || "Recurso não encontrado" };
    }
    if (err?.status === 403 && err.data?.error) {
      return { title: "Erro de validação" }; // { title: err.data?.error.errors || "Erro de validação" }; // [0].description;
    }
    if (err?.status === 401) {
      // localStorage.removeItem("user");
      // window.location.reload();
      return { title: "Usuário não autorizado." };
    }
    if (err?.status === 409) {
      return { title: err.data.mensagem };
    }
    if (err?.data?.error?.errors) {
      console.log(err?.data?.error?.errors[0]?.description);
      return { title: err?.data?.error?.errors[0]?.description || "Dados de entrada inválidos" };
    }
    if (err?.data?.error?.msg) {
      return { title: err?.data?.error?.msg || "Dados de entrada inválidos" };
    }
    return { title: "Houve um erro na sua requisição" };
  };

  instance.interceptors.request.use(
    async (req) => {
      const authorization = req?.headers?.Authorization;
      if (!authorization) return req;
      const token = String(authorization).replace("Bearer ", "");

      const jwtTokwn = jwt(token);
      const date1 = new Date(jwtTokwn.exp * 1000);
      const date2 = new Date();
      const isExpired = date2.getTime() - date1.getTime();
      const user = JSON.parse(localStorage.getItem("user"));
      const refreshToken = user?.refreshToken;

      if (isExpired >= 0 && refreshToken) {
        localStorage.removeItem("user");
        navigate("/auth/login");
        setIsLoading(dispatch, false);
      }

      return req;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      const errorNotification = errHandler(err.response);
      console.log(err);
      setNotification(dispatch, { ...errorNotification, open: true });
      return Promise.reject(err);
    }
  );

  return instance;
};
export default Axios;
