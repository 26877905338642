/*eslint-disable*/
import { Autocomplete, FormControl, Grid, Paper, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Axios from "utils/axiosInstance";

export default function Step1({ planId, nextStep }) {
  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState();

  const [customer, setCustomer] = useState({ id: "", name: "" });
  const [customers, setCustomers] = useState([]);

  const [farm, setFarm] = useState({ id: "", name: "" });
  const [farms, setFarms] = useState([]);
  
  const [harvest, setHarvest] = useState({ id: "", name: "" });
  const [harvestList, setHarvestList] = useState([]);

  const [harvestTime, setHarvestTime] = useState({ id: "", name: "" });
  const [harvestTimeList, setHarvestTimeList] = useState([]);

  const [crop, setCrop] = useState({ id: "", name: "" });
  const [cropList, setCropList] = useState([]);

  const [area, setArea] = useState();
  const [services, setServices] = useState([]);

  const { id } = useParams();
  const axios = Axios();

  const idPlan = id || planId;

  const getFarmByCustomer = (value) => {
    axios.get(`/v1/farm/${value.id}/customer`).then((res) => setFarms(res.data));
  };

  useEffect(() => {
    if (id || planId) {
      axios.get(`/v1/plans/${idPlan}`).then((res) => {
        setStartDate(parseISO(res.data.startDate).toISOString());
        setName(res.data.name);
        setCustomer({ id: res.data.customer?.id, name: res.data.customer?.name });
        setHarvest({ id: res.data.harvest.id, name: res.data.harvest.name });
        setHarvestTime({ id: res.data.harvestTime.id, name: res.data.harvestTime.name });
        setCrop({ id: res.data.crop.id, name: res.data.crop.name });
        setArea(res.data.area);
        setFarm({ id: res.data.farm.id, name: res.data.farm.name });
        setServices(res.data.services);
        getFarmByCustomer(res.data.customer);
      });
    }
  }, [id, planId]);


  useEffect(() => {
    axios.get(`/v1/customer`).then((res) => {
      setCustomers(res.data.data.map((user) => ({ id: user.id, name: user.name })));
    });
    axios.get(`/v1/harvests`).then((res) => {
      setHarvestList(res.data.data.map((user) => ({ id: user.id, name: user.name })));
    });
    axios.get(`/v1/harvest-time`).then((res) => {
      setHarvestTimeList(res.data.data.map((user) => ({ id: user.id, name: user.name })));
    });
    axios.get(`/v1/crops`).then((res) => {
      setCropList(res.data.data.map((user) => ({ id: user.id, name: user.name })));
    });
  }, []);

  const submitCreatePlan = async (e) => {
    e.preventDefault();

    const payload = {
      startDate,
      name,
      customer: customer?.id.toString(),
      harvest: harvest.id.toString(),
      harvestTime: harvestTime.id.toString(),
      crop: crop.id.toString(),
      area: area.toString(),
      farm: farm.id.toString(),
      services: [{ id: "", name: "",  products: [], defaultService: false }],
      status: "SERVICES_AND_PRODUCTS",
      
      
    };

    if (id || planId) {
      payload.id = id;
      payload.services = services;

      const res = await axios.patch(`/v1/plans/${idPlan}`, payload);

      if (res.status === 200) {
        nextStep(id);
      }

      return;
    }

    const res = await axios.post("/v1/plans", payload);

    if (res.status === 200) {
      nextStep(res.data.id);
    }
  };

  const changeCustomer = (value) => {
    setCustomer(value);
    setFarm(null);
    getFarmByCustomer(value);
  };

  return (
    <Paper
      sx={{ width: "100%" }}
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <form onSubmit={submitCreatePlan}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Data de Criação"
                value={startDate}
                inputFormat="dd/MM/yyyy"
                autoFocus
                openOnFocus
                onChange={(newValue) => {
                  setStartDate(newValue.toISOString());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                    style={{ width: "100%" }}
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={8}>
            <MDInput
              type="text"
              label="Nome do Planejamento"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="customers"
                options={customers}
                openOnFocus
                value={customer}
                getOptionLabel={(option) => option?.name}
                onChange={(_, value) => changeCustomer(value)}
                renderInput={(params) => <MDInput {...params} label="Cliente" required />}
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="farm"
                options={farms}
                value={farm}
                getOptionLabel={(option) => option?.name}
                openOnFocus
                onChange={(_, value) =>
                  setFarm({
                    id: value.id,
                    name: value.name,
                  })
                }
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <MDInput {...params} label="Fazenda" required />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="harvest"
                options={harvestList}
                value={harvest}
                getOptionLabel={(option) => option.name}
                openOnFocus
                onChange={(e, value) => {
                  setHarvest(value);
                }}
                renderInput={(params) => <MDInput {...params} label="Ano / Safra" required />}
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} sm={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="harvestTimeList"
                options={harvestTimeList}
                value={harvestTime}
                getOptionLabel={(option) => option.name}
                openOnFocus
                onChange={(e, value) => {
                  setHarvestTime(value);
                }}
                renderInput={(params) => <MDInput {...params} label="Época" required />}
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} sm={3}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="crop"
                options={cropList}
                value={crop}
                getOptionLabel={(option) => option.name}
                openOnFocus
                onChange={(e, value) => {
                  setCrop(value);
                }}
                renderInput={(params) => <MDInput {...params} label="Cultura" required />}
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <MDInput
              type="number"
              label="Área (ha)"
              value={area || ""}
              onChange={(e) => setArea(e.target.value)}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item textAlign="end" xs={12}>
            <MDButton variant="gradient" color="dark" type="submit">
              continuar planejamento
            </MDButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
