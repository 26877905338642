/*eslint-disable*/
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import logoImg from "assets/images/logo_nuagro.png";
import { useMaterialUIController } from "context";
import React, { Fragment, useEffect, useState } from "react";

export default function ReportProgramManagement() {
  const [controller] = useMaterialUIController();
  // const { reportContent } = controller;
  // const { reportContentDate } = controller;

  const [datesInitial, setDatesInitial] = useState([]);
  const [services, setServices] = useState([]);
  const [sproducts, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [farm, setFarm] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [somePerHectare, setSomePerHectare] = useState(0);
  const [userName, setUserName] = useState();
  const [reportContent, setReportContent] = useState([]);
  const [soilTotalArea, setSoilTotalArea] = useState(0);

  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
    try {
      const reports = JSON.parse(localStorage.getItem("reports"));
      setReportContent(reports);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }, []);

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
    const [datePart, timePart] = formattedDate.split(", ");
    return `${datePart} às ${timePart}`;
  }

  useEffect(() => {
    if (reportContent) {
      const getReportsInitial = reportContent.flatMap((report) => {
        return report.program_service.newDateInitial;
      });

      const groupedServices = [];

      for (let i = 0; i < reportContent.length; i++) {
        const currentSoil = reportContent[i].soil.name;

        const group = {
          soil: currentSoil,
          soil_area: reportContent[i].soil.area,
          plan: reportContent[i].plan,
          program_service: [reportContent[i].program_service],
        };

        for (let j = i + 1; j < reportContent.length; j++) {
          if (reportContent[j].soil.name === currentSoil) {
            group.program_service.push(reportContent[j].program_service);
            i++;
          } else {
            break;
          }
        }

        groupedServices.push(group);
      }

      const getServices = groupedServices;

      const getSoilServicesArea = getServices.reduce((sum, service) => {
        return sum + parseFloat(service.soil_area);
      }, 0);

      const getProducts = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {

           const pHectare = product.perHectare.includes(",")
            ? product.perHectare.replace(",", ".")
            : product.perHectare;

          const products = {
            ...product,
            perHectare: pHectare,
          };

          return products;
        });
      });

      const getSuppliers = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => product.supplier);
      });

      const getFarm = reportContent.flatMap((report) => {
        return report.plan.farm.name;
      });

      console.log("getproducts", getProducts);

      setFarm(removeDuplicateDates(getFarm));
      setDatesInitial(removeDuplicateDates(getReportsInitial));
      setServices(getServices, reportContent);
      setProducts(getProducts);
      setSuppliers(removeDuplicateDates(getSuppliers));
      setSoilTotalArea(getSoilServicesArea);
    }
  }, [reportContent]);

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }
  let totalValueForAllSuppliers = 0;

  // Step 2: Modify the calculateSupplierTotal function
  function calculateSupplierTotal(supplier) {
    const supplierProducts = sproducts.filter(
      (product) => product.supplier === supplier && typeof product.unitPrice === "number"
    );
    const total = supplierProducts.reduce((sum, product) => {
      const productValue = parseFloat(product.perHectare) * parseFloat(product.unitPrice);
      return sum + productValue;
    }, 0);
    return total;
  }

  function calculateSomePerHectare() {
    const somePerHectare = sproducts.reduce((sum, product) => {
      const productValue = parseFloat(product.perHectare);
      return sum + productValue;
    }, 0);

    return somePerHectare;
  }

  useEffect(() => {
    // Step 2: Atualize o valor total do estado após calcular para todos os fornecedores
    let totalForAllSuppliers = 0;
    suppliers.forEach((supplier) => {
      totalForAllSuppliers += calculateSupplierTotal(supplier);
    });
    setTotalValue(totalForAllSuppliers);
    setSomePerHectare(calculateSomePerHectare());
  }, [sproducts, suppliers]);

  function handlePrintButtonClick() {
    window.print();
  }

  function calculateTotalCustoSoil(soil) {
    const servicesWithSameSoil = services.filter((service) => service.soil === soil);

    const totalCustoSoil = servicesWithSameSoil.reduce((sum, service) => {
      const totalCusto = service.program_service.reduce((sum, program_service) => {
        const totalCusto = program_service.products.reduce((sum, product) => {
          const phectare = product.perHectare.includes(",")
            ? product.perHectare.replace(",", ".")
            : product.perHectare;
          const pUnitPrice = product.unitPrice ? product.unitPrice : 0;

          const productValue = parseFloat(phectare) * parseFloat(pUnitPrice);

          return sum + productValue;
        }, 0);

        return sum + totalCusto;
      }, 0);

      return sum + totalCusto;
    }, 0);

    return totalCustoSoil.toFixed(2);
  }

  function calculateTotalCusto(service) {
    const totalCusto = service.products.reduce((sum, product) => {
      const productValue = parseFloat(product.perHectare) * parseFloat(product.unitPrice);
      return sum + productValue;
    }, 0);

    return totalCusto.toFixed(2);
  }

function aggregateProductsByService(service) {
  const productMap = new Map();

  service.products.forEach((product) => {
    const perHectareValue = parseFloat(product.perHectare.replace(',', '.'));

    if (productMap.has(product.name)) {
      const existingProduct = productMap.get(product.name);
      existingProduct.unitPrice += product.unitPrice;
      existingProduct.perHectare += perHectareValue;
    } else {
      productMap.set(product.name, { ...product, perHectare: perHectareValue });
    }
  });

  return Array.from(productMap.values());
}

  return (
    <Container
      style={{
        marginTop: 20,
        padding: "16px !important",
      }}
    >
      <style>
        {`
            .MuiTableCell-root {
              border-bottom: 1px solid black;
              padding: 0px;
            }
            .MuiTable-root {
              border-collapse: collapse;
              width: 100%;
            }
          `}
      </style>
      <Button
        id="demo-customized-button"
        variant="contained"
        textColor="white"
        style={{ color: "white", marginBottom: "30px" }}
        className="no-print"
        onClick={handlePrintButtonClick}
      >
        Exportar PDF
      </Button>
      <TableContainer
        style={{
          borderRadius: 0,
          boxShadow: "none",
          overflow: "hidden",
          margin: "auto",
          padding: "18px",
        }}
      >
        <TableHead style={{ display: "flex", justifyContent: "space-between" }}>
          <TableHead>
            <TableRow style={{ display: "flex", justifyContent: "space-between" }}>
              <TableCell
                style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "14px" }}
                colSpan={22}
              >
                {reportContent[0]?.plan?.customer?.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            {farm.map((nome, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "14px" }}
                >
                  {nome}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </TableHead>
        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                colSpan={12}
                style={{ textAlign: "center", width: "100%", padding: "0px" }}
              ></TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <TableHead style={{ display: "contents" }}>
          <TableRow style={{ display: "flex", justifyContent: "center" }}>
            <TableCell
              style={{
                border: "none",
                paddingBottom: "0px",
                display: "flex",
                gap: 10,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              align="center"
            >
              <img src={logoImg} alt="logo" style={{ width: "100px", marginTop: "20px" }} />
              Programa de Manejo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead style={{ display: "contents" }}>
          <TableRow style={{ display: "flex", justifyContent: "center" }}>
            <TableCell
              style={{
                padding: "0px",
                fontWeight: "normal",
                fontSize: "12px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Gerado em {formatDate(new Date())}, por {userName}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px",
            padding: "10px 0px 16px 0px",
          }}
        >
          <TableHead style={{ display: "flex", flexDirection: "column", padding: "0px" }}>
            <TableRow>
              <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                {reportContent[0]?.plan?.name}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                {reportContent[0]?.plan?.harvest?.name}; {reportContent[0]?.plan?.harvestTime?.name}
                ; {reportContent[0]?.plan?.crop?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                Área total: {soilTotalArea} há
              </TableCell>
            </TableRow>
          </TableHead>
        </TableHead>

        {services.map((service, index) => (
          <Fragment key={index}>
            <Table sx={{ minWidth: 650, pageBreakAfter: "always" }}>
              <TableHead style={{ display: "contents" }}>
                <TableRow>
                  <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              {/* {index === 0 || service.soil.name !== services[index - 1].soil.name ? (
                <TableHead style={{ display: "contents" }}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{ border: "none", paddingTop: 25, paddingBottom: 5, fontSize: 24 }}
                    >
                      {service.soil.name}
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : null} */}

              <TableHead style={{ display: "contents" }}>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ border: "none", paddingTop: 25, paddingBottom: 5, fontSize: 24 }}
                  >
                    {service.soil}
                  </TableCell>
                  <TableCell
                    style={{ border: "none", paddingTop: 25, paddingBottom: 5, fontSize: 24 }}
                  >
                    R$ {calculateTotalCustoSoil(service.soil)}/ha
                  </TableCell>
                </TableRow>
              </TableHead>
              {service.program_service.map((program_service, index) => (
                <>
                  <TableHead style={{ marginTop: "10px" }}>
                    <TableRow>
                      <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead style={{ display: "contents", paddingBottom: "16px" }}>
                    <TableRow>
                      <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={5}>
                        {program_service.name.toUpperCase()}
                        {/* ({((calculateSupplierTotal(supplier) / totalValue) * 100).toFixed(1)} %) */}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#D8D8D8" }}>
                        R$ {calculateTotalCusto(program_service)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead style={{ display: "contents" }}>
                    <TableRow>
                      <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead style={{ display: "contents" }}></TableHead>
                  <TableHead style={{ display: "contents", paddingTop: "16px" }}>
                    <TableRow>
                      <TableCell>Categoria</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Unid.</TableCell>
                      <TableCell>Dose/ha</TableCell>
                      <TableCell>Custo unit</TableCell>
                      <TableCell>Custo/ha</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {aggregateProductsByService(program_service).map((product, sproductIndex) => (
                      <TableRow
                        key={sproductIndex}
                        style={{
                          borderBottom: "dashed 1px black",
                        }}
                      >
                        <TableCell style={{ borderBottom: "none" }}>{product.category}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>{product.name}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>{product.unit}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {Number(product.perHectare).toFixed(2)}
                        </TableCell>

                        <TableCell style={{ borderBottom: "none" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(product.unitPrice)}
                        </TableCell>

                        <TableCell style={{ borderBottom: "none" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(parseFloat(product.perHectare) * parseFloat(product.unitPrice))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ))}
            </Table>
          </Fragment>
        ))}
      </TableContainer>
    </Container>
  );
}
