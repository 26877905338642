/*eslint-disable*/
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { cellphoneMask } from "utils/masks";
import StyledMenu from "./components/headTable";

const columns = [
  { accessor: "name", Header: "Nome", width: 300 },
  { accessor: "email", Header: "E-mail", width: 100 },
  { accessor: "cellphone", Header: "Telefone", width: 100 },
];

const filterOptions = ["Nome", "Razão Social", "Cidade", "Estado"];

function translateOption(option) {
  switch (option) {
    case "Nome":
      return "name";
    case "Razão Social":
      return "corporateName";
    case "Cidade":
      return "address.city.cityName";
    case "Estado":
      return "address.state.stateName";
    default:
      return option;
  }
}

export default function AdminList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [fieldName, setFieldName] = useState(filterOptions[0]);
  const [filter, setFilter] = useState(null);
  const [pageOptions, setPageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const axios = Axios();

  const fetchData = async () => {
    setLoading(true);
    try {
      let url = `/v1/auth?current=${page + 1}&pageSize=${rowsPerPage}`;

      if (filter !== null && filter !== "") {
        const translatedOption = translateOption(fieldName);
        url = `/v1/auth?current=1&pageSize=10&filter=${translatedOption}&value=${filter}`;
      }

      const response = await axios.get(url);

      const users = response.data.data.map((row) => ({
        ...row,
        name: <Link to={`/administradores/${row.id}`}>{row.name}</Link>,
        email: row.email,
        cellphone: cellphoneMask(row.cellphone),
      }));

      setRows(users);
      const { total } = response.data.pagination;
      setTotalRows(total);
      const options = [];
      for (let i = 0; i <= Math.ceil(total / rowsPerPage); i += 1) {
        options.push(i);
      }
      setPageOptions(options);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, filter, fieldName]);

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Lista de Administradores
          </h3>
        </Typography>

        <TableContainer>
          <StyledMenu
            filterLabel="Filtrar cliente por"
            filterOptions={filterOptions}
            fieldName={fieldName}
            setFieldName={setFieldName}
          />
          <form style={{ display: "inline-block" }}>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              value={filter}
              onChange={handleFilterChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          <Button
            id="demo-customized-button"
            variant="contained"
            textColor="white"
            style={{ marginRight: "24px", color: "white", float: "right" }}
            onClick={() => navigate("/administradores/novo")}
          >
            Cadastrar Novo
          </Button>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
    </DashboardLayout>
  );
}
