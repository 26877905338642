/* eslint-disable */

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMaterialUIController } from "context";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { customSort } from "utils/sort";

import {
  Autocomplete,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import RealInput from "components/custom-inputs/RealInput";
import { setNotification } from "context";
import { formatDate } from "utils/formatDate";

export default function Step5({ planId, nextStep }) {
  const [controller, dispatch] = useMaterialUIController();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogProduct, setOpenDialogProduct] = useState(false);
  const [openDialogService, setOpenDialogService] = useState(false);
  const [openDialogAddService, setOpenDialogAddService] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const [step2AddService, setStep2AddService] = useState(0);
  const [selectedSoilsAddService, setSelectedSoilsAddService] = useState([]);
  const [error, setError] = useState({ active: false, message: "" });

  const [plan, setPlan] = useState({});
  const [soils, setSoils] = useState([]);
  const [services, setServices] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [hasOs, setHasOs] = useState(false);
  const [newService, setNewService] = useState({});

  const [productList, setProductList] = useState([]);

  const [newProduct, setNewProduct] = useState({});
  const [selectedSoilServices, setSelectedSoilServices] = useState();
  const [selectedServicesSoil, setSelectedServicesSoil] = useState();
  const [selectedSoilForProducts, setSelectedSoilForProducts] = useState(null);

  const [serviceList, setServiceList] = useState([]);
  const [perHectare, setPerHectare] = useState(0);

  const navigate = useNavigate();

  const [os, setOs] = useState([]);

  const axios = Axios();
  const { id } = useParams();

  const searchParams = new URLSearchParams(document.location.search);

  const idPlan = id || planId;

  useEffect(() => {
    if (searchParams) {
      // search params example: ?soil=2&service=3
      const soilId = searchParams.get("soil");
      const serviceId = searchParams.get("service");

      if (soilId && serviceId) {
        // handle collapse open soil and service
        const soilIndex = os.findIndex((o) => o.soil.id === Number(soilId));
        const serviceIndex = services.findIndex((service) => service.id === Number(serviceId));

        if (soilIndex !== -1 && serviceIndex !== -1) {
          // verifica se não já esta aberto
          if (
            !expandedRows.includes(`${soilIndex}`) &&
            !expandedRows.includes(`${soilIndex}-${serviceIndex}`)
          ) {
            setExpandedRows((prevRows) => [...prevRows, `${soilIndex}`]);
            setExpandedRows((prevRows) => [...prevRows, `${soilIndex}-${serviceIndex}`]);
          }
        }

        // handle soil chec
      }
    }
  }, [searchParams]);

  useEffect(() => {
    axios.get(`/v1/services`).then((res) => {
      setServiceList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (idPlan) {
      axios.get(`/v1/program/${idPlan}/plan`).then((res) => {
        if (res.data.length) {
          setOs(res.data);
          setHasOs(true);
        }
      });

      axios.get(`/v1/plans/${idPlan}`).then((res) => {
        setPlan(res.data);

        setServices(res.data.services);
        if (res.data.length > 0) {
          //     const services = res.data.flatMap((service) =>
          //       service.services.map((s, index) => ({ ...s, checked: false, index: index }))
          //     );
        }
      });

      // axios.get(`/v1/planServiceOs/${idPlan}/plan`).then((res) => {
      //   if (res.data.length > 0) {
      //     const services = res.data.flatMap((service) =>
      //       service.services.map((s, index) => ({ ...s, checked: false, index: index }))
      //     );

      //     setServices(services);
      //   }
      // });

      axios.get(`/v1/products`).then((res) => {
        const productList = res.data.data;
        productList.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setProductList(productList);
      });

      axios.get(`/v1/supplier`).then((res) => {
        let suppliersList = res.data.data.map((user) => ({ id: user.id, name: user.name }));
        suppliersList.sort((a, b) => a.name.localeCompare(b.name));

        setSuppliers(suppliersList);
      });
    }
  }, [idPlan]);

  useEffect(() => {
    if (plan?.id) {
      axios.get(`/v1/soil/${plan?.farm?.id}/farm`).then((res) => {
        if (hasOs) {
          const soils = res.data.map((soil) => ({
            ...soil,
            checked: os.some((o) => o.soil.id === soil.id),
          }));
          setSoils(customSort(soils));
        } else {
          const soils = res.data.map((soil) => ({ ...soil, checked: false }));
          setSoils(customSort(soils));
        }
      });
    }
  }, [plan]);

  useEffect(() => {
    if (selectedSoilServices) {
      handleOpenDialogService();
    }
  }, [selectedSoilServices]);

  const handleSaveOs = () => {
    const osWithPlanId = os.map((o) => ({
      ...o,
      services: o.services.map((s) => ({ ...s })),
      plan: plan.id,
      status: "BASE",
    }));

    if (hasOs) {
      axios.patch(`/v1/program/${idPlan}/plan`, osWithPlanId).then((res) => {
        setNotification(dispatch, {
          open: true,
          title: "Programa de manejo atualizada com sucesso",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
        nextStep(idPlan);
      });
      axios.patch(`/v1/plans/status/${idPlan}`, { status: "BASE" }).then((res) => {
        setNotification(dispatch, {
          open: true,
          title: "Programa de manejo atualizada com sucesso",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
      });
    } else {
      axios.post(`/v1/program`, osWithPlanId).then((res) => {
        setNotification(dispatch, {
          open: true,
          title: "Programa de manejo criada com sucesso",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
        nextStep(idPlan);
      });
      axios.patch(`/v1/plans/status/${idPlan}`, { status: "BASE" }).then((res) => {
        setNotification(dispatch, {
          open: true,
          title: "Programa de manejo atualizada com sucesso",
          severity: "success",
          icon: "check_circle_outline",
          color: "success",
        });
      });
    }
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSoilChecked = (event, id) => {
    const checked = event.target.checked;
    const newSoil = soils.map((soil) => {
      if (soil.id === id) {
        return { ...soil, checked, checkedSoil: checked };
      }
      return soil;
    });

    setSoils(customSort(newSoil));

    setOs((prevOs) => {
      if (checked) {
        if (!prevOs.some((o) => o.soil === id)) {
          return [...prevOs, { soil: id, services: services, selectedSoil: id }];
        }
        return prevOs;
      } else {
        return prevOs.filter((o) => o.soil !== id);
      }
    });
  };

  const handleSoilCheckedAll = () => {
    if (soils.every((soil) => soil.checked)) {
      const newSoil = soils.map((soil) => {
        return { ...soil, checked: false };
      });

      setOs((prevOs) => prevOs.filter((o) => !newSoil.some((soil) => soil.id === o.soil)));

      setSoils(customSort(newSoil));
    } else {
      const newSoil = soils.map((soil) => {
        return { ...soil, checked: true };
      });

      setOs((prevOs) => {
        const newOs = [...prevOs];
        newSoil.forEach((soil) => {
          if (!newOs.some((o) => o.soil === soil.id)) {
            newOs.push({ soil: soil.id, services: services, selectedSoil: soil.id });
          }
        });
        return newOs;
      });

      setSoils(newSoil);
    }
  };

  const handleServiceCheckedAll = () => {
    if (services.every((o) => o.checked)) {
      const newService = services.map((s) => {
        return { ...s, checked: false };
      });

      setOs((prevOs) =>
        prevOs.map((o) => {
          if (o.soil === selectedSoilServices.soil) {
            return { ...o, services: [] };
          }
          return o;
        })
      );

      setServices(newService);
    } else {
      const newService = services.map((s) => {
        return { ...s, checked: true };
      });

      setOs((prevOs) =>
        prevOs.map((o) => {
          if (o.soil === selectedSoilServices.soil) {
            return { ...o, services: newService };
          }
          return o;
        })
      );

      setServices(newService);
    }
  };

  const handleServiceChecked = (event, index) => {
    const checked = event.target.checked;
    const checkedService = services.find((s) => s.index === index);

    if (checked) {
      setOs((prevOs) =>
        prevOs.map((o) => {
          if (o.soil === selectedSoilServices.soil) {
            return { ...o, services: [...o.services, checkedService] };
          }
          return o;
        })
      );
    } else {
      setOs((prevOs) =>
        prevOs.map((o) => {
          if (o.soil === selectedSoilServices.soil) {
            return { ...o, services: o.services.filter((s) => s.index !== index) };
          }
          return o;
        })
      );
    }

    setServices((prevServices) =>
      prevServices.map((s) => {
        if (s.index === index) {
          return { ...s, checked };
        }
        return s;
      })
    );
  };

  const handleSoilToOS = () => {
    toggleDialog();
  };

  const handleRowClick = (serviceId) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(serviceId)
        ? prevRows.filter((id) => id !== serviceId)
        : [...prevRows, serviceId]
    );
  };

  const handleRemoveProductService = (soilIndex, serviceIndex, productIndex) => {
    setOs((prevOs) => {
      const newOs = [...prevOs];
      newOs[soilIndex] = {
        ...newOs[soilIndex],
        services: [...newOs[soilIndex].services],
      };
      newOs[soilIndex].services[serviceIndex] = {
        ...newOs[soilIndex].services[serviceIndex],
        products: [...newOs[soilIndex].services[serviceIndex].products],
      };
      newOs[soilIndex].services[serviceIndex].products.splice(productIndex, 1);
      return newOs;
    });
  };

  const onChangePerHectare = (soilIndex, serviceIndex, productIndex, value) => {
    setOs((prevOs) => {
      const newOs = [...prevOs];
      newOs[soilIndex] = {
        ...newOs[soilIndex],
        services: [...newOs[soilIndex].services],
      };
      newOs[soilIndex].services[serviceIndex] = {
        ...newOs[soilIndex].services[serviceIndex],
        products: [...newOs[soilIndex].services[serviceIndex].products],
      };
      newOs[soilIndex].services[serviceIndex].products[productIndex] = {
        ...newOs[soilIndex].services[serviceIndex].products[productIndex],
        perHectare: value,
      };
      return newOs;
    });
  };

  const handleInsertProductService = () => {
    let newOs = JSON.parse(JSON.stringify(os));

    const newProductData = {
      name: newProduct.name,
      category: newProduct.category.name || newProduct.category,
      perHectare: newProduct.perHectare,
      unit: newProduct.unit.name || newProduct.unit,
      formulation: newProduct.formulation.name,
      supplier: newProduct.supplier,
      unitPrice: newProduct.unitPrice,
    };

    // Inserir o novo produto
    newOs[selectedSoilForProducts.index].services[selectedServicesSoil.index].products.push(
      newProductData
    );

    // Ordenar os produtos pela formulação
    newOs[selectedSoilForProducts.index].services[selectedServicesSoil.index].products.sort(
      (a, b) => {
        const numberA = parseInt(a.formulation.split(' ')[0], 10);
        const numberB = parseInt(b.formulation.split(' ')[0], 10);
        return numberA - numberB;
      }
    );

    // Atualizar o estado
    setOs(newOs);

    // Limpar o produto novo e fechar o diálogo
    setNewProduct({});
    setOpenDialogProduct(false);
  };


  const handleRemoveServiceOs = (soilIndex, serviceId) => {
    setOs((prevOs) => {
      const newOs = [...prevOs];
      newOs[soilIndex].services = newOs[soilIndex].services.filter(
        (service) => service.idUnique !== serviceId
      );
      return newOs;
    });
  };

  const handleOpenDialogService = () => {
    const osForSelectedSoil = os.find((o) => o.soil === selectedSoilServices.soil);
    const newServices = services.filter((service) => service.checked);

    if (osForSelectedSoil) {
      setOs((prevOs) =>
        prevOs.map((o) => {
          if (o.soil === selectedSoilServices.soil) {
            return { ...o, services: [...newServices] }; // Crie uma nova lista de serviços
          }
          return o;
        })
      );
    } else {
      const newOs = [
        ...os,
        {
          soil: selectedSoilServices.soil,
          services: [...newServices], // Crie uma nova lista de serviços
        },
      ];

      setOs(newOs);
    }

    setOpenDialogService(false);
  };

  const removeGleba = (soilId) => {
    const idGleba = os.findIndex((o) => o.soil === soilId);

    axios.delete(`/v1/program/${os[idGleba].id}`).then((res) => {
      setNotification(dispatch, {
        open: true,
        title: "Gleba removida com sucesso",
        severity: "success",
        icon: "check_circle_outline",
        color: "success",
        timeout: 3000,
      });
      setOs((prevOs) => prevOs.filter((o) => o.soil !== soilId));
      setSoils((prevSoils) =>
        prevSoils.map((soil) => {
          if (soil.id === soilId.id) {
            return { ...soil, checked: false };
          }
          return soil;
        })
      );
    });
  };

  function parseNumberWithComma(input) {
    if (typeof input !== "string") {
      return NaN;
    }
    var cleanedInput = input.replace(",", ".");
    return parseFloat(cleanedInput);
  }

  return (
    <div>
      <TableContainer style={{ marginBottom: "1em" }}>
        <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
              <TableCell style={{ color: "inherit" }} colSpan={5} align="center">
                {plan.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="20%">ID: PLAN-00{plan?.id}</TableCell>
              <TableCell width="20%">Cliente: {plan?.customer?.name}</TableCell>
              <TableCell width="20%">Fazenda: {plan?.farm?.name} </TableCell>
              <TableCell width="20%">Cultura: {plan?.crop?.name}</TableCell>
              <TableCell width="20%">Data de Criação: {formatDate(plan?.startDate)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        gap={2}
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <MDButton variant="gradient" color="dark" onClick={() => setOpenDialog(true)}>
          selecionar gleba
        </MDButton>

        {os.length > 0 && (
          <MDButton variant="gradient" color="dark" onClick={() => setOpenDialogAddService(true)}>
            adicionar serviços
          </MDButton>
        )}

        {os.length > 0 &&
          os.every((os) => os.services.length > 0) &&
          os.every((os) =>
            os.services.every(
              (service) =>
                service.products.length > 0 &&
                service.products.every((product) => parseNumberWithComma(product.perHectare) > 0)
            )
          ) && (
            <MDButton variant="gradient" color="dark" onClick={() => handleSaveOs()}>
              salvar programa de manejo
            </MDButton>
          )}
      </Grid>

      {os.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            marginTop: "2em",
          }}
        >
          <h5 style={{ marginBottom: "1em", color: "#5D5D5D", fontWeight: 300 }}>
            Nenhuma Gleba selecionada, selecione as Glebas para abertura de ordem de serviço
          </h5>
        </div>
      )}

      {os.length > 0 &&
        os.map((o, soilIndex) => (
          <Fragment key={soilIndex}>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1em",
                marginTop: "1em",
                marginBottom: "0.5em",
              }}
            >
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleRowClick(`${soilIndex}`)}
              >
                Gleba {soilIndex + 1}:{" "}
                {o?.soil?.name || soils.filter((soil) => soil.id === o.soil)[0]?.name}
                {expandedRows.includes(`${soilIndex}`) ? (
                  <KeyboardArrowUpIcon fontSize="medium" strokeWidth={2} />
                ) : (
                  <KeyboardArrowDownIcon fontSize="medium" strokeWidth={2} />
                )}
              </h3>

              {hasOs && (
                <DeleteForeverIcon
                  onClick={() => removeGleba(o.soil)}
                  style={{ cursor: "pointer", color: "red", width: "20px", height: "20px" }}
                ></DeleteForeverIcon>
              )}
            </Grid>

            {o.services.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                  marginTop: "2em",
                }}
              >
                <h5 style={{ marginBottom: "1em", color: "#5D5D5D", fontWeight: 300 }}>
                  Nenhum serviço selecionado, selecione os serviços para abertura de ordem de
                  serviço
                </h5>
              </div>
            )}
            <Collapse in={expandedRows.includes(`${soilIndex}`)} timeout="auto" unmountOnExit>
              {o.services.length > 0 &&
                o.services.map((service, serviceIndex) => (
                  <TableContainer style={{ marginBottom: "10px" }} key={serviceIndex}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      <TableHead style={{ display: "contents" }}>
                        <TableRow
                          style={{
                            backgroundColor: "#00000099",
                            color: "white",
                          }}
                        >
                          <TableCell
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              color: "inherit",
                              textTransform: "uppercase",
                            }}
                            colSpan={7}
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRowClick(`${soilIndex}-${serviceIndex}`)}
                            >
                              <span
                                style={{
                                  marginRight: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {service?.name}
                                {expandedRows.includes(`${soilIndex}-${serviceIndex}`) ? (
                                  <KeyboardArrowUpIcon fontSize="medium" strokeWidth={2} />
                                ) : (
                                  <KeyboardArrowDownIcon fontSize="medium" strokeWidth={2} />
                                )}
                              </span>
                              {service.defaultService ? "Serviço Base" : ""}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "1em",
                              }}
                            >
                              {service?.status !== "done" && service?.status !== "done_delayed" && (
                                <>
                                  <MDButton
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSelectedSoilForProducts({ ...o, index: soilIndex });
                                      setSelectedServicesSoil({
                                        ...service,
                                        index: serviceIndex,
                                      });
                                      setOpenDialogProduct(true);
                                    }}
                                  >
                                    Adicionar produtos
                                  </MDButton>
                                  <MDButton
                                    variant="gradient"
                                    color="error"
                                    disabled={service.defaultService ? true : false}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleRemoveServiceOs(soilIndex, service.idUnique);
                                    }}
                                  >
                                    Remover serviço {serviceIndex + 1}
                                  </MDButton>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ padding: "0px" }}>
                            <Collapse
                              in={expandedRows.includes(`${soilIndex}-${serviceIndex}`)}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table>
                                <TableBody>
                                  <TableRow
                                    style={{ backgroundColor: "#40404099", color: "white" }}
                                  >
                                    <TableCell style={{ color: "inherit" }}>Produto</TableCell>
                                    <TableCell style={{ color: "inherit" }}>Categoria</TableCell>
                                    <TableCell style={{ color: "inherit" }}>Unidade</TableCell>
                                    <TableCell style={{ color: "inherit" }}>Área</TableCell>
                                    <TableCell style={{ color: "inherit" }}>Dose/ha</TableCell>
                                    <TableCell style={{ color: "inherit" }}>Ações</TableCell>
                                  </TableRow>
                                  {service?.products.length ? (
                                    service?.products?.map((product, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{product?.name}</TableCell>
                                        <TableCell>{product?.category}</TableCell>
                                        <TableCell>{product?.unit}</TableCell>
                                        <TableCell>{o.soil ? o.soil.area : ""}</TableCell>
                                        <TableCell>
                                          <MDInput
                                            type="text"
                                            name="perHectare"
                                            value={product?.perHectare}
                                            onChange={(e) => {
                                              const regex = /[^0-9,]/g; // O regex agora corresponde a caracteres que não são números ou vírgulas

                                              const value = e.target.value.replace(regex, "");

                                              onChangePerHectare(
                                                soilIndex,
                                                serviceIndex,
                                                index,
                                                value
                                              );
                                            }}
                                            style={{ width: "80px" }}
                                          />
                                        </TableCell>

                                        <TableCell>
                                          <MDButton
                                            variant="gradient"
                                            color="error"
                                            disabled={
                                              service?.products?.length === 1 ||
                                              service.status === "done" ||
                                              service.status === "done_delayed"
                                            }
                                            onClick={() =>
                                              handleRemoveProductService(
                                                soilIndex,
                                                serviceIndex,
                                                index
                                              )
                                            }
                                          >
                                            remover
                                          </MDButton>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={6}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            width: "100%",
                                            flexDirection: "column",
                                            marginTop: "2em",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              marginBottom: "1em",
                                              color: "#5D5D5D",
                                              fontWeight: 300,
                                            }}
                                          >
                                            Não há produtos cadastrados para este serviço
                                          </h3>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))}
            </Collapse>
          </Fragment>
        ))}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Incluir glebas
          <DialogContentText id="alert-dialog-description">
            Selecione as glebas que farão parte da ordem de serviço
            <FormControlLabel
              control={
                <Checkbox
                  checked={soils.every((soil) => soil.checked)}
                  onChange={handleSoilCheckedAll}
                  name="checkedAll"
                  color="primary"
                />
              }
              label="Selecionar todos"
            />
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          {soils.map((soil, index) => (
            <div>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={soil.checked}
                    onChange={(event) => handleSoilChecked(event, soil.id)}
                    name={soil.name}
                    color="primary"
                    disabled={soil.checked && hasOs}
                  />
                }
                label={soil.name}
              />
              <Divider variant="fullWidth" />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => toggleDialog()}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            autoFocus
            type="submit"
            onClick={() => handleSoilToOS()}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogService}
        onClose={() => {
          setOpenDialogService(false);
          setSelectedSoilServices(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Selecionar Serviços
          <DialogContentText id="alert-dialog-description">
            Selecione os serviços que quer gerar a ordem de serviço
            <FormControlLabel
              control={
                <Checkbox
                  checked={services.every((s) => s.checked)}
                  onChange={handleServiceCheckedAll}
                  name="checkedAll"
                  color="primary"
                />
              }
              label="Selecionar todos"
            />
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          {services.map((service, index) => {
            return (
              <div>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={service.checked}
                      onChange={(event) => handleServiceChecked(event, service.index)}
                      name={service.name}
                      color="primary"
                    />
                  }
                  label={service.name}
                />
                <Divider variant="fullWidth" />
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="text"
            color="secondary"
            onClick={() => {
              setSelectedSoilServices(null);
              setOpenDialogService(false);
            }}
          >
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            autoFocus
            type="submit"
            onClick={() => {
              setSelectedSoilServices(null);
              setOpenDialogService(false);
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogProduct}
        onClose={() => {
          setNewProduct({});
          setOpenDialogProduct(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Adicionar Novo Produto</DialogTitle>
        <DialogContent>
          <Grid container gap={1}>
            <Grid marginTop={2}>
              <MDInput
                id="soil"
                label="Nome da gleba"
                type="text"
                value={
                  selectedSoilForProducts?.soil?.name ||
                  soils.filter((soil) => soil?.id === selectedSoilForProducts?.soil)[0]?.name ||
                  ""
                }
                disabled
                InputLabelProps={{ shrink: true }} // Tente adicionar esta propriedade
              />
            </Grid>

            <Grid marginTop={2}>
              <MDInput
                id="soil"
                label="Nome do serviço"
                type="text"
                value={selectedServicesSoil?.name}
                disabled
              />
            </Grid>
          </Grid>

          <Autocomplete
            style={{
              marginTop: "1em",
            }}
            disablePortal
            autoFocus
            id="product"
            options={productList}
            required
            value={{ id: 0, name: newProduct.name || "" }}
            getOptionLabel={(option) => option.name}
            onChange={(_, newValue) => {
              // checar se o produto selecionado já existe na lista de produtos da OS, se existe, usar ele
              if (
                os
                  .map((o) => o.services)
                  .flatMap((s) => s.map((p) => p.products))
                  .flatMap((p) => p)
                  .filter((p) => p.name === newValue.name)[0]
              ) {
                const product = os
                  .map((o) => o.services)
                  .flatMap((s) => s.map((p) => p.products))
                  .flatMap((p) => p)
                  .filter((p) => p.name === newValue.name)[0];

                const productMerge = Object.assign({}, product, newValue);

                setNewProduct({
                  ...newProduct,
                  ...productMerge,
                });
              } else {
                setNewProduct({
                  ...newProduct,
                  ...newValue,
                });
              }
            }}
            onInputChange={(event, value, reason) => {
              if (reason === "clear") {
                setNewProduct({});
              }
            }}
            openOnFocus
            renderInput={(params) => <MDInput {...params} label="Produto" required />}
            renderOption={(props, option) => (
              <li {...props} optionId={option.id}>
                {option.name}
              </li>
            )}
          />
          <RealInput
            style={{
              marginTop: "1em",
            }}
            label="Custo Unitário"
            value={newProduct.unitPrice || ""}
            onChange={(e) => {
              setNewProduct({ ...newProduct, unitPrice: e.target.value });
            }}
            fullWidth
          />
          <Autocomplete
            style={{
              marginTop: "1em",
            }}
            disablePortal
            id="supplier"
            options={suppliers}
            value={{ id: 0, name: newProduct.supplier || "" }}
            getOptionLabel={(option) => option.name}
            openOnFocus
            onChange={(_, newValue) => {
              setNewProduct({ ...newProduct, supplier: newValue.name });
            }}
            renderInput={(params) => <MDInput {...params} label="Fornecedor" fullWidth required />}
          />

          <MDInput
            type="text"
            label="Dose/ha"
            value={newProduct.perHectare || ""}
            onChange={(e) => {
              const regex = /[^0-9,]/g;

              const value = e.target.value.replace(regex, "");

              setNewProduct({ ...newProduct, perHectare: value });
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="text"
            color="secondary"
            onClick={() => {
              setNewProduct({});
              setOpenDialogProduct(false);
            }}
          >
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            autoFocus
            type="submit"
            onClick={() => handleInsertProductService()}
          >
            adicionar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogAddService}
        onClose={() => {
          setStep2AddService(0);
          setNewService({ name: "", products: [] });
          setOpenDialogAddService(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Adicionar serviço
          {step2AddService === 2 ? (
            <DialogContentText id="alert-dialog-description">
              Selecione as glebas que quer adicionar o serviço
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSoilsAddService.length === os.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedSoilsAddService(os.map((o) => o.soil));
                      } else {
                        setSelectedSoilsAddService([]);
                      }
                    }}
                    name="checkedAll"
                    color="primary"
                  />
                }
                label="Selecionar todos"
              />
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Qual o nome do serviço que deseja adicionar?
            </DialogContentText>
          )}
        </DialogTitle>
        <DialogContent>
          {step2AddService === 0 && (
            <>
              <Autocomplete
                id="service"
                label="Nome do serviço"
                type="text"
                options={customSort(serviceList) || [] || ""}
                value={{ id: 0, name: newService.name || "" }}
                required
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                  const data = {
                    ...newValue,
                    checked: true,
                    index: services.length + 1,
                    idUnique: services.length + 1,
                    indexSum: 0,
                    dateInitial: 0,
                    dateFinish: 0,
                    defaultService: false,
                    products: [
                      { id: "", name: "", category: "", unit: "", formulation: "", perHectare: "" },
                    ],
                  };
                  setNewService(data);
                }}
                openOnFocus
                renderInput={(params) => <MDInput {...params} label="Serviço" required />}
                renderOption={(props, option) => (
                  <li {...props} optionId={option.id}>
                    {option.name}
                  </li>
                )}
              />

              {error.active && (
                <div style={{ color: "red", fontSize: "12px" }}>{error.message}</div>
              )}
            </>
          )}

          {step2AddService === 1 && (
            <>
              {newService.products.map((product, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>Produto {index + 1}</h4>
                    <MDButton
                      variant="text"
                      color="error"
                      disabled={newService.products.length === 1}
                      onClick={() => {
                        setNewService({
                          ...newService,
                          products: [
                            ...newService.products.slice(0, index),
                            ...newService.products.slice(index + 1),
                          ],
                        });
                      }}
                    >
                      remover produto
                    </MDButton>
                  </div>

                  <Autocomplete
                    disablePortal
                    autoFocus
                    id="product"
                    options={productList}
                    value={{ id: 0, name: product.name || "" }}
                    required
                    getOptionLabel={(option) => option.name}
                    onChange={(_, newValue) => {
                      if (
                        os
                          .map((o) => o.services)
                          .flatMap((s) => s.map((p) => p.products))
                          .flatMap((p) => p)
                          .filter((p) => p.name === newValue.name)[0]
                      ) {
                        const product = os
                          .map((o) => o.services)
                          .flatMap((s) => s.map((p) => p.products))
                          .flatMap((p) => p)
                          .filter((p) => p.name === newValue.name)[0];

                        const productMerge = Object.assign({}, product, newValue);

                        const newProductData = {
                          name: productMerge.name,
                          category: productMerge.category.name || productMerge.category,
                          perHectare: productMerge.perHectare,
                          unit: productMerge.unit.name || productMerge.unit,
                          formulation: productMerge.formulation.name,
                          supplier: productMerge.supplier,
                          unitPrice: productMerge.unitPrice,
                        };

                        setNewService({
                          ...newService,
                          products: [
                            ...newService.products.slice(0, index),
                            { ...newService.products[index], ...newProductData },
                            ...newService.products.slice(index + 1),
                          ],
                        });
                      } else {
                        const newProductData = {
                          name: newValue.name,
                          category: newValue.category.name,
                          perHectare: 0,
                          unit: newValue.unit.name,
                          formulation: newValue.formulation.name,
                        };

                        setNewService({
                          ...newService,
                          products: [
                            ...newService.products.slice(0, index),
                            { ...newService.products[index], ...newProductData },
                            ...newService.products.slice(index + 1),
                          ],
                        });
                      }
                    }}
                    openOnFocus
                    renderInput={(params) => <MDInput {...params} label="Produto" required />}
                    renderOption={(props, option) => (
                      <li {...props} optionId={option.id}>
                        {option.name}
                      </li>
                    )}
                  />
                  <RealInput
                    style={{
                      marginTop: "1em",
                    }}
                    label="Custo Unitário"
                    value={product.unitPrice || ""}
                    onChange={(e) => {
                      setNewService({
                        ...newService,
                        products: [
                          ...newService.products.slice(0, index),
                          { ...newService.products[index], unitPrice: e.target.value },
                          ...newService.products.slice(index + 1),
                        ],
                      });
                    }}
                    fullWidth
                  />

                  <Autocomplete
                    style={{
                      marginTop: "1em",
                    }}
                    disablePortal
                    id="supplier"
                    options={suppliers}
                    value={{ id: 0, name: product.supplier || "" }}
                    getOptionLabel={(option) => option.name}
                    openOnFocus
                    onChange={(_, newValue) => {
                      setNewService({
                        ...newService,
                        products: [
                          ...newService.products.slice(0, index),
                          { ...newService.products[index], supplier: newValue.name },
                          ...newService.products.slice(index + 1),
                        ],
                      });
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} label="Fornecedor" fullWidth required />
                    )}
                  />

                  <MDInput
                    label="Dose/ha"
                    value={product.perHectare || ""}
                    onChange={(e) => {
                      const regex = /[^0-9,]/g;

                      const value = e.target.value.replace(regex, "");

                      setNewService({
                        ...newService,
                        products: [
                          ...newService.products.slice(0, index),
                          { ...newService.products[index], perHectare: value },
                          ...newService.products.slice(index + 1),
                        ],
                      });
                    }}
                    fullWidth
                  />
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "20px" }}>
                      <MDButton
                        type="submit"
                        variant="contained"
                        color="dark"
                        iconOnly
                        circular
                        onClick={() => {
                          setNewService({
                            ...newService,
                            products: [
                              ...newService.products,
                              {
                                id: "",
                                name: "",
                                category: "",
                                unit: "",
                                formulation: "",
                                perHectare: "",
                              },
                            ],
                          });
                        }}
                      >
                        <AddIcon />
                      </MDButton>
                    </Grid>
                  </Grid>
                  {index > 0 && <Divider />}
                </div>
              ))}
              {error.active && (
                <div style={{ color: "red", fontSize: "12px" }}>{error.message}</div>
              )}
            </>
          )}

          {step2AddService === 2 &&
            os &&
            os.map((o, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedSoilsAddService.includes(o.soil)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedSoilsAddService((prev) => [...prev, o.soil]);
                      } else {
                        setSelectedSoilsAddService((prev) => prev.filter((id) => id !== o.soil));
                      }
                    }}
                    name={
                      soils.filter((soil) => soil.id === o.soil || soil.id === o.soil.id)[0]?.name
                    }
                    color="primary"
                  />
                }
                label={soils.filter((soil) => soil.id === o.soil || soil.id === o.soil.id)[0]?.name}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="text"
            color="secondary"
            onClick={() => {
              setStep2AddService(0);
              setNewService({ name: "", products: [] });
              setOpenDialogAddService(false);
            }}
          >
            fechar
          </MDButton>
          {(step2AddService === 1 || step2AddService === 2) && (
            <MDButton
              variant="text"
              color="error"
              autoFocus
              type="submit"
              onClick={() => {
                setError({ active: false, message: "" });
                setStep2AddService((prev) => prev - 1);
              }}
            >
              voltar
            </MDButton>
          )}

          {(step2AddService === 0 || step2AddService === 1) && (
            <MDButton
              variant="text"
              color="error"
              autoFocus
              type="submit"
              onClick={() => {
                if (step2AddService === 0) {
                  if (
                    newService.hasOwnProperty("name") &&
                    newService.name !== "" &&
                    newService.hasOwnProperty("dateInitial") &&
                    newService.dateInitial !== "" &&
                    newService.hasOwnProperty("dateFinish") &&
                    newService.dateFinish !== ""
                  ) {
                    setStep2AddService((prev) => prev + 1);
                    setError({ active: false, message: "" }); // limpar qualquer mensagem de erro
                  } else {
                    setError({ active: true, message: "Todos os campos são obrigatórios." });
                  }
                }

                if (step2AddService === 1) {
                  if (newService.products.length === 0) {
                    setError({
                      active: true,
                      message: "O serviço precisa ter pelo menos um produto.",
                    });
                  } else if (
                    newService.products.every(
                      (product) => product.name && product.unitPrice && product.supplier
                    )
                  ) {
                    setStep2AddService((prev) => prev + 1);
                    setError({ active: false, message: "" });
                  } else {
                    setError({
                      active: true,
                      message: "Todos os campos dos produtos são obrigatórios.",
                    });
                  }
                }
              }}
            >
              avançar
            </MDButton>
          )}

          {step2AddService === 2 && (
            <>
              <MDButton
                variant="text"
                color="error"
                autoFocus
                type="submit"
                onClick={() => {
                  const newOs = os.map((o) => {
                    if (selectedSoilsAddService.includes(o.soil)) {
                      return {
                        ...o,
                        services: [...o.services, { ...newService }],
                      };
                    }
                    return o;
                  });

                  setOs(newOs);
                  setNewService({ name: "", products: [] });
                  setSelectedSoilsAddService([]);
                  setStep2AddService(0);
                  setOpenDialogAddService(false);
                }}
              >
                adicionar
              </MDButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
