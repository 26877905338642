/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.
*/

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MDInput from "components/MDInput";

import OrderListLayout from "examples/LayoutContainers/OrdersLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";

const columns = [
  { accessor: "customerId", Header: "ID", width: 300 },
  { accessor: "name", Header: "Cliente", width: 200 },
];

const statusDict = {
  SERVICE_ORDERS: "Ordens de serviço",
  ORDERS_RELEASED: "Ordens liberadas",
  SCHEDULE: "Agenda",
};

const filterOptions = ["Cliente"];

export default function OrderList() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [fieldName, setFieldName] = React.useState(filterOptions[0]);
  const [clientes, setClientes] = React.useState([]);
  const [cliente, setCliente] = React.useState({ id: "", name: "" });
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const axios = Axios();

  React.useEffect(() => {
    setLoading(true);
    setRows([]);
    axios
      .get(
        `/v1/customer?current=${page + 1}&pageSize=${rowsPerPage}&filter=name&value=${cliente.name}`
      )
      .then((res) => {
        const customerList = res.data.data.map((row) => ({
          ...row,
          customerId: <Link to={`/agenda/${row.id}`}>CLNT-00{row.id}</Link>,
          status: statusDict[row.status],
        }));
        setRows(customerList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    axios
      .get(`/v1/customer`)
      .then((res) => {
        setClientes(res.data.data.map((user) => ({ id: user.id, name: user.name })));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, rowsPerPage, cliente]);

  return (
    <OrderListLayout>
      <Paper sx={{ width: "100%", paddingTop: "1em" }}>
        <TableContainer>
          <FormControl
            style={{
              width: "30%",
              padding: "1em",
            }}
          >
            <Autocomplete
              disablePortal
              id="customers"
              options={clientes}
              value={cliente}
              getOptionLabel={(option) => option.name}
              openOnFocus
              onInputChange={(event, value, reason) => {
                if (reason === "clear") {
                  setCliente({ id: "", name: "" });
                }
              }}
              onChange={(e) => {
                setCliente({
                  id: e.target.attributes.optionid.value,
                  name: e.target.textContent,
                });
              }}
              renderInput={(params) => <MDInput {...params} label="Nome do Cliente" />}
              renderOption={(props, option) => (
                <li {...props} optionId={option.id}>
                  {option.name}
                </li>
              )}
            />
          </FormControl>
          <DataTable
            table={{ columns, rows }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
    </OrderListLayout>
  );
}
