/*eslint-disable*/
import {
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { formatDate } from "utils/formatDate";

export default function Step4({ planId, nextStep }) {
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([{}]);
  const [categories, setCategories] = useState([{}]);
  const [plans, setPlans] = useState([{}]);
  const [disabled, setDisabled] = useState(true);
  const [unitPrices, setUnitPrices] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [supplier, setSupplier] = useState({ id: "", name: "" });
  const [suppliers, setSuppliers] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const axios = Axios();
  const idPlan = id || planId;

  useEffect(() => {
    axios.get(`/v1/plans/${idPlan || id}`).then((res) => {
      setPlans(res.data);
      setServices(res.data.services);
      setProducts(
        res.data.services.flatMap((service) => {
          return service.products.map((product) => {
            return {
              ...product,
              serviceName: service.name,
              categoryName: product.category,
            };
          });
        })
      );
    });

    axios.get(`/v1/supplier`).then((res) => {
      setSuppliers(res.data.data.map((user) => ({ id: user.id, name: user.name })));
    });
  }, [id, planId]);

  const savePlan = () => {
    axios.patch(`/v1/plans/status/${idPlan}`, { status: "CHECKUP" }).then(() => {
      navigate("/planejamentos");
    });
  };

  const submitPlan = () => {
    axios.patch(`/v1/plans/status/${idPlan}`, { status: "CHECKUP" }).then(() => {
      nextStep();
    });
  };
  const categoryProductsMap = [];

  services.forEach((service) => {
    service.products.forEach((product) => {
      const category = product.category;
      const productName = product.name;
      const area = plans.area;

      // Create the product in the categoryProductsMap object if it doesn't exist yet
      if (!categoryProductsMap[category]) {
        categoryProductsMap[category] = [];
      }

      // Find the existing product in the categoryProductsMap object
      const existingProduct = categoryProductsMap[category].find(
        (existingProduct) => existingProduct.productName === productName
      );

      const custoHa = product.unitPrice * product.perHectare;

      // If the product doesn't exist, create a new one
      if (!existingProduct) {
        categoryProductsMap[category].push({
          productName,
          services: [service.name],
          unitPrice: [product.unitPrice],
          unit: [product.unit],
          perHectare: [product.perHectare],
          area,
          custoHa,
        });
      } else {
        // If the product exists, add the new service to the existing product
        existingProduct.services.push(service.name);
        existingProduct.unitPrice.push(product.unitPrice);
        existingProduct.unit.push(product.unit);
        existingProduct.perHectare.push(product.perHectare);
      }
    });
  });

  function handlePrintButtonClick() {
    window.document.title = "Relatório Step 4 - Orçamento - " + plans?.name;
    window.print();
    window.document.title = "NuAgro";
  }

  let somaCustoTotal = 0;

  // Itera sobre as categorias
  Object.keys(categoryProductsMap).forEach((category) => {
    const categoryTotal = categoryProductsMap[category].reduce((total, product) => {
      const productTotal = product.services.reduce(
        (serviceTotal, service) =>
          serviceTotal +
          product.perHectare[product.services.indexOf(service)] *
            product.unitPrice[product.services.indexOf(service)] *
            product.area,
        0
      );
      return total + productTotal;
    }, 0);

    // Adicione o valor do custo total da categoria à variável de soma
    somaCustoTotal += categoryTotal;
  });

  let somaCustoPorHa = 0;

  // Itera sobre as categorias
  Object.keys(categoryProductsMap).forEach((category) => {
    const custoHa = categoryProductsMap[category].reduce((total, product) => {
      const productTotal = product.services.reduce(
        (serviceTotal, service) =>
          serviceTotal +
          product.unitPrice[product.services.indexOf(service)] *
            product.perHectare[product.services.indexOf(service)],
        0
      );
      return total + productTotal;
    }, 0);

    // Adicione o valor do custo Dose/ha da categoria à variável de soma
    somaCustoPorHa += custoHa;
  });

  return (
    <>
      <TableContainer style={{ marginBottom: "1em" }}>
        <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
              <TableCell style={{ color: "inherit" }} colSpan={5} align="center">
                {plans?.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="20%">ID: PLAN-00{plans.id || id || idPlan} </TableCell>
              <TableCell width="20%">Cliente: {plans?.customer?.name}</TableCell>
              <TableCell width="20%">Fazenda: {plans?.farm?.name} </TableCell>
              <TableCell width="20%">Cultura: {plans?.crop?.name}</TableCell>
              <TableCell width="20%">Data de Criação: {formatDate(plans?.startDate)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <>
        <Container
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <style>
            {`
              .MuiTableCell-root {
                border-bottom: 1px solid black;
                margin: 0px;
                padding: 0px;
              }
              .MuiTable-root {
                border-collapse: collapse;
                width: 100%;
                margin: 0px;
                
              }
              
            `}
          </style>

          <TableContainer
            style={{
              borderRadius: 10,
              boxShadow: "none",

              padding: 10,
              margin: "auto",
            }}
          >
            <TableHead
              style={{
                borderRadius: 10,
                boxShadow: "none",

                padding: 20,
                margin: "auto",
              }}
            >
              <TableHead style={{ display: "flex", flexDirection: "column", padding: "0px" }}>
                <TableRow>
                  <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                    {plans?.harvest?.name}; {plans?.harvestTime?.name}; {plans?.crop?.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                    Área total: {plans.area} há
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead style={{ display: "contents" }}>
                <TableRow
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                    Custo/ha:
                    {somaCustoPorHa.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </TableCell>
                  <TableCell
                    style={{ border: "none", padding: "0px", paddingLeft: "16px" }}
                    colSpan={12}
                  >
                    Valor total:{" "}
                    {somaCustoTotal.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
            </TableHead>

            {Object.keys(categoryProductsMap)
              .sort((a, b) => a.localeCompare(b))
              .map((category) => {
                const categoryTotal = categoryProductsMap[category].reduce((total, product) => {
                  const productTotal = product.services.reduce(
                    (serviceTotal, service) =>
                      serviceTotal +
                      product.perHectare[product.services.indexOf(service)] *
                        product.unitPrice[product.services.indexOf(service)] *
                        product.area,
                    0
                  );
                  return total + productTotal;
                }, 0);

                const custoHa = categoryProductsMap[category].reduce((total, product) => {
                  const productTotal = product.services.reduce(
                    (serviceTotal, service) =>
                      serviceTotal +
                      product.perHectare[product.services.indexOf(service)] *
                        product.unitPrice[product.services.indexOf(service)],
                    0
                  );

                  return total + productTotal;
                }, 0);

                return (
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell colSpan={6} style={{ border: "none" }}></TableCell>
                      </TableRow>
                    </TableHead>

                    <Fragment>
                      <TableHead style={{ display: "contents" }}>
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            style={{ border: "none", paddingTop: 35 }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead style={{ display: "contents", paddingBottom: "16px" }}>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={4}>
                            {category.toUpperCase()} (
                            {((categoryTotal / somaCustoTotal) * 100).toFixed(1)} %)
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={2}>
                            {custoHa.toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>

                          <TableCell style={{ backgroundColor: "#D8D8D8" }}>
                            {categoryTotal.toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead style={{ display: "contents" }}>
                        <TableRow>
                          <TableCell colSpan={6} style={{ border: "none" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      {categoryProductsMap[category]
                        .sort((a, b) => a.productName.localeCompare(b.productName))
                        .map((product, index) => {
                          const totalCostPerHa = product.services.reduce(
                            (total, service, index) => {
                              const perHectareValue = Array.isArray(product.perHectare)
                                ? product.perHectare[index]
                                : product.perHectare;

                              const unitPriceValue = Array.isArray(product.unitPrice)
                                ? product.unitPrice[index]
                                : product.unitPrice;

                              if (!isNaN(perHectareValue) && !isNaN(unitPriceValue)) {
                                return total + perHectareValue * unitPriceValue;
                              } else {
                                return total; // Ignorar valores não numéricos
                              }
                            },
                            0
                          );

                          const totalCostProduct = product.services.reduce(
                            (total, service, index) => {
                              const perHectareValue = Array.isArray(product.perHectare)
                                ? product.perHectare[index]
                                : product.perHectare;
                              {
                              }
                              const unitPriceValue = Array.isArray(product.unitPrice)
                                ? product.unitPrice[index]
                                : product.unitPrice;

                              if (!isNaN(perHectareValue) && !isNaN(unitPriceValue)) {
                                return total + perHectareValue * plans.area;
                              } else {
                                return total; // Ignorar valores não numéricos
                              }
                            },
                            0
                          );

                          const custoTotal = product.services.reduce((total, service, index) => {
                            const perHectareValue = Array.isArray(product.perHectare)
                              ? product.perHectare[index]
                              : product.perHectare;

                            const unitPriceValue = Array.isArray(product.unitPrice)
                              ? product.unitPrice[index]
                              : product.unitPrice;

                            if (!isNaN(perHectareValue) && !isNaN(unitPriceValue)) {
                              return total + perHectareValue * unitPriceValue * plans.area;
                            } else {
                              return total; // Ignorar valores não numéricos
                            }
                          }, 0);

                          const porcentagem = ((custoTotal / somaCustoTotal) * 100).toFixed(1);
                          const porcentagemFormatada = `(${porcentagem} %)`;

                          return (
                            <TableBody key={index}>
                              <TableHead style={{ display: "contents" }}>
                                <TableRow>
                                  <TableCell
                                    style={{ border: "none", paddingBottom: 35 }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead style={{ display: "contents" }}>
                                <TableRow>
                                  <TableCell colSpan={4}>
                                    {product.productName.toUpperCase()} {porcentagemFormatada}
                                  </TableCell>
                                  <TableCell>
                                    {totalCostPerHa.toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {totalCostProduct.toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {custoTotal.toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead style={{ display: "contents", paddingTop: "16px" }}>
                                <TableRow>
                                  <TableCell>Serviço</TableCell>
                                  <TableCell>Und</TableCell>
                                  <TableCell>Dose/ha</TableCell>
                                  <TableCell>Custo unit</TableCell>
                                  <TableCell>Custo/ha</TableCell>
                                  <TableCell>Total de produto</TableCell>
                                  <TableCell colSpan={7}>Custo Total</TableCell>
                                </TableRow>
                              </TableHead>

                              {product.services.map((service, serviceIndex) => (
                                <TableRow key={serviceIndex}>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {service}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {product.unit[serviceIndex]}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {product.perHectare[serviceIndex]}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {product.unitPrice[serviceIndex]}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {(
                                      product.perHectare[serviceIndex] *
                                      product.unitPrice[serviceIndex]
                                    ).toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {(
                                      product.area * product.perHectare[serviceIndex]
                                    ).toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                  <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                    {(
                                      product.perHectare[serviceIndex] *
                                      product.unitPrice[serviceIndex] *
                                      product.area
                                    ).toLocaleString("pt-BR", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          );
                        })}
                    </Fragment>
                  </Table>
                );
              })}
          </TableContainer>
        </Container>
      </>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} textAlign="end">
          <Stack direction="row-reverse" spacing={2} style={{ textAlign: "end" }}>
            <MDButton
              className="no-print"
              form="form-product-slim"
              variant="gradient"
              color="dark"
              onClick={submitPlan}
            >
              Continuar planejamento
            </MDButton>
            <MDButton
              className="no-print"
              form="form-product-slim"
              variant="gradient"
              color="dark"
              onClick={savePlan}
            >
              Salvar
            </MDButton>
            <MDButton
              className="no-print"
              onClick={handlePrintButtonClick}
              variant="gradient"
              color="dark"
            >
              Exportar PDF
            </MDButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
