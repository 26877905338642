/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Axios from "utils/axiosInstance";
import logoNovo from "assets/images/logo_novo.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {
  setMiniSidenav,
  setNotification,
  setTransparentNavbar,
  useMaterialUIController,
} from "context";

import "./styles.css";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  // const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const [openAccountEditor, setOpenAccountEditor] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const axios = Axios(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword.length < 6) {
      console.log("A senha deve possuir no mínimo seis caracteres");
      setOpenAccountEditor(false);
      setNotification(dispatch, {
        title: "A nova senha deve ter pelo menos seis caracteres.",
        color: "error",
        icon: "error",
        open: true,
      });
      setCurrentPassword("");
      setNewPassword("");
      return;
    }

    const userData = {
      email,
      currentPassword,
      newPassword,
    };
    axios
      .put(`/v1/auth/updatepass/${loggedUser.id}`, userData)
      .then((res) => {
        setOpenAccountEditor(false);
        setNotification(dispatch, {
          title: "Usuário atualizado.",
          color: "success",
          icon: "success",
          open: true,
        });
        setCurrentPassword("");
        setNewPassword("");
      })
      .catch((err) => {
        console.log("error", err);
        // setNotificationError(true);
      });
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("activeTab");
    navigate("/auth/login");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<AccountCircleIcon />}
        title="Perfil"
        onClick={() => {
          setEmail(loggedUser.email);
          setOpenAccountEditor(true);
        }}
      />
      <NotificationItem icon={<LogoutIcon />} title="Sair" onClick={logout} />
    </Menu>
  );

  return (
    <div className="navbar">
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="black"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox style={{ width: "4rem" }} />
          <MDBox color="white" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <img src={logoNovo} alt="logo" style={{ width: "150px" }} />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              {/* <MDBox pr={1}>
                <MDInput label="Search here" />
              </MDBox> */}
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="white"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon>account_circle</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="white"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon fontSize="medium">{miniSidenav ? "menu_open" : "menu"}</Icon>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
      <Dialog open={openAccountEditor} onClose={() => setOpenAccountEditor(false)}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Editar Conta</DialogTitle>
          <DialogContent>
            <MDInput
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
            />
            <MDInput
              type="password"
              label="Senha Atual"
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              fullWidth
            />
            <MDInput
              type="password"
              label="Nova Senha"
              onChange={(e) => setNewPassword(e.target.value)}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <MDButton variant="text" color="error" onClick={() => setOpenAccountEditor(false)}>
              Fechar
            </MDButton>
            <MDButton type="submit" variant="text" color="secondary">
              Atualizar
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
