/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";

// Authentication layout components
import * as React from "react";
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import bgImage from "assets/images/page404.jpg";
// import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

function Basic() {
  const [redirectLink, setRedirectLink] = React.useState("/clientes");

  React.useEffect(() => {
    const mUser = JSON.parse(localStorage.getItem("user"));

    if (mUser?.typePerson === "Users") {
      setRedirectLink("/clientes");
    } else if (mUser?.typePerson === "Users.Collaborators") {
      setRedirectLink("/colaborador/servicos");
    }
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <MDBox sx={{ width: "100%", maxWidth: 500, position: "relative" }}>
        <MDTypography variant="h1" component="div" color="white" gutterBottom>
          Página não encontrada. <Link to={redirectLink}>Voltar para cadastros</Link>
        </MDTypography>
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
