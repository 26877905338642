/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import MDSnackbar from "components/MDSnackbar";
import jwt from "jwt-decode";

// Material Dashboard 2 React components
import "css/custom.css";

// Material Dashboard 2 React example components
import CenteredTabs from "examples/Tabs";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { setLayout, setNotification, useMaterialUIController } from "context";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { direction, layout, darkMode, notification, isLoading } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const layoutName = sessionStorage.getItem("layout");
    setLayout(dispatch, layoutName);
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const jwtTokwn = jwt(user.accessToken);
      const date1 = new Date(jwtTokwn.exp * 1000);
      const date2 = new Date();
      const isExpired = date2.getTime() - date1.getTime();
      const refreshToken = user?.refreshToken;

      if (isExpired >= 0 && refreshToken) {
        localStorage.removeItem("user");
      }
    }
    if (!user?.accessToken) {
      navigate("/auth/login");
    }
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const closeNotification = () => {
    setNotification(dispatch, { ...notification, open: false });
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" ||
      layout === "planning" ||
      layout === "orders" ||
      layout === "reports" ? (
        <CenteredTabs />
      ) : null}
      {layout === "collaborator" ? (
        <Box sx={{ width: "100%", bgcolor: "#7D9D4A" }}>
          <DashboardNavbar />
        </Box>
      ) : null}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
      <Backdrop
        sx={{ color: "#fff", zIndex: (mTheme) => mTheme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification.open && (
        <MDSnackbar
          color={notification?.color || "error"}
          icon={notification?.icon || "warning"}
          title={notification.title}
          content={notification?.content || ""}
          open={notification.open}
          onClose={closeNotification}
          close={closeNotification}
          bgWhite
        />
      )}
    </ThemeProvider>
  );
}
