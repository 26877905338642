/* eslint-disable */
import axios from "axios";

export const FetchBrStates = () => {
  return axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    .then((response) => {
      return response.data.map((state) => ({ stateName: state.nome, stateId: state.id }));
    })
    .catch((error) => {
      console.error('Error fetching states:', error);
      return [];
    });
};



export const FetchCities = (stateId) => {
  return axios
    .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`)
    .then((res) => {
      return res.data.map((city) => ({
        cityUf: city.uf,
        cityName: city.nome,
      }));
    })
    .catch((error) => {
      console.error("Erro ao buscar cidades:", error);
      return [];
    });
};
