/* eslint-disable*/

import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import Axios from "utils/axiosInstance";
import StyledMenu from "./components/headTable";

// import StyledMenu from "./components/headTable";

const filterOptions = ["Nome", "Fazenda"];

const columns = [
  { accessor: "name", Header: "Gleba", width: 300 },
  { accessor: "farm.name", Header: "Nome da Fazenda", width: 300 },
  { accessor: "area", Header: "Área (ha)", width: 300 },
  { accessor: "actions", Header: "Ações", width: 300 },
];

function translateOption(option) {
  switch (option) {
    case "Nome":
      return "name";
    case "Fazenda":
      return "farm";
    default:
      return option;
  }
}

export default function ProductsList() {
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [farm, setFarm] = React.useState({ id: "", name: "" });
  const [area, setArea] = React.useState(null);
  const [updateData, setUpdateData] = React.useState({
    id: "",
    name: "",
    farm: { id: "", name: "" },
    area: 0,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [farms, setFarms] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [createLoading, setCreateLoading] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [fieldName, setFieldName] = React.useState(filterOptions[0]);
  const [filter, setFilter] = React.useState("");
  const axios = Axios();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const updateRows = () => {
    setLoading(true);
    setRows([]);
    axios
      .get(
        `/v1/soil?current=${page + 1}&pageSize=${rowsPerPage}&filter=${translateOption(
          fieldName
        )}&value=${filter}`
      )
      .then((res) => {
        const harvestList = res.data.data.map((row) => ({
          ...row,
          name: row.name,
          farm: { id: row.farm.id, name: row.farm.name },
          area: row.area,
          actions: (
            <>
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  setOpenEditDialog(true);
                  setUpdateData({
                    id: row.id,
                    name: row.name,
                    farm: row.farm,
                    area: row.area,
                  });
                }}
                iconOnly
              >
                <Icon>editar</Icon>
              </MDButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MDButton
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setUpdateData({
                    id: row.id,
                    name: row.name,
                    farm: row.farm,
                    area: row.area,
                  });
                }}
                iconOnly
              >
                <DeleteIcon />
              </MDButton>
            </>
          ),
        }));
        setRows(harvestList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // React.useEffect(() => {
  //   if (name.replace(/\D/g, "").length < 3 && name.replace(/\D/g, "").length > 0) {
  //     setNameError(true);
  //   } else {
  //     setNameError(false);
  //   }
  // }, [name]);

  const handleCheckLengthName = (value) => {
    if (value.trim().length < 3 && value.trim().length > 0) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  React.useEffect(() => {
    axios
      .get(`/v1/farm`)
      .then((res) => {
        const mFarms = res.data.data.map((mFarm) => ({ id: mFarm.id, name: mFarm.name }));
        setFarms(mFarms);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    updateRows();
  }, [page, rowsPerPage, filter]);

  const onsubmit = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .post("/v1/soil", { name, farm, area: Number(area) })
      .then(() => {
        updateRows();
        setName("");
        setFarm({ id: "", name: "" });
        setArea(0);
        setCreateLoading(false);
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  const updateName = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .put(`/v1/soil/${updateData.id}`, {
        name: updateData.name,
        farm: updateData.farm,
        area: updateData.area,
      })
      .then(() => {
        setCreateLoading(false);
        setUpdateData({ id: "", name: "", farm: { id: "", name: "" }, area: 0 });
        setOpenEditDialog(false);
        updateRows();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTrigger = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .delete(`/v1/soil/${updateData.id}`)
      .then(() => {
        setCreateLoading(false);
        setOpenDeleteDialog(false);
        updateRows();
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Cadastrar nova Gleba
          </h3>
        </Typography>

        <TableContainer>
          <form
            onSubmit={onsubmit}
            style={{ width: "100%", display: "inline-block", paddingLeft: "24px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Autocomplete
                  disablePortal
                  id="farms"
                  options={farms}
                  value={farm}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(e) => {
                    setFarm({
                      id: e.target.attributes.optionid.value,
                      name: e.target.textContent,
                    });
                  }}
                  renderInput={(params) => <MDInput {...params} label="Fazenda" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <MDInput
                  type="text"
                  label="Nome da Gleba"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    handleCheckLengthName(e.target.value);
                  }}
                  required
                  fullWidth
                  error={nameError}
                />
              </Grid>
              <Grid item xs={2}>
                <MDInput
                  type="number"
                  label="Área (ha)"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} mt={2}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={createLoading || nameError}
                  type="submit"
                  fullWidth
                >
                  Salvar
                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Divider variant="fullWidth" />
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingBottom: "24px",
                display: "inline-block",
              }}
            >
              Lista de Glebas
            </h3>
            <div>
              <StyledMenu
                filterLabel="Filtrar glebas por"
                filterOptions={filterOptions}
                fieldName={fieldName}
                setFieldName={setFieldName}
              />
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                placeholder="Pesquisar"
                onChange={handleFilterChange}
                style={{
                  marginRight: "24px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </Typography>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer deletar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDeleteDialog(false)}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            onClick={deleteTrigger}
            disabled={createLoading}
            autoFocus
          >
            Deletar
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        disabled={createLoading}
      >
        <form onSubmit={updateName}>
          <DialogTitle>Editar Gleba</DialogTitle>
          <DialogContent>
            <Autocomplete
              disablePortal
              id="farms-edit"
              options={farms}
              value={updateData.farm}
              getOptionLabel={(option) => option.name}
              openOnFocus
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  farm: {
                    id: e.target.attributes.optionid.value,
                    name: e.target.textContent,
                  },
                });
              }}
              renderInput={(params) => <MDInput {...params} label="Fazenda" required />}
              renderOption={(props, option) => (
                <li {...props} optionId={option.id}>
                  {option.name}
                </li>
              )}
            />
            <MDInput
              type="text"
              label="Nome da Gleba"
              value={updateData.name}
              onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
              required
              fullWidth
            />
            <MDInput
              type="number"
              label="Área (ha)"
              value={updateData.area}
              onChange={(e) => setUpdateData({ ...updateData, area: e.target.value })}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <MDButton variant="text" color="secondary" onClick={() => setOpenEditDialog(false)}>
              Fechar
            </MDButton>
            <MDButton variant="text" color="error" type="submit" disabled={createLoading}>
              Atualizar
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </DashboardLayout>
  );
}
