/*eslint-disable*/
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import logoImg from "assets/images/logo_nuagro.png";
import React, { Fragment, useEffect, useState } from "react";

export default function BudgetReport() {
  // const [controller] = useMaterialUIController();
  // const { reportContent } = controller;

  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [category, setCategory] = useState([]);
  const [totalCustoHa, setTotalCustoHa] = useState(0);

  const [categoryProducts, setCategoryProducts] = useState([]);
  const [farm, setFarm] = useState([]);
  const [userName, setUserName] = useState();

  const [reportContent, setReportContent] = useState([]);

  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
    try {
      const reports = JSON.parse(localStorage.getItem("reports"));
      setReportContent(reports);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }, []);

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
    const [datePart, timePart] = formattedDate.split(", ");
    return `${datePart} às ${timePart}`;
  }

  useEffect(() => {
    if (reportContent) {
      const getServices = reportContent.flatMap((report) => {
        return {
          ...report.program_service.products.map((product) => {
            return {
              ...product,
              nameServices: report.program_service.name,
            };
          }),
        };
      });

      const getProduct = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {
          return {
            ...product,
            nameServices: report.program_service.name,
          };
        });
      });

      const getCategory = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {
          return {
            ...product,
            nameServices: report.program_service.name,
          };
        });
      });

      const getFarm = reportContent.flatMap((report) => {
        return report.plan.farm.name;
      });

      setFarm(removeDuplicateDates(getFarm));
      setServices(getServices);
      setCategory(getCategory);
      setProducts(removeDuplicateDates(getProduct));
    }
  }, [reportContent]);
  const categoryProductsMap = {};

  reportContent.forEach((report) => {
    const serviceName = report.program_service.name;
    const area = report.plan.area;
    report.program_service.products.forEach((product) => {
      const category = product.category;
      const productName = product.name;

      if (!categoryProductsMap[category]) {
        categoryProductsMap[category] = [];
      }

      const existingProduct = categoryProductsMap[category].find(
        (existingProduct) => existingProduct.productName === productName
      );

      if (existingProduct) {
        // Se o produto já existe, apenas adiciona o serviço a ele
        if (!existingProduct.services.includes(serviceName)) {
          existingProduct.services.push(serviceName);
        }
      } else {
        // Se o produto não existe, cria um novo
        categoryProductsMap[category].push({
          productName,
          services: [serviceName],
          perHectare: product.perHectare,
          unit: product.unit,
          unitPrice: product.unitPrice,
          area: area,
        });
      }
    });
  });

  function calcularValorTotal() {
    let total = 0;

    Object.keys(categoryProductsMap).forEach((category) => {
      const categoryTotal = categoryProductsMap[category].reduce((total, product) => {
        return (
          total +
          product.services.reduce(
            (serviceTotal, service) =>
              serviceTotal + product.perHectare * product.unitPrice * product.area,
            0
          )
        );
      }, 0);

      total += categoryTotal;
    });

    return total;
  }
  function calcularCustoTotalPorHectare() {
    let totalCustoHa = 0;

    Object.keys(categoryProductsMap).forEach((category) => {
      const categoryTotal = categoryProductsMap[category].reduce((total, product) => {
        return (
          total +
          product.services.reduce(
            (serviceTotal, service) => serviceTotal + product.unitPrice * product.perHectare,
            0
          )
        );
      }, 0);

      totalCustoHa += categoryTotal;
    });

    return totalCustoHa;
  }

  function handlePrintButtonClick() {
    window.print();
  }

  return (
    <>
      <Container
        style={{
          marginTop: 20,
          padding: 0,
          margin: 0,
        }}
      >
        <style>
          {`
              .MuiTableCell-root {
                border-bottom: 1px solid black;
                margin: 0px;
                padding: 0px;
              }
              .MuiTable-root {
                border-collapse: collapse;
                width: 100%;
                margin: 0px;
                min-width: 100%;
              }
              
            `}
        </style>
        <Button
          id="demo-customized-button"
          variant="contained"
          textColor="white"
          style={{ color: "white", marginBottom: "30px" }}
          className="no-print"
          onClick={handlePrintButtonClick}
        >
          Exportar PDF
        </Button>

        <TableContainer
          style={{
            borderRadius: 0,
            boxShadow: "none",
            width: "100%",
            padding: "20px 20px 20px 20px",
            margin: "auto",
          }}
        >
          <TableHead style={{ display: "flex", justifyContent: "space-between" }}>
            <TableHead>
              <TableRow style={{ display: "flex", justifyContent: "space-between" }}>
                <TableCell
                  style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "14px" }}
                  colSpan={7}
                >
                  {reportContent[0]?.plan?.customer?.name}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {farm.map((nome, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      padding: "0px",
                      border: "none",
                      fontWeight: "normal",
                      fontSize: "14px",
                    }}
                  >
                    {nome}
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </TableHead>

          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                style={{
                  border: "none",
                  paddingBottom: "0px",
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                align="center"
              >
                <img src={logoImg} alt="logo" style={{ width: "100px", marginTop: "20px" }} />
                Orçamento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "12px" }}
              >
                Gerado em {formatDate(new Date())}, por {userName}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px",
              padding: "10px 0px 16px 0px",
            }}
          >
            <TableHead style={{ display: "flex", flexDirection: "column", padding: "0px" }}>
              <TableRow>
                <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                  {reportContent[0]?.plan?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                  {reportContent[0]?.plan?.harvest?.name};{" "}
                  {reportContent[0]?.plan?.harvestTime?.name}; {reportContent[0]?.plan?.crop?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                  Área total: {reportContent[0]?.plan.area} há
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead style={{ display: "contents" }}>
              <TableRow>
                <TableCell
                  style={{ border: "none", padding: "0px", paddingLeft: "16px" }}
                  colSpan={12}
                >
                  Custo/ha:{" "}
                  {calcularCustoTotalPorHectare().toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </TableCell>
                <TableCell
                  style={{ border: "none", padding: "0px", paddingLeft: "16px" }}
                  colSpan={12}
                >
                  Valor total:{" "}
                  {calcularValorTotal().toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
          </TableHead>

          {Object.keys(categoryProductsMap)
            .sort((a, b) => a.localeCompare(b))
            .map((category) => {
              const categoryTotal = categoryProductsMap[category].reduce((total, product) => {
                const productTotal = product.services.reduce(
                  (serviceTotal, service) =>
                    serviceTotal + product.perHectare * product.unitPrice * product.area,
                  0
                );
                return total + productTotal;
              }, 0);
              const custoHa = categoryProductsMap[category].reduce((total, product) => {
                const productTotal = product.services.reduce(
                  (serviceTotal, service) => serviceTotal + product.perHectare * product.unitPrice,
                  0
                );
                return total + productTotal;
              }, 0);

              return (
                <Table sx={{ minWidth: 650 }} style={{ breakInside: "avoid" }}>
                  <TableHead style={{ display: "contents" }}>
                    <TableRow>
                      <TableCell colSpan={6} style={{ border: "none" }}></TableCell>
                    </TableRow>
                  </TableHead>

                  <Fragment>
                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          style={{ border: "none", paddingTop: 35 }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ display: "contents", paddingBottom: "16px" }}>
                      <TableRow>
                        <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={4}>
                          {category.toUpperCase()} (
                          {((categoryTotal / calcularValorTotal()) * 100).toFixed(1)} %)
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={2}>
                          {custoHa.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>

                        <TableCell style={{ backgroundColor: "#D8D8D8" }}>
                          {categoryTotal.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead style={{ display: "contents" }}>
                      <TableRow>
                        <TableCell colSpan={6} style={{ border: "none" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    {categoryProductsMap[category].map((product, index) => {
                      const totalCostPerHa = product.services.reduce(
                        (total, service) => total + product.unitPrice * product.perHectare,
                        0
                      );
                      const totalCostProduct = product.services.reduce(
                        (total, service) => total + product.area * product.perHectare,
                        0
                      );
                      const custoTotal = product.services.reduce(
                        (total, service) =>
                          total + product.perHectare * product.unitPrice * product.area,
                        0
                      );

                      const porcentagem = ((custoTotal / categoryTotal) * 100).toLocaleString(
                        "pt-BR",
                        {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }
                      );
                      const porcentagemFormatada = `(${porcentagem} %)`;
                      return (
                        <TableBody key={index}>
                          <TableHead style={{ display: "contents" }}>
                            <TableRow>
                              <TableCell style={{ border: "none" }}></TableCell>
                              <TableCell style={{ border: "none" }}> </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead style={{ display: "contents" }}>
                            <TableRow>
                              <TableCell colSpan={4} style={{ paddingTop: 20 }}>
                                {product.productName.toUpperCase()} {porcentagemFormatada}
                              </TableCell>
                              <TableCell style={{ paddingTop: 20 }}>
                                {totalCostPerHa.toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell style={{ paddingTop: 20 }}>
                                {totalCostProduct.toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell style={{ paddingTop: 20 }}>
                                {custoTotal.toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead style={{ display: "contents", paddingTop: "16px" }}>
                            <TableRow>
                              <TableCell>Serviço</TableCell>
                              <TableCell>Und</TableCell>
                              <TableCell>Dose/ha</TableCell>
                              <TableCell>Custo unit</TableCell>
                              <TableCell>Custo/ha</TableCell>
                              <TableCell>Total de produto</TableCell>
                              <TableCell colSpan={7}>Custo total</TableCell>
                            </TableRow>
                          </TableHead>

                          {product.services.map((service, serviceIndex) => (
                            <TableRow key={serviceIndex}>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {service}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {product.unit}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {Number(product.perHectare).toFixed(2)}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {product.unitPrice}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {(product.perHectare * product.unitPrice).toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {(product.area * product.perHectare).toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell style={{ borderBottom: "dashed 1px black" }}>
                                {(
                                  product.perHectare *
                                  product.unitPrice *
                                  product.area
                                ).toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      );
                    })}
                  </Fragment>
                </Table>
              );
            })}
        </TableContainer>
      </Container>
    </>
  );
}
