/*eslint-disable*/
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import Axios from "utils/axiosInstance";

// import StyledMenu from "./components/headTable";

const columns = [
  { accessor: "name", Header: "Nome do Produto", width: 300 },
  { accessor: "category.name", Header: "Nome da Categoria", width: 300 },
  { accessor: "unit.abbreviation", Header: "Un", width: 300 },
  { accessor: "formulation.name", Header: "Formulação", width: 300 },
  { accessor: "actions", Header: "Ações", width: 300 },
];

export default function ProductsList() {
  const [name, setName] = React.useState("");
  const [category, setCategory] = React.useState({ id: "", name: "" });
  const [unit, setUnit] = React.useState({ id: "", abbreviation: "" });
  const [formulation, setFormulation] = React.useState({ id: "", name: "" });
  const [updateData, setUpdateData] = React.useState({
    id: "",
    name: "",
    category: { id: "", name: "" },
    unit: { id: "", abbreviation: "" },
    formulation: { id: "", name: "" },
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [createLoading, setCreateLoading] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const formulations = [
    { id: 1, name: "Adjuvantes e Condicionadores de Calda" },
    { id: 2, name: "Pós Molháveis (wp)" },
    { id: 3, name: "Grânulos Dispersíveis em Água (wg)" },
    { id: 4, name: "Foliares Dry" },
    { id: 5, name: "Suspensão Concentrada (sc)" },
    { id: 6, name: "Emulsão em Água (EW)" },
    { id: 7, name: "Concentrados Emulsionáveis (EC)" },
    { id: 8, name: "Concentrações Solúveis (SL)" },
    { id: 9, name: "Solução Aquosa Concentrada (SANC)" },
    { id: 10, name: "Solução Aquosa Concentrada (SAC)" },
    { id: 11, name: "Foliares Convencionais" },
    { id: 12, name: "Óleo" },
    { id: 13, name: "Não se aplica" },
  ];
  const axios = Axios();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const updateRows = () => {
    setLoading(true);
    setRows([]);
    axios
      .get(`/v1/products?current=${page + 1}&pageSize=${rowsPerPage}&filter=name&value=${filter}`)
      .then((res) => {
        const harvestList = res.data.data.map((row) => ({
          ...row,
          name: row.name,
          category: { id: row.category.id, name: row.category.name },
          unit: { id: row.unit.id, abbreviation: row.unit.abbreviation },
          formulation: row.formulation,
          actions: (
            <>
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  setOpenEditDialog(true);
                  setUpdateData({
                    id: row.id,
                    name: row.name,
                    category: row.category,
                    unit: row.unit,
                    formulation: row.formulation,
                  });
                }}
                iconOnly
              >
                <Icon>editar</Icon>
              </MDButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MDButton
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setUpdateData({
                    id: row.id,
                    name: row.name,
                    category: row.category,
                    unit: row.unit,
                    formulation: row.formulation,
                  });
                }}
                iconOnly
              >
                <DeleteIcon />
              </MDButton>
            </>
          ),
        }));
        setRows(harvestList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  React.useEffect(() => {
    axios
      .get(`/v1/categories`)
      .then((res) => {
        const cats = res.data.data.map((cat) => ({ id: cat.id, name: cat.name }));
        setCategories(cats);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/v1/units`)
      .then((res) => {
        const uns = res.data.data.map((un) => ({ id: un.id, abbreviation: un.abbreviation }));
        setUnits(uns);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    updateRows();
  }, [page, rowsPerPage, filter]);

  const onsubmit = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .post("/v1/products", { name, category, unit, formulation })
      .then(() => {
        updateRows();
        setName("");
        setCategory({ id: "", name: "" });
        setUnit({ id: "", abbreviation: "" });
        setFormulation({ id: "", name: "" });
        setCreateLoading(false);
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  const updateName = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .put(`/v1/products/${updateData.id}`, {
        name: updateData.name,
        category: updateData.category,
        unit: updateData.unit,
        formulation: updateData.formulation,
      })
      .then(() => {
        setCreateLoading(false);
        setUpdateData({
          id: "",
          name: "",
          category: { id: "", name: "" },
          unit: { id: "", abbreviation: "" },
          formulation: { id: "", name: "" },
        });
        setOpenEditDialog(false);
        updateRows();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTrigger = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .delete(`/v1/products/${updateData.id}`)
      .then(() => {
        setCreateLoading(false);
        setOpenDeleteDialog(false);
        updateRows();
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Cadastrar novo Produto
          </h3>
        </Typography>

        <TableContainer>
          <form
            onSubmit={onsubmit}
            style={{ width: "100%", display: "inline-block", paddingLeft: "24px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MDInput
                  type="text"
                  label="Nome do Produto"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  disablePortal
                  id="categories"
                  options={categories}
                  value={category}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(e) => {
                    setCategory({
                      id: e.target.attributes.optionid.value,
                      name: e.target.textContent,
                    });
                  }}
                  required
                  renderInput={(params) => <MDInput {...params} label="Categoria" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  disablePortal
                  id="units"
                  options={units}
                  value={unit}
                  getOptionLabel={(option) => option.abbreviation}
                  openOnFocus
                  onChange={(e) => {
                    setUnit({
                      id: e.target.attributes.optionid.value,
                      abbreviation: e.target.textContent,
                    });
                  }}
                  renderInput={(params) => <MDInput {...params} label="Unidade" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.abbreviation}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  disablePortal
                  id="formulation"
                  options={formulations}
                  required
                  value={formulation}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(e, v) => {
                    if (v?.name) {
                      setFormulation({
                        id: e.target.attributes.optionid.value,
                        name: e.target.textContent,
                      });
                    } else {
                      setFormulation({ id: "", name: "" });
                    }
                  }}
                  renderInput={(params) => <MDInput {...params} label="Formulação" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.id} - {option.name}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={3} mt={2}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={createLoading}
                  type="submit"
                  fullWidth
                >
                  Salvar
                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Divider variant="fullWidth" />
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingBottom: "24px",
                display: "inline-block",
              }}
            >
              Lista de Produtos
            </h3>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              placeholder="Pesquisar"
              onChange={handleFilterChange}
              style={{
                marginRight: "24px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Typography>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer deletar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDeleteDialog(false)}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            onClick={deleteTrigger}
            disabled={createLoading}
            autoFocus
          >
            Deletar
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        disabled={createLoading}
        scroll="body"
      >
        <form onSubmit={updateName}>
          <DialogTitle>Editar Produto</DialogTitle>
          <DialogContent>
            <MDInput
              type="text"
              label="Nome do Produto"
              value={updateData.name}
              onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
              required
              fullWidth
            />
            <Autocomplete
              disablePortal
              id="categories"
              options={categories}
              value={updateData.category}
              getOptionLabel={(option) => option.name}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  category: {
                    id: e.target.attributes.optionid.value,
                    name: e.target.textContent,
                  },
                });
              }}
              renderInput={(params) => <MDInput {...params} label="Categoria" required />}
              renderOption={(props, option) => (
                <li {...props} optionId={option.id}>
                  {option.name}
                </li>
              )}
            />
            <Autocomplete
              disablePortal
              id="units"
              options={units}
              value={updateData.unit}
              getOptionLabel={(option) => option.abbreviation}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  unit: {
                    id: e.target.attributes.optionid.value,
                    abbreviation: e.target.textContent,
                  },
                });
              }}
              renderInput={(params) => <MDInput {...params} label="Unidade" required />}
              renderOption={(props, option) => (
                <li {...props} optionId={option.id}>
                  {option.abbreviation}
                </li>
              )}
            />
            <Autocomplete
              disablePortal
              id="formulation"
              options={formulations}
              value={updateData.formulation}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => {
                if (v?.name) {
                  setUpdateData({
                    ...updateData,
                    formulation: {
                      id: e.target.attributes.optionid.value,
                      name: e.target.textContent,
                    },
                  });
                } else {
                  setUpdateData({
                    ...updateData,
                    formulation: {
                      id: "",
                      name: "",
                    },
                  });
                }
              }}
              renderInput={(params) => <MDInput {...params} label="Formulação" />}
              renderOption={(props, option) => (
                <li {...props} optionId={option.id}>
                  {option.id} - {option.name}
                </li>
              )}
            />
          </DialogContent>
          <DialogActions>
            <MDButton variant="text" color="secondary" onClick={() => setOpenEditDialog(false)}>
              Fechar
            </MDButton>
            <MDButton type="submit" variant="text" color="error" disabled={createLoading}>
              Atualizar
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </DashboardLayout>
  );
}
