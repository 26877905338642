import CircularProgress from "@mui/material/CircularProgress";
import { createContext, useContext } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthGuard({ children }) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    if (user.role !== "admin") {
      window.location.href = `/agenda/${user.customer.id}`;

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
  }

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}
