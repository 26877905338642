import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import Axios from "utils/axiosInstance";

// import StyledMenu from "./components/headTable";

const columns = [
  { accessor: "name", Header: "Nome da Categoria", width: 300 },
  { accessor: "actions", Header: "Ações", width: 300 },
];

export default function CategoriesList() {
  const [name, setName] = React.useState("");
  const [updateData, setUpdateData] = React.useState({ id: "", name: "", abbreviation: "" });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [createLoading, setCreateLoading] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const axios = Axios();

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const updateRows = () => {
    setLoading(true);
    setRows([]);
    axios
      .get(`/v1/categories?current=${page + 1}&pageSize=${rowsPerPage}&filter=name&value=${filter}`)
      .then((res) => {
        const harvestList = res.data.data.map((row) => ({
          ...row,
          name: row.name,
          actions: (
            <>
              <MDButton
                variant="contained"
                color="info"
                onClick={() => {
                  setOpenEditDialog(true);
                  setUpdateData({ id: row.id, name: row.name });
                }}
                iconOnly
              >
                <Icon>editar</Icon>
              </MDButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MDButton
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenDeleteDialog(true);
                  setUpdateData({ id: row.id, name: row.name });
                }}
                iconOnly
              >
                <DeleteIcon />
              </MDButton>
            </>
          ),
        }));
        setRows(harvestList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  React.useEffect(() => {
    updateRows();
  }, [page, rowsPerPage, filter]);

  const onsubmit = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .post("/v1/categories", { name })
      .then(() => {
        setName("");
        updateRows();
        setCreateLoading(false);
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  const updateName = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .put(`/v1/categories/${updateData.id}`, {
        name: updateData.name,
      })
      .then(() => {
        setCreateLoading(false);
        setUpdateData({ id: "", name: "" });
        setOpenEditDialog(false);
        updateRows();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTrigger = (e) => {
    e.preventDefault();
    setCreateLoading(true);
    axios
      .delete(`/v1/categories/${updateData.id}`)
      .then(() => {
        setCreateLoading(false);
        setOpenDeleteDialog(false);
        updateRows();
      })
      .catch((err) => {
        setCreateLoading(false);
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Cadastrar nova Categoria
          </h3>
        </Typography>

        <TableContainer>
          <form
            onSubmit={onsubmit}
            style={{ width: "100%", display: "inline-block", paddingLeft: "24px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <MDInput
                  type="text"
                  label="Nome da Categoria"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} mt={2}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={createLoading}
                  type="submit"
                  fullWidth
                >
                  Salvar
                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Divider variant="fullWidth" />
          <Typography
            variant="h3"
            component="div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingBottom: "24px",
                display: "inline-block",
              }}
            >
              Lista de Categorias
            </h3>

            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              placeholder="Pesquisar"
              onChange={handleFilterChange}
              style={{
                marginRight: "24px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Typography>

          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer deletar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDeleteDialog(false)}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            onClick={deleteTrigger}
            disabled={createLoading}
            autoFocus
          >
            Deletar
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        disabled={createLoading}
      >
        <DialogTitle>Editar Categoria</DialogTitle>
        <form onSubmit={updateName}>
          <DialogContent>
            <MDInput
              type="text"
              label="Nome da Categoria"
              value={updateData.name}
              onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <MDButton variant="text" color="secondary" onClick={() => setOpenEditDialog(false)}>
              Fechar
            </MDButton>
            <MDButton variant="text" color="error" type="submit" disabled={createLoading}>
              Atualizar
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </DashboardLayout>
  );
}
