/*eslint-disable*/

import * as React from "react";
// import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import InputMask from "react-input-mask";

import FormControl from "@mui/material/FormControl";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";

const roles = [
  {
    id: 1,
    name: "Proprietário",
  },
  {
    id: 2,
    name: "Gerente",
  },
];

const roleName = {
  owner: "Proprietário",
  manager: "Gerente",
};

export default function CreateCollaborator() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [customer, setCustomer] = React.useState({ id: "", name: "" });
  const [role, setRole] = React.useState({ id: "", name: "" });
  const [farm, setFarm] = React.useState([]);
  // const [password, setPassword] = React.useState("");
  // const [confirmPassword, setConfirmPassword] = React.useState("");

  const [customers, setCustomers] = React.useState([]);
  const [farms, setFarms] = React.useState([]);
  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const axios = Axios();

  React.useEffect(() => {
    axios.get(`/v1/customer`).then((res) => setCustomers(res.data.data));

    if (id) {
      setEditMode(true);
      axios
        .get(`/v1/collaborator/${id}`)
        .then((res) => {
          setName(res.data.name);
          setEmail(res.data.email);
          setCellphone(res.data.cellphone);
          setCustomer(res.data.customer);
          setFarm(res.data.farms);
          setRole(res.data.role ? { id: 0, name: roleName[res.data.role] } : { id: "", name: "" });
          axios.get(`/v1/farm/${res.data.customer.id}/customer`).then((res) => setFarms(res.data));
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const submitCreateCustomer = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!editMode) {
      const payload = {
        name,
        email,
        cellphone: cellphone.replace(/\D/g, ""),
        customer: {
          id: Number(customer.id),
          name: customer.name,
        },
        farms: farm.map((item) => item.id),
        role: role.name === "Proprietário" ? "owner" : "manager",
      };

      axios
        .post("/v1/collaborator", payload)
        .then(() => {
          navigate("/funcionarios");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      const payload = {
        id: Number(id),
        name,
        email,
        cellphone: cellphone.replace(/\D/g, ""),
        customer: customer,
        farms: farm.map((item) => item.id),
        role: role.name === "Proprietário" ? "owner" : "manager",
      };
      axios
        .put(`/v1/collaborator/${id}`, payload)
        .then(() => {
          navigate("/funcionarios");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const inativeCustomer = () => {
    axios
      .delete(`/v1/collaborator/${id}`)
      .then(() => {
        navigate("/funcionarios");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const changeCustomer = (e) => {
    const data = {
      id: e.target.attributes.optionid.value,
      name: e.target.textContent,
    };
    setCustomer(data);
    setFarm([]);
    axios.get(`/v1/farm/${data.id}/customer`).then((res) => setFarms(res.data));
  };

  const handleSetFarmCollaborator = (event, newValue) => {
    setFarm(newValue);
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }} style={{ padding: "24px" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Novo funcionario
          </h3>
        </Typography>
        {editMode ? (
          <Grid pb={3} container spacing={2}>
            <Grid item xs={11} />
            <Grid item xs={1}>
              <MDButton
                variant="text"
                color="error"
                disabled={loading}
                type="submit"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={() => setOpenDialog(true)}
              >
                inativar
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        <form onSubmit={submitCreateCustomer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <MDInput
                type="text"
                label="Nome do Funcionário"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} sm={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="role"
                  options={roles}
                  value={role}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, newValue) => setRole(newValue)}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Cargo" required />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={4}>
              <MDInput
                type="email"
                label="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4} sm={4}>
              <InputMask
                mask={
                  cellphone.replace(/\D/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"
                }
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                maskChar={null}
                disabled={false}
                required
              >
                {() => <MDInput type="text" label="Telefone" fullWidth />}
              </InputMask>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="customer"
                  options={customers}
                  value={customer}
                  getOptionLabel={(option) => option?.name}
                  onChange={changeCustomer}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => <MDInput {...params} label="Cliente" required />}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={3}>
              <MDInput
                type="password"
                label="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={!id}
                disabled={editMode}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MDInput
                type="password"
                label="Confirmar Senha"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required={!id}
                disabled={editMode}
                fullWidth
              />
            </Grid> */}
            <Grid item xs={10} />
            <Grid item xs={2}>
              <MDButton variant="gradient" color="dark" disabled={loading} type="submit" fullWidth>
                Salvar
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer inativar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDialog(false)}>
            fechar
          </MDButton>
          <MDButton variant="text" color="error" onClick={inativeCustomer} autoFocus>
            Inativar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
