/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in
 all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context

function PlanningLayout({ background, children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    sessionStorage.setItem("layout", "planning");
  }, [pathname]);

  return (
    <MDBox bgColor={background} p={3}>
      {children}
    </MDBox>
  );
}

// Setting default values for the props for PlanningLayout
PlanningLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PlanningLayout
PlanningLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PlanningLayout;
