/*eslint-disable*/

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";
import React from "react";
// Images
import bgImage from "assets/images/login-background.jpg";
import logo from "assets/images/logo.png";

import { useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorPassword1, setErrorPassword1] = useState(false);
  const [errorPassword2, setErrorPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState();
  const navigate = useNavigate();
  const axios = Axios(false);
  const [userToken, setUserToken] = useState();

  function VerificatedPassword() {
    if (password === repeatPassword) {
      return true;
    } else {
      return false;
    }
  }
  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
    setUserToken(JSON.parse(localStorage.getItem("user")));
  }, []);

  const submitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!VerificatedPassword()) {
      alert("As senhas não conferem");
      setLoading(false);
      return;
    }

    axios
      .post(`/v1/auth/resetPassword`, {
        email: userToken.email,
        newPassword: repeatPassword,
        password: password,
      })
      .then((res) => {
        setLoading(false);

        if (userToken?.role === "admin") {
          navigate("/administradores");
        } else if (userToken?.role === "manager" || userToken?.role === "owner") {
          navigate(`/agenda/${userToken?.customer.id}`);
        } else {
          navigate("/page404");
        }
      });
  };

  React.useEffect(() => {
    if (password.replace(/\D/g, "").length < 6 && password.replace(/\D/g, "").length > 0) {
      setErrorPassword1(true);
    } else {
      setErrorPassword1(false);
    }
  }, [password]);

  React.useEffect(() => {
    if (
      repeatPassword.replace(/\D/g, "").length < 6 &&
      repeatPassword.replace(/\D/g, "").length > 0
    ) {
      setErrorPassword2(true);
    } else {
      setErrorPassword2(false);
    }
  }, [repeatPassword]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <div style={{ width: "100%", textAlign: "center" }}>
          <MDBox component="img" src={logo} alt="Brand" width="15rem" alignContent="center" />
        </div>
        <form onSubmit={submitLogin}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <h5 style={{ marginBottom: "1em", color: "#5D5D5D", fontWeight: 300 }}>
                Bem Vindo, {userName}!
              </h5>
              <h5 style={{ marginBottom: "1em", color: "#5D5D5D", fontWeight: 300 }}>
                Insira uma nova senha para acessar a plataforma.
              </h5>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Chave de acesso"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  required
                  fullWidth
                  error={errorPassword1}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirme a chave de acesso"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  disabled={loading}
                  required
                  fullWidth
                  error={errorPassword2}
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={loading || errorPassword1 || errorPassword2}
                  type="submit"
                  fullWidth
                >
                  {loading && (
                    <CircularProgress color="white" size="1.5em" style={{ marginRight: "1em" }} />
                  )}{" "}
                  Acessar
                </MDButton>
              </MDBox>
              {/* <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Não tem uma conta?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    registrar
                  </MDTypography>
                </MDTypography>
              </MDBox> */}
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default PasswordReset;
