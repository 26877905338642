/* eslint-disable */

import * as React from "react";
// import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
// import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { FetchBrStates, FetchCities } from "utils/apiStateCity";
import Axios from "utils/axiosInstance";

export default function CreateCustomer() {
  const [document, setDocument] = React.useState("");
  const [corporateName, setCorporativeName] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [brStates, setBrStates] = React.useState([]);
  const [brCities, setBrCities] = React.useState([]);
  const [brState, setBrState] = React.useState({ stateName: "", stateUf: "" });
  const [brCity, setBrCity] = React.useState({ cityName: "", cityUf: "" });
  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errorCellphone, setErrorCellphone] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const axios = Axios();

  React.useEffect(() => {
    FetchBrStates().then((states) => {
      const sortedStates = states.sort((a, b) => a.stateName.localeCompare(b.stateName));
      setBrStates(sortedStates);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      setEditMode(true);
      axios
        .get(`/v1/customer/${id}`)
        .then((res) => {
          let mState = res.data.address?.state;
          mState = {
            stateName: mState?.stateName ?? "",
            stateUf: mState?.stateUf ?? "",
          };
          let mCity = res.data.address?.city;
          mCity = {
            cityName: mCity?.cityName ?? "",
            cityUf: mCity?.cityUf ?? "",
          };
          setDocument(res.data.document);
          setCorporativeName(res.data.corporateName);
          setName(res.data.name);
          setEmail(res.data.email);
          setCellphone(res.data.cellphone);
          setBrState(mState || { name: "", uf: "" });
          setBrCity(mCity || { name: "", uf: "" });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (cellphone.replace(/\D/g, "").length < 11 && cellphone.replace(/\D/g, "").length > 0) {
      setErrorCellphone(true);
    } else {
      setErrorCellphone(false);
    }
  }, [cellphone]);

  const submitCreateCustomer = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      document: document.replace(/\D/g, ""),
      corporateName,
      name,
      email,
      cellphone: cellphone.replace(/\D/g, ""),
      address: {
        state: brState,
        city: brCity,
      },
    };
    if (!editMode) {
      axios
        .post("/v1/customer", payload)
        .then(() => {
          navigate("/clientes");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      axios
        .put(`/v1/customer/${id}`, payload)
        .then(() => {
          navigate("/clientes");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const inativeCustomer = () => {
    axios
      .delete(`/v1/customer/${id}`)
      .then(() => {
        navigate("/clientes");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (brState && brState.stateId) {
      FetchCities(brState.stateId).then((cities) => {
        setBrCities(cities);
      });
    }
  }, [brState]);

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }} style={{ padding: "24px" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Novo cliente
          </h3>
        </Typography>
        {editMode ? (
          <Grid pb={3} container spacing={2}>
            <Grid item xs={11} />
            <Grid item xs={1}>
              <MDButton
                variant="text"
                color="error"
                disabled={loading}
                type="submit"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={() => setOpenDialog(true)}
              >
                inativar
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        <form onSubmit={submitCreateCustomer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputMask
                mask={
                  document.replace(/\D/g, "").length < 12 ? "999.999.999-999" : "99.999.999/9999-99"
                }
                value={document}
                onChange={(e) => setDocument(e.target.value)}
                maskChar={null}
                required
                disabled={editMode}
              >
                {() => <MDInput type="text" disabled={editMode} label="CNPJ/CPF" fullWidth />}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="text"
                label="Razão Social"
                value={corporateName}
                onChange={(e) => setCorporativeName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="text"
                label="Nome do Contato"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="email"
                label="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <InputMask
                mask={"(99) 99999-9999"}
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                maskChar={null}
                disabled={false}
                required
              >
                {() => <MDInput type="text" label="Telefone" fullWidth error={errorCellphone} />}
              </InputMask>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="br-state"
                  options={brStates}
                  value={brState}
                  getOptionLabel={(option) => option?.stateName}
                  openOnFocus
                  onChange={(e, value) => {
                    setBrState(value);
                    setBrCity(null); // Define a cidade como null ao alterar o estado
                  }}
                  renderInput={(params) => <MDInput {...params} label="Estado" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionid={option?.stateUf}>
                      {option?.stateName}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="br-city"
                  options={brCities || []}
                  value={brCity}
                  getOptionLabel={(option) => option?.cityName}
                  openOnFocus
                  onChange={(e) =>
                    setBrCity({
                      cityName: e.target.textContent,
                    })
                  }
                  renderInput={(params) => <MDInput {...params} label="Cidade" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.cityUf}>
                      {option.cityName}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <MDButton
                variant="gradient"
                color="dark"
                disabled={loading || errorCellphone}
                type="submit"
                fullWidth
              >
                Salvar
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer inativar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDialog(false)}>
            fechar
          </MDButton>
          <MDButton variant="text" color="error" onClick={inativeCustomer} autoFocus>
            Inativar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
