/*eslint-disable*/
import OutlinedInput from "@mui/material/OutlinedInput";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";

import PlanningLayout from "examples/LayoutContainers/PlanningLayout";

import { formatDate } from "utils/formatDate";

const columns = [
  { accessor: "id", Header: "ID", width: 100 },
  { accessor: "name", Header: "Planejamento", with: 300 },
  { accessor: "harvest.name", Header: "Safra", width: 100 },
  { accessor: "customer.name", Header: "Cliente", width: 100 },
  { accessor: "crop.name", Header: "Cultura", width: 100 },
  { accessor: "status", Header: "Etapa", width: 100 },
  { accessor: "startDate", Header: "Data da criação", width: 100 },
  { accessor: "actions", Header: "Ações", width: 100 },
];

const statusDict = {
  HEADER: "Cadastro Cabeçalho",
  SERVICES_AND_PRODUCTS: "Cadastro Serviço e Produtos",

  SET_PRICES: "Cadastro Preço",
  CHECKUP: "Orçamento",
  FINISH: "Cronograma de Serviço",
  OS: "Programa por Gleba",
  BASE: "Data Base",
};

const filterOptions = ["ID", "CNPJ", "Cidade", "Estado", "Telefone"];
const filterOptionsSafra = ["NOME"];
const filterOptionsCultura = ["NOME"];

export default function Planning() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const [filterString, setFilterString] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [harvestName, setHarvestName] = React.useState("");
  const [harvestTimeName, setHarvestTimeName] = React.useState("");

  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [row, setRow] = React.useState({});

  const [pageOptions, setPageOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const axios = Axios();

  const applyFilter = () => {
    const filters = [];

    if (customerName) {
      filters.push(`filter[0][key]=customer&filter[0][value]=${encodeURIComponent(customerName)}`);
    }

    if (harvestName) {
      filters.push(`filter[1][key]=harvest&filter[1][value]=${encodeURIComponent(harvestName)}`);
    }

    if (harvestTimeName) {
      filters.push(
        `filter[2][key]=harvestTime&filter[2][value]=${encodeURIComponent(harvestTimeName)}`
      );
    }

    const queryString = filters.join("&");
    setFilterString(queryString);
  };

  React.useEffect(() => {
    setLoading(true);
    setRows([]);
    axios
      .get(`/v1/plans?current=${page + 1}&pageSize=${rowsPerPage}&${filterString}`)
      .then((res) => {
        const planningList = res.data.data.map((row) => ({
          ...row,
          id: <Link to={`/planejamentos/${row.id}`}>PLAN-00{row.id}</Link>,
          startDate: formatDate(row.startDate),
          status: statusDict[row.status],
          actions: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
              }}
            >
              <Button
                id="demo-customized-button"
                variant="contained"
                textColor="white"
                style={{ color: "white" }}
                onClick={() => duplicatePlan(row.id)}
              >
                Duplicar
              </Button>
              <MDButton
                id="demo-customized-button"
                variant="gradient"
                color="error"
                onClick={() => {
                  setRow(row);
                  setOpenDialogDelete(true);
                }}
              >
                Inativar
              </MDButton>
            </div>
          ),
        }));
        setRows(planningList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, rowsPerPage, filterString]);

  const duplicatePlan = (id) => {
    axios
      .get(`/v1/plans/duplicate/${id}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const deletePlan = (id) => {
    axios
      .delete(`/v1/plans/${id}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  function handleClickOpenFilter() {
    setOpenFilter(!openFilter);
  }

  return (
    <PlanningLayout>
      <Paper sx={{ width: "100%", paddingTop: "1em" }}>
        <TableContainer>
          <Button
            id="demo-customized-button"
            variant="contained"
            textColor="white"
            style={{ marginRight: "24px", color: "white", float: "right" }}
            onClick={() => navigate("/planejamentos/novo")}
          >
            Cadastrar Novo
          </Button>
          <Button
            id="demo-customized-button"
            variant="contained"
            textColor="white"
            style={{ marginRight: "24px", color: "white", float: "right" }}
            onClick={() => handleClickOpenFilter()}
          >
            Filtros
          </Button>
          <div
            style={
              openFilter
                ? {
                    marginTop: "3%",
                    marginLeft: "1.3%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }
                : { display: "none" }
            }
          >
            <div>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                placeholder="Nome do cliente"
                onChange={(e) => setCustomerName(e.target.value)}
                style={{
                  marginRight: "24px",
                }}
              />
            </div>

            <div>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                placeholder="Nome da safra"
                onChange={(e) => setHarvestName(e.target.value)}
                style={{
                  marginRight: "24px",
                }}
              />
            </div>
            <div>
              <OutlinedInput
                id="outlined-adornment-search"
                type="text"
                placeholder="Nome da época"
                onChange={(e) => setHarvestTimeName(e.target.value)}
                style={{
                  marginRight: "24px",
                }}
              />
            </div>

            <div>
              <Button
                id="demo-customized-button"
                variant="contained"
                textColor="white"
                style={{ marginRight: "24px", color: "white", float: "right" }}
                onClick={() => applyFilter()}
              >
                Aplicar Filtros
              </Button>
            </div>
          </div>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
      <Dialog open={openDialogDelete} onClose={() => setOpenDialogDelete(false)}>
        <DialogContent>
          Certeza que deseja invativar? Essa ação não poderá ser desfeita.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogDelete(false)}>Cancelar</Button>
          <MDButton
            id="demo-customized-button"
            variant="gradient"
            color="error"
            onClick={() => deletePlan(row.id)}
          >
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>
    </PlanningLayout>
  );
}
