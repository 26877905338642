/*eslint-disable*/
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MDButton from "components/MDButton";
import { setNotification, useMaterialUIController } from "context";
import { addDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";
export default function Step7({ nextStep, planId }) {
  const [controller, dispatch] = useMaterialUIController();
  const { id } = useParams();
  const axios = Axios();
  const navigate = useNavigate();
  const idOrd = id || planId;
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [updatedServices, setUpdatedServices] = useState([]);
  const [baseServiceId, setBaseServiceId] = useState(null);
  const [programList, setProgramList] = useState([]);

  const [expandedGlebaService, setExpandedGlebaService] = useState({
    glebaIndex: null,
    serviceIndex: null,
  });
  const [hasProgram, setHasProgram] = useState(false);
  const [servicesWithoutDates, setProgramWhitoutDates] = useState([]);
  const [openWithoutDate, setOpenWithoutDateDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState({
    modal: false,
    glebaIndex: null,
    serviceIndex: null,
    newData: null,
    diff: null,
  });

  useEffect(() => {
    axios.get(`/v1/program/${idOrd}/plan`).then((res) => {
      const data = res.data;
      if (data && data) {
        const updatedProgramServices = data.map((program) => ({ ...program }));

        setUpdatedServices(updatedProgramServices);

        const services = updatedProgramServices.map((program) => program.services).flat();

        const programServicesWithoutDates = updatedProgramServices
          .flatMap((program) =>
            program.services.map((service) => {
              if (
                service.newDateInitial === undefined ||
                service.newDateInitial === null ||
                service.newDateFinish === undefined ||
                service.newDateFinish === null
              ) {
                return {
                  program,
                  service,
                };
              }

              return;
            })
          )
          .filter((entry) => entry !== undefined);

        setProgramWhitoutDates(programServicesWithoutDates);

        const defaultService = services.find(
          (service) => service && service.defaultService === true
        );
        if (defaultService) {
          const baseServiceId = defaultService.idUnique;
          setBaseServiceId(baseServiceId);
        }
      }

      if (data.length > 0) {
        setHasProgram(true);
      }
    });
    axios.get(`/v1/order/${idOrd}/plan`).then((res) => {
      const data = res.data;
      if (data && data) {
        const updatedProgramServices = data.map((program) => ({ ...program }));

        setProgramList(updatedProgramServices);
      }
    });
  }, [idOrd]);

  const handleRowClick = (glebaIndex, serviceIndex) => {
    if (
      expandedGlebaService.glebaIndex === glebaIndex &&
      expandedGlebaService.serviceIndex === serviceIndex
    ) {
      setExpandedGlebaService({ glebaIndex: null, serviceIndex: null });
    } else {
      setExpandedGlebaService({ glebaIndex: glebaIndex, serviceIndex: serviceIndex });
    }

    if (expandedRows.includes(serviceIndex)) {
      setExpandedRows((prevExpandedRows) =>
        prevExpandedRows.filter((rowIndex) => rowIndex !== serviceIndex)
      );
    } else {
      setExpandedRows((prevExpandedRows) => [...prevExpandedRows, serviceIndex]);
    }
  };

const submitPlan = () => {
  const dataUpdateProgram = updatedServices.map((program) => ({
    ...program,
    services: program.services.map((service) => {
      const sortedProducts = service.products.sort((a, b) => {
        const numberA = parseInt(a.formulation.split(' ')[0], 10);
        const numberB = parseInt(b.formulation.split(' ')[0], 10);
        return numberA - numberB;
      });

      return {
        ...service,
        products: sortedProducts,
        plan: program.plan.id,
        soil: program.soil.id,
        status: service.status ?? "PENDING",
      };
    }),
  }));

  axios.patch(`/v1/program/${idOrd}/plan`, dataUpdateProgram).then((res) => {
    setNotification(dispatch, {
      open: true,
      title: "Programa de Manejo atualizado com sucesso!",
      severity: "success",
      icon: "check_circle_outline",
      color: "success",
    });
    navigate("/agenda");
  });
};


  const handleDrop = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceGlebaId = parseInt(source.droppableId.split("-")[1]);
    const destinationGlebaId = parseInt(destination.droppableId.split("-")[1]);

    if (sourceGlebaId !== destinationGlebaId) return;

    const reorderedServices = [...updatedServices];

    const movedService = reorderedServices.filter((program) => program.id === sourceGlebaId)[0]
      .services[source.index];

    reorderedServices
      .filter((program) => program.id === sourceGlebaId)[0]
      .services.splice(source.index, 1);

    reorderedServices
      .filter((program) => program.id === destinationGlebaId)[0]
      .services.splice(destination.index, 0, movedService);

    setUpdatedServices(reorderedServices);
  };

  const recalculateServicesDates = (glebaIndex, serviceIndex, newData, diff) => {
    setUpdatedServices((prevState) => {
      const updatedPrograms = [...prevState];
      const glebaProgram = updatedPrograms[glebaIndex];

      updatedPrograms[glebaIndex] = {
        ...glebaProgram,
        services: glebaProgram.services.map((service, index) => {
          if (index < serviceIndex) {
            return service;
          } else if (index === serviceIndex) {
            return {
              ...service,
              newDateInitial: newData,
              newDateFinish: moment(newData).add(service.dateFinish, "days"),
            };
          } else {
            let newDateInitial = null;
            let newDateFinish = null;

            if (diff < 0) {
              const days = diff * -1;

              newDateInitial = moment(service.newDateInitial).subtract(days, "days");

              newDateFinish = moment(service.newDateFinish).subtract(days, "days");

              const newDateIndex = moment(service.newDateIndex).subtract(days, "days");
              return {
                ...service,
                newDateInitial,
                newDateFinish,
                start: newDateInitial,
                end: newDateFinish,
                dateFinish: diff,
                newDateIndex,
                dateServiceBase: newData,
              };
            }

            newDateInitial = moment(service.newDateInitial).add(diff, "days");

            newDateFinish = moment(service.newDateFinish).add(diff, "days");

            const newDateIndex = moment(service.newDateIndex).add(diff, "days");

            return {
              ...service,
              newDateInitial,
              newDateFinish,
              start: newDateInitial,
              end: newDateFinish,
              dateFinish: diff,
              newDateIndex,
              dateServiceBase: newData,
            };
          }
        }),
      };
      return updatedPrograms;
    });

    setOpenConfirm({
      modal: false,
      glebaIndex: null,
      serviceIndex: null,
      diff: null,
    });
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <div>
        <TableContainer style={{ marginBottom: "1em" }}>
          <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead style={{ display: "contents" }}>
              <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
                <TableCell style={{ color: "inherit" }} colSpan={5} align="center">
                  {updatedServices[0]?.plan?.name || ""}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell width="25%">ID: PRG-000{updatedServices[0]?.plan?.id || ""}</TableCell>
                <TableCell width="25%">
                  Cliente: {updatedServices[0]?.plan?.customer?.name || ""}{" "}
                </TableCell>
                <TableCell width="25%">
                  Fazenda: {updatedServices[0]?.plan?.farm?.name || ""}{" "}
                </TableCell>
                <TableCell width="25%">
                  Cultura: {updatedServices[0]?.plan?.crop?.name || ""}{" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h5
          style={{
            marginBottom: "1em",
            color: "#5D5D5D",
            fontWeight: 300,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Selecione uma data para o serviço base!
          <MDButton variant="gradient" color="dark" onClick={submitPlan}>
            Salvar programa de manejo
          </MDButton>
        </h5>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#edbd39",
            gap: "10px",
          }}
        >
          {servicesWithoutDates.length > 0 && (
            <>
              <h4>Há {servicesWithoutDates.length} serviços sem datas previstas!</h4>{" "}
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setOpenWithoutDateDialog(true)}
              >
                Clique aqui para ver
              </span>
            </>
          )}
        </div>

        {updatedServices.map((program, glebaIndex) => (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1rem",
                marginTop: "3rem",
                textTransform: "uppercase",
              }}
            >
              <h3
                style={{
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (expandedRows.includes(`${glebaIndex}`)) {
                    setExpandedRows((prevExpandedRows) =>
                      prevExpandedRows.filter((rowIndex) => rowIndex !== `${glebaIndex}`)
                    );
                  } else {
                    setExpandedRows((prevExpandedRows) => [...prevExpandedRows, `${glebaIndex}`]);
                  }
                }}
              >
                Gleba: {program.soil ? program.soil.name : "SEM GLEBA"}{" "}
                {expandedRows.includes(`${glebaIndex}`) ? (
                  <KeyboardArrowUpIcon fontSize="medium" strokeWidth={2} />
                ) : (
                  <KeyboardArrowDownIcon fontSize="medium" strokeWidth={2} />
                )}
              </h3>

              <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Data Serviço Base"
                  inputFormat="dd/MM/yyyy"
                  value={program.services.flatMap((service) => service.dateServiceBase)[0] || ""}
                  disabled={
                    program.services.flatMap((service) => service.status)[0] === "done" ||
                    program.services.flatMap((service) => service.status)[0] === "done_delayed"
                  }
                  onChange={(newValue) => {
                    setUpdatedServices((prevState) => {
                      const updatedPrograms = [...prevState];
                      const glebaProgram = updatedPrograms[glebaIndex];

                      // Encontre o índice do serviço base com base na chave 'defaultService'
                      const baseServiceIndex = glebaProgram.services.findIndex(
                        (service) => service.defaultService === true
                      );

                      updatedPrograms[glebaIndex] = {
                        ...glebaProgram,
                        dateServiceBase: newValue,
                        services: glebaProgram.services.map((service, index) => {
                          if (index < baseServiceIndex) {
                            if (service.indexSum < 0) {
                              const indexSum = service.indexSum * -1;

                              return {
                                ...service,
                                newDateInitial: moment(newValue, "DD/MM/YYYY").subtract(
                                  indexSum,
                                  "days"
                                ),

                                newDateFinish: moment(newValue, "DD/MM/YYYY")
                                  .subtract(indexSum, "days")
                                  .add(service.dateFinish, "days"),
                                newDateIndex: service.newDateIndex,
                                dateServiceBase: newValue,
                              };
                            }

                            return {
                              ...service,
                              newDateInitial: moment(newValue)
                                .add(service.indexSum, "days")
                                .format("YYYY-MM-DD"),
                              newDateFinish: moment(newValue)
                                .add(service.indexSum, "days")
                                .add(service.dateFinish, "days")
                                .format("YYYY-MM-DD"),
                              newDateIndex: service.newDateIndex,
                              dateServiceBase: newValue,
                            };
                          } else {
                            // Caso contrário, atualize as datas com base na nova data do serviço base
                            const newDateInitial = newValue
                              ? addDays(newValue, service.indexSum)
                              : null;
                            const newDateFinish = newValue
                              ? addDays(
                                  newValue,
                                  parseInt(service.indexSum) + parseInt(service.dateFinish)
                                )
                              : null;
                            const newDateIndex = newValue
                              ? addDays(newValue, service.indexSum)
                              : null;
                            return {
                              ...service,
                              newDateInitial,
                              newDateFinish,
                              newDateIndex,
                              dateServiceBase: newValue,
                            };
                          }
                        }),
                      };
                      return updatedPrograms;
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      style={{ width: "20%", marginLeft: "2rem" }}
                      required
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <Collapse in={expandedRows.includes(`${glebaIndex}`)} timeout="auto" unmountOnExit>
              <Droppable droppableId={`progam-${program.id}-${glebaIndex}`} direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {program.services.map((service, index) => (
                      <Draggable
                        key={index}
                        draggableId={`service-${glebaIndex}-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <>
                              <TableContainer
                                style={{
                                  marginBottom: "1rem",
                                }}
                              >
                                <Table
                                  sx={{
                                    minWidth: 650,
                                  }}
                                  aria-label="caption table"
                                >
                                  <TableHead style={{ display: "contents" }}>
                                    <TableRow
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          color: "inherit",
                                          textTransform: "uppercase",
                                        }}
                                        colSpan={7}
                                        align="center"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onClick={() => handleRowClick(glebaIndex, index)}
                                        >
                                          <span style={{ marginRight: "8px" }}>
                                            {service.name}{" "}
                                          </span>
                                          {expandedRows.includes(index) ? (
                                            <KeyboardArrowUpIcon
                                              fontSize="medium"
                                              strokeWidth={2}
                                            />
                                          ) : (
                                            <KeyboardArrowDownIcon
                                              fontSize="medium"
                                              strokeWidth={2}
                                            />
                                          )}
                                          <span style={{ marginLeft: "8px", color: "red" }}>
                                            {service.defaultService === true ? "serviço base" : ""}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "self-end",
                                            gap: "2rem",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <LocalizationProvider
                                            locale={ptBR}
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DatePicker
                                              label="Data Inicial Prevista"
                                              inputFormat="dd/MM/yyyy"
                                              value={service.newDateInitial}
                                              onClick={(e) => e.stopPropagation(glebaIndex, index)}
                                              disabled={
                                                service?.status === "done" ||
                                                service?.status === "done_delayed"
                                              }
                                              onChange={(newValue) => {
                                                let diff;
                                                let diffDateFinish;

                                                setUpdatedServices((prevState) => {
                                                  const updatedPrograms = [...prevState];
                                                  updatedPrograms[glebaIndex] = {
                                                    ...updatedPrograms[glebaIndex],
                                                    services: updatedPrograms[
                                                      glebaIndex
                                                    ].services.map((service, i) => {
                                                      if (i === index) {
                                                        diff = moment(newValue).diff(
                                                          service.newDateInitial,
                                                          "days"
                                                        );

                                                        if (
                                                          moment(newValue).diff(
                                                            service.newDateFinish,
                                                            "days"
                                                          ) < 0
                                                        ) {
                                                          diffDateFinish = 0;
                                                        } else {
                                                          diffDateFinish = moment(newValue).diff(
                                                            service.newDateFinish,
                                                            "days"
                                                          );
                                                        }

                                                        return {
                                                          ...service,
                                                          newDateInitial: newValue,
                                                          newDateFinish: moment(newValue).add(
                                                            service.dateFinish,
                                                            "days"
                                                          ),
                                                          start: newValue,
                                                          end: moment(newValue).add(
                                                            service.dateFinish,
                                                            "days"
                                                          ),
                                                        };
                                                      }
                                                      return service;
                                                    }),
                                                  };
                                                  return updatedPrograms;
                                                });

                                                const serviceBaseIndice = updatedServices[
                                                  glebaIndex
                                                ].services.findIndex(
                                                  (service) => service.defaultService === true
                                                );

                                                if (
                                                  moment(newValue).year() > 2000 &&
                                                  index >= serviceBaseIndice
                                                ) {
                                                  setOpenConfirm({
                                                    modal: true,
                                                    glebaIndex: glebaIndex,
                                                    serviceIndex: index,
                                                    newData: newValue,
                                                    diff,
                                                  });
                                                }
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "dd/mm/aaaa",
                                                  }}
                                                  style={{ width: "55%", marginLeft: "2rem" }}
                                                  required
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>
                                          <LocalizationProvider
                                            locale={ptBR}
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DatePicker
                                              label="Data Final Prevista"
                                              inputFormat="dd/MM/yyyy"
                                              onClick={(e) => e.stopPropagation(glebaIndex, index)}
                                              value={service.newDateFinish}
                                              disabled={
                                                service?.status === "done" ||
                                                service?.status === "done_delayed"
                                              }
                                              onChange={(newValue) => {
                                                setUpdatedServices((prevState) => {
                                                  if (
                                                    moment(newValue).isBefore(
                                                      service.newDateInitial
                                                    )
                                                  ) {
                                                    setNotification(dispatch, {
                                                      open: true,
                                                      title:
                                                        "A data final prevista não pode ser menor que a data inicial prevista!",
                                                      severity: "error",
                                                      icon: "error_outline",
                                                      color: "error",
                                                    });
                                                    return prevState;
                                                  }

                                                  let diffDateFinish;

                                                  const updatedPrograms = [...prevState];
                                                  updatedPrograms[glebaIndex] = {
                                                    ...updatedPrograms[glebaIndex],
                                                    services: updatedPrograms[
                                                      glebaIndex
                                                    ].services.map((service, i) => {
                                                      if (i === index) {
                                                        if (
                                                          moment(newValue).diff(
                                                            service.newDateInitial,
                                                            "days"
                                                          ) > 0
                                                        ) {
                                                          diffDateFinish = moment(newValue).diff(
                                                            service.newDateInitial,
                                                            "days"
                                                          );
                                                        } else {
                                                          diffDateFinish = 0;
                                                        }

                                                        return {
                                                          ...service,
                                                          // Update the "Data Inicial Prevista" as well
                                                          newDateFinish: newValue,
                                                          dateFinish: diffDateFinish,
                                                        };
                                                      }
                                                      return service;
                                                    }),
                                                  };
                                                  return updatedPrograms;
                                                });
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "dd/mm/aaaa",
                                                  }}
                                                  style={{ width: "50%" }}
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>

                                          {/* Botão para limpar os inputs de data prevista inicial e data prevista final  */}
                                          <MDButton
                                            variant="gradient"
                                            color="dark"
                                            disabled={
                                              service?.status === "done" ||
                                              service?.status === "done_delayed"
                                            }
                                            onClick={() => {
                                              setUpdatedServices((prevState) => {
                                                const updatedPrograms = [...prevState];
                                                updatedPrograms[glebaIndex] = {
                                                  ...updatedPrograms[glebaIndex],
                                                  services: updatedPrograms[
                                                    glebaIndex
                                                  ].services.map((service, i) => {
                                                    if (i === index) {
                                                      return {
                                                        ...service,
                                                        newDateInitial: null,
                                                        newDateFinish: null,
                                                      };
                                                    }
                                                    return service;
                                                  }),
                                                };
                                                return updatedPrograms;
                                              });
                                            }}
                                          >
                                            Limpar
                                          </MDButton>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key={index}>
                                      <TableCell style={{ padding: "0px" }}>
                                        <Collapse
                                          in={
                                            expandedGlebaService.glebaIndex === glebaIndex &&
                                            expandedGlebaService.serviceIndex === index
                                          }
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Table>
                                            <TableBody>
                                              <TableRow
                                                style={{
                                                  backgroundColor: "#40404099",
                                                  color: "white",
                                                }}
                                              >
                                                <TableCell style={{ color: "inherit" }}>
                                                  Produto
                                                </TableCell>
                                                <TableCell style={{ color: "inherit" }}>
                                                  Categoria
                                                </TableCell>
                                                <TableCell style={{ color: "inherit" }}>
                                                  Unidade
                                                </TableCell>
                                                <TableCell style={{ color: "inherit" }}>
                                                  Área
                                                </TableCell>
                                                <TableCell style={{ color: "inherit" }}>
                                                  Dose/ha
                                                </TableCell>
                                              </TableRow>
                                              {service.products.map((product, index) => (
                                                <TableRow key={index}>
                                                  <TableCell>{product?.name}</TableCell>
                                                  <TableCell>{product?.category}</TableCell>
                                                  <TableCell>{product?.unit}</TableCell>
                                                  <TableCell>
                                                    {program?.soil ? program.soil?.area : ""}
                                                  </TableCell>
                                                  <TableCell>{product?.perHectare}</TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Collapse>
          </>
        ))}
      </div>

      <Dialog
        open={openConfirm.modal}
        onClose={() =>
          setOpenConfirm({
            modal: false,
            glebaIndex: null,
            serviceIndex: null,
            newData: null,
            diff: null,
          })
        }
      >
        <DialogTitle>Deseja recalcular as datas depois desse serviço?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() =>
              setOpenConfirm({
                modal: false,
                glebaIndex: null,
                serviceIndex: null,
                newData: null,
                diff: null,
              })
            }
          >
            Não
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() =>
              recalculateServicesDates(
                openConfirm.glebaIndex,
                openConfirm.serviceIndex,
                openConfirm.newData,
                openConfirm.diff
              )
            }
          >
            Sim
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openWithoutDate} onClose={() => setOpenWithoutDateDialog(false)}>
        <DialogTitle>Existem serviços sem datas previstas!</DialogTitle>
        <DialogContent>
          {servicesWithoutDates.map((swt) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "5px 0",
                borderBottom: "1px solid #cecece",
              }}
            >
              <h4
                style={{
                  color: "#888888",
                }}
              >
                {swt.program.soil.name || ""} - {swt.service.name || ""}
              </h4>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={() => setOpenWithoutDateDialog(false)}>
            Fechar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DragDropContext>
  );
}
