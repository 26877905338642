/*eslint-disable*/
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

function formatDate(date) {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
  const [datePart, timePart] = formattedDate.split(", ");
  return `${datePart} às ${timePart}`;
}

export async function quoteGenerateXlxs(
  reportContent,
  farm,
  services,
  sproducts,
  suppliers,
  categories,
  userName
) {
  const headerRow2Style = {
    font: {
      size: 12,
      bold: true,
    },
  };

  const tableData = [
    ["", "", "", "", "", ""],
    [`${reportContent[0]?.plan?.customer?.name}`, ``, "", "", "", "", `${farm}`],
    ["", "", "", "", "", "", ""],
    ["Relação de insumos para cotação", ""],
    [`gerado em ${formatDate(new Date())} por ${userName}`],
    ["", "", "", "", "", "", ""],
    [
      `${reportContent[0]?.plan?.harvest?.name} ;${reportContent[0]?.plan?.harvestTime?.name} ;${reportContent[0]?.plan?.crop?.name}`,
      "",
      "",
      "",
      "",
      "",
      `Área total: ${reportContent[0]?.plan?.area} há`,
    ],
    ["", "", "", "", "", "", ""],
  ];

  const headerRow1 = [
    "",
    "",
    "",
    "Cotação unitária",
    "",
    "",
    "",
    "Cotação unitária",
    "",
    "",
    "",
    "Cotação unitária",
    "",
    "",
  ];

  const headerRow2 = [
    "Produto",
    "unid.",
    "demanda de produto",
    "à vista",
    "à prazo",
    "Observação",
    "Substituto 1 (nome)",
    "à vista",
    "à prazo",
    "Observação",
    "Substituto 2 (nome)",
    "à vista",
    "à prazo",
    "Observação",
  ];

  if (categories && categories.length > 0) {
    categories.sort((a, b) => a.localeCompare(b));
    categories.forEach((category) => {
      tableData.push([""]);
      tableData.push([category]);
      tableData.push([""]);
      tableData.push(headerRow1);
      tableData.push(headerRow2);

      // Apply bold style to headerRow2

      const categoryProducts = sproducts.filter((product) => product.category === category);
      categoryProducts.sort((a, b) => a.name.localeCompare(b.name));
      categoryProducts.forEach((product, index) => {
        tableData.push([
          product.name,
          product.unit,
          parseFloat(product.perHectare) * parseFloat(reportContent[0]?.plan?.area),
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
          "", // Placeholder for other columns
        ]);
      });

      tableData.push([]); // Empty row after each category's products
    });
  }
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Dados");

  const maxWidth = tableData.reduce((max, row) => Math.max(max, row.length), 0);

  worksheet.columns = Array.from({ length: maxWidth }, () => ({ width: 25 }));
  worksheet.views = [
    {
      showGridLines: false,
    },
  ];

  tableData.forEach((row) => {
    worksheet.addRow(row);
  });

  worksheet.mergeCells("G2:H2");
  worksheet.mergeCells("A4:N4");
  worksheet.mergeCells("A5:N5");

  worksheet.getCell("A4").font = { size: 12, bold: true };
  worksheet.getCell("A4").border = {
    top: { style: "thin" },
  };
  worksheet.getCell("A5").border = {
    bottom: { style: "thin" },
  };

  const totalHeaderRow = tableData.findIndex((row) => row.includes("demanda de produto"));

  let rowIndex = totalHeaderRow + 2;
  let rowTitleIndex = totalHeaderRow;
  let cellCategoryIndex = totalHeaderRow;

  categories.forEach((category) => {
    const categoryProducts = sproducts.filter((product) => product.category === category);

    categoryProducts.forEach((product, index) => {
      const row = rowIndex + index;
      const rowTitle = rowTitleIndex;
      const cellCategory = cellCategoryIndex - 2;

      worksheet.getCell(`A${cellCategory}`).font = {
        size: 12,
        bold: true,
      };

      worksheet.getCell(`A${cellCategory}`).border = {
        bottom: { style: "thin" },
      };

      worksheet.getCell(`A${row}`).font = {
        size: 12,
        color: { argb: "FF0000" },
      };

      worksheet.getCell(`A${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`A${row}`).alignment = { horizontal: "center" };
      worksheet.getCell(`B${row}`).alignment = { horizontal: "center" };
      worksheet.getCell(`C${row}`).alignment = { horizontal: "center" };
      worksheet.getCell(`B${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`C${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`D${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`E${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`F${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`G${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`G${row},`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`H${row},`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`I${row},`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`J${row},`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`H${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`I${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`J${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`K${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`L${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`M${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`N${row}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getCell(`B${row}`).font = {
        size: 12,
        color: { argb: "FF0000" },
      };
      worksheet.getCell(`C${row}`).font = {
        size: 12,
        color: { argb: "FF0000" },
      };
      worksheet.getRow(rowTitle).font = {
        size: 12,
        bold: true,
      };

      worksheet.getRow(rowTitle + 1).font = {
        size: 12,
        bold: true,
      };
      worksheet.getRow(rowTitle + 1).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      worksheet.getCell(`G${rowTitle + 1}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`H${rowTitle + 1}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`I${rowTitle + 1}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };
      worksheet.getCell(`J${rowTitle + 1}`).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "d9d9d9" },
      };

      worksheet.getRow(rowTitle + 1).alignment = { horizontal: "center" };
      if (worksheet.getCell(`D${rowTitle}`)._mergeCount === 0) {
        worksheet.mergeCells(`D${rowTitle}:E${rowTitle}`);
        worksheet.getCell(`D${rowTitle}`).alignment = { vertical: "middle", horizontal: "center" };
        worksheet.getCell(`D${rowTitle}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      if (worksheet.getCell(`H${rowTitle}`)._mergeCount === 0) {
        worksheet.mergeCells(`H${rowTitle}:I${rowTitle}`);
        worksheet.getCell(`H${rowTitle}`).alignment = { vertical: "middle", horizontal: "center" };
        worksheet.getCell(`H${rowTitle}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`H${rowTitle}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "d9d9d9" },
        };
      }
      if (worksheet.getCell(`L${rowTitle}`)._mergeCount === 0) {
        worksheet.mergeCells(`L${rowTitle}:M${rowTitle}`);
        worksheet.getCell(`L${rowTitle}`).alignment = { vertical: "middle", horizontal: "center" };
        worksheet.getCell(`L${rowTitle}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      if (worksheet.getCell(`A${cellCategory}`)._mergeCount === 0) {
        worksheet.mergeCells(`A${cellCategory}:N${cellCategory}`);

        worksheet.getCell(`A${cellCategory}`).border = {
          bottom: { style: "thin" },
        };
      }
    });

    // Update the rowIndex for the next category
    rowIndex += categoryProducts.length + 6;
    rowTitleIndex += categoryProducts.length + 6;
    cellCategoryIndex += categoryProducts.length + 6;
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(
    blob,
    `Cotação de insumos - ${farm} - ${reportContent[0]?.plan?.harvest?.name} - ${reportContent[0]?.plan?.harvestTime?.name} - ${reportContent[0]?.plan?.crop?.name}.xlsx`
  );
}
