/* eslint-disable */

import * as React from "react";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";

export default function AdminCreate() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");

  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errorCellphone, setErrorCellphone] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const axios = Axios();

  React.useEffect(() => {
    if (id) {
      setEditMode(true);
      axios
        .get(`/v1/auth/${id}`)
        .then((res) => {
          setName(res.data.name);
          setEmail(res.data.email);
          setCellphone(res.data.cellphone);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    if (cellphone.replace(/\D/g, "").length < 11 && cellphone.replace(/\D/g, "").length > 0) {
      setErrorCellphone(true);
    } else {
      setErrorCellphone(false);
    }
  }, [cellphone]);

  const submitCreateCustomer = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      name,
      email,
      cellphone: cellphone.replace(/\D/g, ""),
      role: "admin",
    };
    if (!editMode) {
      axios
        .post("/v1/auth/register", payload)
        .then(() => {
          navigate("/administradores");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      axios
        .put(`/v1/auth/update/${id}`, payload)
        .then(() => {
          navigate("/administradores");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const inativeCustomer = () => {
    axios
      .delete(`/v1/auth/${id}`)
      .then(() => {
        navigate("/administradores");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }} style={{ padding: "24px" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Novo administrador
          </h3>
        </Typography>
        {editMode ? (
          <Grid pb={3} container spacing={2}>
            <Grid item xs={11} />
            <Grid item xs={1}>
              <MDButton
                variant="text"
                color="error"
                disabled={loading}
                type="submit"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={() => setOpenDialog(true)}
              >
                inativar
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        <form onSubmit={submitCreateCustomer}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MDInput
                type="text"
                label="Nome do Contato"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="email"
                label="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask={
                  cellphone.replace(/\D/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"
                }
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                maskChar={null}
                disabled={false}
                required
              >
                {() => <MDInput type="text" label="Telefone" fullWidth error={errorCellphone} />}
              </InputMask>
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <MDButton variant="gradient" color="dark" disabled={loading} type="submit" fullWidth>
                Salvar
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer inativar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDialog(false)}>
            fechar
          </MDButton>
          <MDButton variant="text" color="error" onClick={inativeCustomer} autoFocus>
            Inativar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
