/*eslint-disable*/

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "utils/axiosInstance";
import { customSort } from "utils/sort";

import StyledMenu from "./components/headTable";

const columns = [
  { accessor: "name", Header: "Nome da Fazenda", width: 300 },
  { accessor: "customer.name", Header: "Cliente", width: 100 },
  { accessor: "manager", Header: "Gerente", width: 100 },
  { accessor: "cellphone", Header: "Telefone do Gerente", width: 100 },
  { accessor: "address.city.cityName", Header: "Cidade", width: 100 },
  { accessor: "address.state", Header: "Estado", width: 100 },
];

const filterOptions = ["Nome", "Cliente", "Cidade", "Estado"];

function translateOption(option) {
  switch (option) {
    case "Nome":
      return "name";
    case "Cliente":
      return "customer.name";
    case "Cidade":
      return "address.city.cityName";
    case "Estado":
      return "address.state";
    default:
      return option;
  }
}

export default function FarmList() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [fieldName, setFieldName] = React.useState(filterOptions[0]);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState(null);
  const [pageOptions, setPageOptions] = React.useState([]);
  const axios = Axios();

  const fetchData = async () => {
    let url = `/v1/farm?current=${page + 1}&pageSize=${rowsPerPage}`;

    if (filter !== null && filter !== "") {
      const translatedOption = translateOption(fieldName);
      url = `/v1/farm?current=1&pageSize=10&filter=${translatedOption}&value=${filter}`;
    }

    setLoading(true);
    setRows([]);
    axios
      .get(url)
      .then((res) => {
        const farmList = customSort(res.data.data).map((row) => ({
          ...row,
          name: <Link to={`/fazendas/${row.id}`}>{row.name}</Link>,
        }));

        setRows(farmList);
        const { total } = res.data.pagination;
        setTotalRows(total);
        const options = [];
        for (let i = 0; i < Math.ceil(total / rowsPerPage); i += 1) {
          options.push(i);
        }
        setPageOptions(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, filter, fieldName]);

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  const handleFilterSubmit = (event) => {
    event.preventDefault();
    setPage(0);
  };

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingTop: "24px",
              paddingLeft: "24px",
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Lista de Fazendas
          </h3>
        </Typography>

        <TableContainer>
          <StyledMenu
            filterLabel="Filtrar fazenda por"
            filterOptions={filterOptions}
            fieldName={fieldName}
            setFieldName={setFieldName}
          />
          <form style={{ display: "inline-block" }} onSubmit={handleFilterSubmit}>
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              onChange={handleFilterChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleFilterSubmit(event);
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          <Button
            id="demo-customized-button"
            variant="contained"
            textColor="white"
            style={{ marginRight: "24px", color: "white", float: "right" }}
            onClick={() => navigate("/fazendas/novo")}
          >
            Cadastrar Nova
          </Button>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
            showTotalEntries
            isSorted={false}
            noEndBorder
            pageSize={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            pageIndex={page}
            gotoPage={setPage}
            totalRows={totalRows}
            pageOptions={pageOptions}
            isLoading={loading}
          />
        </TableContainer>
      </Paper>
    </DashboardLayout>
  );
}
