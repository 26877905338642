/*eslint-disable*/
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import logoImg from "assets/images/logo_nuagro.png";
import { useMaterialUIController } from "context";
import React, { Fragment, useEffect, useState } from "react";

export default function ReportShopping() {
  const [controller] = useMaterialUIController();
  // const { reportContent } = controller;
  // const { reportContentDate } = controller;

  const [datesInitial, setDatesInitial] = useState([]);
  const [services, setServices] = useState([]);
  const [sproducts, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [farm, setFarm] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [totalValuePerHectare, setTotalValuePerHectare] = useState(0);
  const [userName, setUserName] = useState();
  const [totalArea, setTotalArea] = useState(0);

  const [reportContent, setReportContent] = useState([]);

  React.useEffect(() => {
    setUserName(JSON.parse(localStorage.getItem("user"))?.name);
    try {
      const reports = JSON.parse(localStorage.getItem("reports"));
      setReportContent(reports);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }, []);

  function formatDate(date) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedDate = new Intl.DateTimeFormat("pt-BR", options).format(date);
    const [datePart, timePart] = formattedDate.split(", ");
    return `${datePart} às ${timePart}`;
  }

  useEffect(() => {
    if (reportContent) {
      const getReportsInitial = reportContent.flatMap((report) => {
        return report.program_service.newDateInitial;
      });

      const getArea = reportContent.flatMap((report) => {
        return report.soil.area;
      });

      // remove duplicate areas
      const uniqueArea = [...new Set(getArea)];

      const totalArea = uniqueArea.reduce((sum, area) => {
        return sum + parseFloat(area);
      }, 0);

      setTotalArea(totalArea);

      const getServices = reportContent.flatMap((report) => {
        return {
          ...report.program_service,
          plan: report.plan,
          soil: report.soil,
        };
      });

      const getProducts = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => {
          const phectare = product.perHectare.replace(",", ".");

          return {
            ...product,
            perHectare: phectare,
            soil: report.soil,
            total: phectare * parseFloat(report.soil.area),
          };
        });
      });

      const productsConsolidated = getProducts.reduce((acc, product) => {
        const existingProductIndex = acc.findIndex((item) => item.name === product.name);

        if (existingProductIndex === -1) {
          return [...acc, product];
        } else {
          const existingProduct = acc[existingProductIndex];
          const updatedProduct = {
            ...existingProduct,
            perHectare: parseFloat(existingProduct.perHectare) + parseFloat(product.perHectare),
            total: parseFloat(existingProduct.total) + parseFloat(product.total),
          };

          updatedProduct.totalValor =
            parseFloat(existingProduct.total) + parseFloat(product.unitPrice);

          return [
            ...acc.slice(0, existingProductIndex),
            updatedProduct,
            ...acc.slice(existingProductIndex + 1),
          ];
        }
      }, []);

      const getSuppliers = reportContent.flatMap((report) => {
        return report.program_service.products.map((product) => product.supplier);
      });

      const getFarm = reportContent.flatMap((report) => {
        return report.plan.farm.name;
      });

      setFarm(removeDuplicateDates(getFarm));
      setDatesInitial(removeDuplicateDates(getReportsInitial));
      setServices(getServices, reportContent);
      setProducts(productsConsolidated);
      setSuppliers(removeDuplicateDates(getSuppliers));
    }
  }, [reportContent]);

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

  function calculateSupplierTotal(supplier) {
    const supplierProducts = sproducts
      .filter((product) => product.supplier === supplier)
      .map((product) => {
        return {
          ...product,
          totalValor: parseFloat(product.total) * parseFloat(product.unitPrice),
        };
      });

    const total = supplierProducts.reduce((sum, product) => {
      return sum + product.totalValor;
    }, 0);

    return total; // Apenas retorna o total, sem atualizar o estado aqui
  }

  // function calculateSupplierTotal(supplier) {
  //   const supplierProducts = sproducts.filter((product) => product.supplier === supplier);
  //   const total = supplierProducts.reduce((sum, product) => {
  //     const productValue = parseFloat(product.perHectare) * parseFloat(product.unitPrice);
  //     return sum + productValue;
  //   }, 0);

  //   return total; // Apenas retorna o total, sem atualizar o estado aqui
  // }

  function calculateSomePerHectare() {
    const somePerHectare = sproducts.reduce((sum, product) => {
      const productValue = parseFloat(product.perHectare);
      return sum + productValue;
    }, 0);

    return somePerHectare;
  }

  useEffect(() => {
    // Step 2: Atualize o valor total do estado após calcular para todos os fornecedores
    let totalForAllSuppliers = 0;
    suppliers.forEach((supplier) => {
      totalForAllSuppliers += calculateSupplierTotal(supplier);
    });
    setTotalValue(totalForAllSuppliers);
    setTotalValuePerHectare(calculateSomePerHectare());
  }, [sproducts, suppliers]);

  function handlePrintButtonClick() {
    window.print();
  }

  return (
    <Container
      style={{
        marginTop: 20,
        padding: "16px !important",
      }}
    >
      <style>
        {`
            .MuiTableCell-root {
              border-bottom: 1px solid black;
              padding: 0px;
            }
            .MuiTable-root {
              border-collapse: collapse;
              width: 100%;
            }
            // .css-1c51995-MuiTableContainer-root{
            //   padding: 16px;
            // }
          `}
      </style>
      <Button
        id="demo-customized-button"
        variant="contained"
        textColor="white"
        style={{ color: "white", marginBottom: "30px" }}
        className="no-print"
        onClick={handlePrintButtonClick}
      >
        Exportar PDF
      </Button>
      <TableContainer
        style={{
          borderRadius: 0,
          boxShadow: "none",
          overflow: "hidden",
          margin: "auto",
          padding: "16px",
        }}
      >
        <TableHead style={{ display: "flex", justifyContent: "space-between" }}>
          <TableHead>
            <TableRow style={{ display: "flex", justifyContent: "space-between" }}>
              <TableCell
                style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "14px" }}
                colSpan={22}
              >
                {reportContent[0]?.plan?.customer?.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            {farm.map((nome, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{ padding: "0px", border: "none", fontWeight: "normal", fontSize: "14px" }}
                >
                  {nome}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </TableHead>
        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ display: "flex", justifyContent: "center" }}>
              <TableCell
                colSpan={12}
                style={{ textAlign: "center", width: "100%", padding: "0px" }}
              ></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <TableHead style={{ display: "contents" }}>
          <TableRow style={{ display: "flex", justifyContent: "center" }}>
            <TableCell
              style={{
                border: "none",
                paddingBottom: "0px",
                display: "flex",
                gap: 10,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              align="center"
            >
              <img src={logoImg} alt="logo" style={{ width: "100px", marginTop: "20px" }} />
              Relação de insumos para compra
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead style={{ display: "contents" }}>
          <TableRow style={{ display: "flex", justifyContent: "center" }}>
            <TableCell
              style={{
                padding: "0px",
                fontWeight: "normal",
                fontSize: "12px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Gerado em {formatDate(new Date())}, por {userName}{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px",
            padding: "10px 0px 16px 0px",
          }}
        >
          <TableHead style={{ display: "flex", flexDirection: "column", padding: "0px" }}>
            <TableRow>
              <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                {reportContent[0]?.filter?.status?.name} entre {reportContent[0]?.filter?.startDate}{" "}
                à {reportContent[0]?.filter?.finishDate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "none", padding: "0px" }} colSpan={12}>
                {reportContent[0]?.plan?.harvest?.name}; {reportContent[0]?.plan?.harvestTime?.name}
                ; {reportContent[0]?.plan?.crop?.name}
              </TableCell>
            </TableRow>
            <TableRow></TableRow>
          </TableHead>
          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell
                style={{ border: "none", padding: "0px", paddingLeft: "16px" }}
                colSpan={12}
              >
                Valor total:{" "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(totalValue)}
              </TableCell>
            </TableRow>
          </TableHead>
        </TableHead>

        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ display: "contents" }}>
            <TableRow>
              <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
            </TableRow>
          </TableHead>

          {suppliers
            .sort((a, b) => a.localeCompare(b))
            .map((supplier, index) => (
              <Fragment key={index}>
                <TableHead style={{ display: "contents" }}>
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{ border: "none", paddingBottom: 35 }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableHead style={{ display: "contents" }}>
                  <TableRow>
                    <TableCell style={{ backgroundColor: "#D8D8D8" }} colSpan={5}>
                      {supplier === null || supplier === undefined || supplier === ""
                        ? "Sem Fornecedor"
                        : supplier.toUpperCase()}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#D8D8D8" }}>
                      {" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(calculateSupplierTotal(supplier))}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead style={{ display: "contents" }}>
                  <TableRow>
                    <TableCell colSpan={4} style={{ border: "none" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableHead style={{ display: "contents" }}></TableHead>
                <TableHead style={{ display: "contents", paddingTop: "16px" }}>
                  <TableRow>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Unid.</TableCell>
                    <TableCell>Demanda de produto</TableCell>
                    <TableCell>Ref. de preço</TableCell>
                    <TableCell>Valor total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sproducts
                    .filter((product) => product.supplier === supplier)
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map((product, sproductIndex) => (
                      <TableRow
                        key={sproductIndex}
                        style={{
                          borderBottom: "dashed 1px black",
                        }}
                      >
                        <TableCell style={{ borderBottom: "none" }}>{product.category}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>{product.name}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>{product.unit}</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {Number(product.total).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(product.unitPrice)}
                        </TableCell>

                        <TableCell style={{ borderBottom: "none" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(parseFloat(product.total) * parseFloat(product.unitPrice))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Fragment>
            ))}
        </Table>
      </TableContainer>
    </Container>
  );
}
