/* eslint-disable */

import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import * as React from "react";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { FetchBrStates, FetchCities } from "utils/apiStateCity";
import Axios from "utils/axiosInstance";

export default function FarmCreate() {
  const [name, setName] = React.useState("");
  const [cliente, setCliente] = React.useState({ id: "", name: "" });
  const [acronym, setAcronym] = React.useState("");
  const [manager, setManager] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [email, setEmail] = React.useState();
  const [brState, setBrState] = React.useState({ stateName: "" });
  const [brCity, setBrCity] = React.useState({ cityName: "" });
  const [clientes, setClientes] = React.useState([]);
  const [brStates, setBrStates] = React.useState([]);
  const [brCities, setBrCities] = React.useState([]);
  const { id } = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [collaboratorList, setCollaboratorList] = React.useState([
    {
      id: "",
      name: "",
      email: "",
    },
  ]);

  const navigate = useNavigate();
  const axios = Axios();

  React.useEffect(() => {
    if (cliente.id) {
      axios
        .get(`/v1/collaborator/${cliente.id}/customer`)
        .then((res) => {
          setCollaboratorList(res.data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [cliente]);

  React.useEffect(() => {
    FetchBrStates().then((states) => {
      const sortedStates = states.sort((a, b) => a.stateName.localeCompare(b.stateName));
      setBrStates(sortedStates);
    });
  }, []);

  React.useEffect(() => {
    axios
      .get(`/v1/customer`)
      .then((res) => {
        setClientes(res.data.data.map((user) => ({ id: user.id, name: user.name })));
      })
      .catch(() => {
        setLoading(false);
      });

    if (id) {
      setEditMode(true);
      axios
        .get(`/v1/farm/${id}`)
        .then((res) => {
          console.log(res.data);
          setName(res.data.name);
          if (res.data.customer) setCliente(res.data.customer);
          setManager(res.data.manager);
          setCellphone(res.data.cellphone);
          setEmail(res.data.email);
          setBrState({ stateName: res.data.address.state });
          setBrCity(res.data.address?.city);
          setAcronym(res.data?.acronym);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const submitCreateCustomer = (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      name: name,
      customer: cliente.id,
      manager: manager,
      cellphone: cellphone.replace(/\D/g, ""),
      address: { state: brState.stateName, city: brCity },
      email: email,
      acronym: acronym,
    };
    if (!editMode) {
      axios
        .post("/v1/farm", payload)
        .then(() => {
          navigate("/fazendas");
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      axios
        .put(`/v1/farm/${id}`, payload)
        .then(() => {
          navigate("/fazendas");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const inativeCustomer = () => {
    axios
      .delete(`/v1/farm/${id}`)
      .then(() => {
        navigate("/fazendas");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (brState && brState.stateId) {
      FetchCities(brState.stateId).then((cities) => {
        setBrCities(cities);
      });
    }
  }, [brState]);

  function generateFarmAcronym(farmName) {
    const words = farmName.split(" ");

    if (words.length === 1) {
      if (farmName.length >= 2) {
        return farmName.substring(0, 2).toUpperCase();
      } else {
        return farmName.toUpperCase();
      }
    } else if (words.length >= 2) {
      const firstWord = words[0];
      const lastWord = words[words.length - 1];

      let acronym = "";

      if (firstWord.length >= 1) {
        acronym += firstWord[0].toUpperCase();
      }

      if (lastWord.length >= 1) {
        acronym += lastWord[0].toUpperCase();
      }

      return acronym;
    }
  }

  return (
    <DashboardLayout>
      <Paper sx={{ width: "100%" }} style={{ padding: "24px" }}>
        <Typography variant="h3" component="div">
          <h3
            style={{
              paddingBottom: "24px",
              display: "inline-block",
            }}
          >
            Nova fazenda
          </h3>
        </Typography>
        {editMode ? (
          <Grid pb={3} container spacing={2}>
            <Grid item xs={11} />
            <Grid item xs={1}>
              <MDButton
                variant="text"
                color="error"
                disabled={loading}
                type="submit"
                startIcon={<DeleteIcon />}
                fullWidth
                onClick={() => setOpenDialog(true)}
              >
                inativar
              </MDButton>
            </Grid>
          </Grid>
        ) : null}
        <form onSubmit={submitCreateCustomer}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="customers"
                  options={clientes}
                  value={cliente}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(e) => {
                    setCliente({
                      id: e.target.attributes.optionid.value,
                      name: e.target.textContent,
                    });
                  }}
                  renderInput={(params) => <MDInput {...params} label="Cliente" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id}>
                      {option.name}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <MDInput
                type="text"
                label="Nome da Fazenda"
                value={name}
                onChange={(e) => {
                  setAcronym(generateFarmAcronym(e.target.value));
                  setName(e.target.value);
                }}
                required
                disabled={editMode}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MDInput
                type="text"
                label="Sigla da Fazenda"
                value={acronym}
                onChange={(e) => setAcronym(generateFarmAcronym(e.target.value))}
                required
                inputProps={{ maxLength: 2 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="Gerente da Fazenda"
                  options={collaboratorList}
                  value={{ id: 0, name: manager || "" }}
                  getOptionLabel={(option) => option.name}
                  openOnFocus
                  onChange={(e, value) => {
                    if (value) {
                      setManager(value.name);
                      setEmail(value.email);
                    } else {
                      setManager("");
                      setEmail("");
                    }
                  }}
                  renderInput={(params) => (
                    <MDInput {...params} label="Gerente da fazenda" required />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.id} optionEmail={option.email}>
                      {option.name}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                type="email"
                label="E-mail do Gerente"
                value={email || ""}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <InputMask
                mask={
                  cellphone.replace(/\D/g, "").length < 11 ? "(99) 9999-99999" : "(99) 99999-9999"
                }
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                maskChar={null}
                disabled={false}
                required
              >
                {() => <MDInput type="text" label="Telefone" fullWidth />}
              </InputMask>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="br-state"
                  options={brStates}
                  value={brState}
                  getOptionLabel={(option) => option?.stateName}
                  openOnFocus
                  onChange={(e, value) => {
                    setBrState(value);
                    setBrCity(null); // Define a cidade como null ao alterar o estado
                  }}
                  renderInput={(params) => <MDInput {...params} label="Estado" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionid={option?.stateUf}>
                      {option?.stateName}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="br-city"
                  options={brCities || []}
                  value={brCity}
                  getOptionLabel={(option) => option?.cityName}
                  openOnFocus
                  onChange={(e) =>
                    setBrCity({
                      cityName: e.target.textContent,
                    })
                  }
                  renderInput={(params) => <MDInput {...params} label="Cidade" required />}
                  renderOption={(props, option) => (
                    <li {...props} optionId={option.cityUf}>
                      {option.cityName}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <MDButton variant="gradient" color="dark" disabled={loading} type="submit" fullWidth>
                Salvar
              </MDButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tem certeza que quer inativar?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setOpenDialog(false)}>
            fechar
          </MDButton>
          <MDButton variant="text" color="error" onClick={inativeCustomer} autoFocus>
            Inativar
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
