/* eslint-disable */

import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "utils/axiosInstance";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { formatDate } from "utils/formatDate";
const formulations = [
  { id: 1, name: "1 - Adjuvantes e Condicionadores de Calda" },
  { id: 2, name: "2 - Pós Molháveis (wp)" },
  { id: 3, name: "3 - Grânulos Dispersíveis em Água (wg)" },
  { id: 4, name: "4 - Foliares Dry" },
  { id: 5, name: "5 - Suspensão Concentrada (sc)" },
  { id: 6, name: "6 - Emulsão em Água (EW)" },
  { id: 7, name: "7 - Concentrados Emulsionáveis (EC)" },
  { id: 8, name: "8 - Concentrações Solúveis (SL)" },
  { id: 9, name: "9 - Solução Aquosa Concentrada (SANC)" },
  { id: 10, name: "10 - Solução Aquosa Concentrada (SAC)" },
  { id: 11, name: "11 - Foliares Convencionais" },
  { id: 12, name: "12 - Óleo" },
  { id: 13, name: "13 - Não se aplica" },
];

export default function Step2({ planId, nextStep }) {
  const [controller, dispatch] = useMaterialUIController();
  const stepNumber = 2;
  const serviceRefs = useRef([]);

  const axios = Axios();
  const { id } = useParams();

  const idPlan = id || planId;

  const [newProduct, setNewProduct] = useState({});

  const [plan, setPlan] = useState({});
  const [products, setProducts] = useState([{}]);

  const [productList, setProductList] = useState([]);
  const [servicesList, setServicesList] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (idPlan) {
      axios.get(`/v1/plans/${idPlan}`).then((res) => {
        const { data } = res;
        const services = data.services.map((service) => {
          const products = service.products.length
            ? service.products
            : [{ id: "", name: "", category: "", unit: "", formulation: "", perHectare: "" }];

          return { ...service, products, defaultService: service.defaultService };
        });

        setPlan({ ...data, services });
      });
    }
    axios.get(`/v1/services`).then((res) => {
      setServicesList(res.data.data);
    });

    axios.get(`/v1/products`).then((res) => {
      const productList = res.data.data;
      productList.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convertendo para letras maiúsculas para uma ordenação case-insensitive
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1; // Retorna um número negativo se a ordem for a < b
        }
        if (nameA > nameB) {
          return 1; // Retorna um número positivo se a ordem for a > b
        }
        return 0; // Retorna 0 se a ordem for a = b
      });

      setProductList(productList);
    });

    axios.get(`/v1/categories`).then((res) => {
      setCategoryList(res.data.data);
    });

    axios.get(`/v1/units`).then((res) => {
      setUnitList(res.data.data);
    });
  }, [idPlan]);

  const addService = () => {
    const lastService = plan.services[plan.services.length - 1];

    if (lastService.id || (lSET_PRICESastService.products && lastService.products[0].id)) {
      const services = [
        ...plan.services,
        { id: "", name: "", products: [{}], defaultService: lastService.defaultService },
      ];
      setPlan({ ...plan, services });
    }
  };

  const submitStep2 = (e) => {
    e.preventDefault();

    const { services } = plan;

    const sortedServices = services.map((service) => {
      const sortedProducts = service.products
        .filter((product) => product.id)
        .sort((a, b) => {
          const numberA = parseInt(a.formulation.split(' ')[0], 10);
          const numberB = parseInt(b.formulation.split(' ')[0], 10);
          return numberA - numberB;
        });

      return { ...service, products: sortedProducts };
    });

    const data = {
      services: sortedServices,
      status: "SET_PRICES",
    };

    axios.patch(`/v1/plans/status/${idPlan}`, data).then((res) => {
      nextStep(idPlan);
    });
  };


  const toggleDialog = () => {
    setOpenDialog(!openDialog);
    if (openDialog) {
      setNewProduct({
        id: "",
        name: "",
        category: { id: "", name: "" },
        unit: { id: "", abbreviation: "" },
        formulation: { id: "", name: "" },
      });
    }
  };

  const submitNewProduct = () => {
    delete newProduct.id;

    axios.post(`/v1/products`, newProduct).then((res) => {
      const { data } = res;
      setProductList((prev) => {
        const newList = [...prev, data];
        newList.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        return newList;
      });

      toggleDialog();
    });
  };
  const handleLastServiceProductKeyPress = (event, indexService) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const nextIndex = (indexService + 1) % plan.services.length;
      serviceRefs[nextIndex][0]?.focus();
    }
  };

  return (
    <div>
      <TableContainer style={{ marginBottom: "1em" }}>
        <Table id="table-form-header" sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead style={{ display: "contents" }}>
            <TableRow style={{ backgroundColor: "#000000de", color: "white" }}>
              <TableCell style={{ color: "inherit" }} colSpan={5} align="center">
                {plan.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width="20%">ID: PLAN-00{planId || id} </TableCell>
              <TableCell width="20%">Cliente: {plan?.customer?.name}</TableCell>
              <TableCell width="20%">Fazenda: {plan?.farm?.name} </TableCell>
              <TableCell width="20%">Cultura: {plan?.crop?.name} </TableCell>
              <TableCell width="20%">Data de Criação: {formatDate(plan?.startDate)} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <form onSubmit={submitStep2}>
        {plan.services &&
          plan.services.map((service, indexService) => {
            return (
              <Fragment key={indexService}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MDButton variant="gradient" color="dark" onClick={() => setOpenDialog(true)}>
                    novo produto
                  </MDButton>
                </Grid>
                <div
                  style={{
                    padding: "20px 20px 0px 20px",
                    marginTop: "1em",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  <Grid container spacing={2} pb={3} pl={1} pr={1}>
                    <Grid item xs={12} md={4} sm={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id={`services-${indexService}`}
                          options={servicesList}
                          value={service || ""}
                          required
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => {
                            setPlan({
                              ...plan,
                              services: [
                                ...plan.services.slice(0, indexService),
                                {
                                  ...plan.services[indexService],
                                  id: value.id,
                                  name: value.name,
                                },
                                ...plan.services.slice(indexService + 1),
                              ],
                            });
                          }}
                          openOnFocus
                          renderInput={(params) => (
                            <MDInput {...params} label={`#${indexService + 1}º Serviço`} required />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} optionId={option.id}>
                              {option.name}
                            </li>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      sm={2}
                      style={
                        indexService === 0
                          ? { display: "none" }
                          : {
                              textAlign: "center",
                              display: "flex",
                              alignItems: "end",
                              justifyContent: "center",
                            }
                      }
                    >
                      <MDButton
                        onClick={() => {
                          setPlan({
                            ...plan,
                            services: [
                              ...plan.services.slice(0, indexService),
                              ...plan.services.slice(indexService + 1),
                            ],
                          });
                        }}
                        variant="gradient"
                        color="dark"
                        disabled={indexService === 0}
                      >
                        remover
                      </MDButton>
                    </Grid>
                  </Grid>

                  {service.products.map((product, indexProduct) => {
                    return (
                      <Grid container spacing={2} columns={12} key={indexProduct}>
                        <Grid item xs={12} md={3} sm={3}>
                          <FormControl fullWidth>
                            <Autocomplete
                              disablePortal
                              autoFocus
                              id="product"
                              options={productList}
                              required
                              value={{ id: 0, name: product.name ?? "" }}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  // quando o input é limpo, atualize o plan com valores vazios para o produto
                                  setPlan({
                                    ...plan,
                                    services: [
                                      ...plan.services.slice(0, indexService),
                                      {
                                        ...plan.services[indexService],
                                        products: [
                                          ...plan.services[indexService].products.slice(
                                            0,
                                            indexProduct
                                          ),
                                          {
                                            ...plan.services[indexService].products[indexProduct],
                                            id: 0,
                                            name: "",
                                            category: "",
                                            unit: "",
                                            formulation: "",
                                          },
                                          ...plan.services[indexService].products.slice(
                                            indexProduct + 1
                                          ),
                                        ],
                                      },
                                      ...plan.services.slice(indexService + 1),
                                    ],
                                  });
                                }
                              }}
                              onChange={(_, value) => {
                                setPlan({
                                  ...plan,
                                  services: [
                                    ...plan.services.slice(0, indexService),
                                    {
                                      ...plan.services[indexService],
                                      products: [
                                        ...plan.services[indexService].products.slice(
                                          0,
                                          indexProduct
                                        ),
                                        {
                                          ...plan.services[indexService].products[indexProduct],
                                          id: value.id,
                                          name: value.name,
                                          category: value.category.name,
                                          unit: value.unit.name,
                                          formulation: value.formulation.name,
                                          unitPrice: 0,
                                          supplier: "",
                                        },
                                        ...plan.services[indexService].products.slice(
                                          indexProduct + 1
                                        ),
                                      ],
                                    },
                                    ...plan.services.slice(indexService + 1),
                                  ],
                                });
                              }}
                              openOnFocus
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  label="Produto"
                                  disabled={stepNumber === 4}
                                  required
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} optionId={option.id}>
                                  {option.name}
                                </li>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} sm={2}>
                          <MDInput
                            type="text"
                            required
                            label="Categoria"
                            value={product?.category || ""}
                            readOnly
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={2} sm={2}>
                          <MDInput
                            type="text"
                            required
                            label="Unidade"
                            value={product?.unit || ""}
                            readOnly
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                          <MDInput
                            type="text"
                            required
                            label="Formulação"
                            value={product?.formulation || ""}
                            readOnly
                            disabled
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={service.products.length === 1 ? 2 : 1} sm={2}>
                          <MDInput
                            type="number"
                            label="Dose/ha"
                            required
                            value={product.perHectare || ""}
                            onKeyDown={(e) => {
                              if (indexProduct === service.products.length - 1) {
                                handleLastServiceProductKeyPress(e, indexService);
                              }
                            }}
                            onChange={(e) => {
                              setPlan({
                                ...plan,
                                services: [
                                  ...plan.services.slice(0, indexService),
                                  {
                                    ...plan.services[indexService],
                                    products: [
                                      ...plan.services[indexService].products.slice(
                                        0,
                                        indexProduct
                                      ),
                                      {
                                        ...plan.services[indexService].products[indexProduct],
                                        perHectare: e.target.value,
                                      },
                                      ...plan.services[indexService].products.slice(
                                        indexProduct + 1
                                      ),
                                    ],
                                  },
                                  ...plan.services.slice(indexService + 1),
                                ],
                              });
                            }}
                            fullWidth
                            InputProps={{ inputProps: { min: 0.0000001, step: 0.0000001 } }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          style={{ display: "flex", alignItems: "end", justifyContent: "center" }}
                        >
                          <MDButton
                            variant="contained"
                            color="dark"
                            iconOnly
                            circular
                            style={service.products.length === 1 ? { display: "none" } : {}}
                            onClick={() => {
                              setPlan({
                                ...plan,
                                services: [
                                  ...plan.services.slice(0, indexService),
                                  {
                                    ...plan.services[indexService],
                                    products: [
                                      ...plan.services[indexService].products.slice(
                                        0,
                                        indexProduct
                                      ),
                                      ...plan.services[indexService].products.slice(
                                        indexProduct + 1
                                      ),
                                    ],
                                  },
                                  ...plan.services.slice(indexService + 1),
                                ],
                              });
                            }}
                          >
                            <CloseIcon />
                          </MDButton>
                        </Grid>
                      </Grid>
                    );
                  })}

                  <Grid
                    container
                    spacing={2}
                    mt={1}
                    style={{
                      marginBottom: "1em",
                    }}
                  >
                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "20px" }}>
                      <MDButton
                        type="submit"
                        variant="contained"
                        color="dark"
                        iconOnly
                        circular
                        onClick={() => {
                          setPlan({
                            ...plan,
                            services: [
                              ...plan.services.slice(0, indexService),
                              {
                                ...plan.services[indexService],
                                products: [
                                  ...plan.services[indexService].products,
                                  {
                                    id: 0,
                                    name: "",
                                    category: "",
                                    unit: "",
                                    formulation: "",
                                    perHectare: "",
                                  },
                                ],
                              },
                              ...plan.services.slice(indexService + 1),
                            ],
                          });
                        }}
                      >
                        <AddIcon />
                      </MDButton>
                    </Grid>
                  </Grid>
                </div>
              </Fragment>
            );
          })}

        <Grid container>
          <Grid item xs={12} textAlign="center">
            <MDButton
              variant="gradient"
              color="dark"
              type="submit"
              onClick={() => addService()}
              style={{ marginRight: "1em" }}
            >
              Adicionar Serviço
            </MDButton>
            <MDButton variant="gradient" color="dark" type="submit">
              Continuar Planejamento
            </MDButton>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={openDialog}
        onClose={toggleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cadastro de Novo produto</DialogTitle>
        <DialogContent>
          <MDInput
            type="text"
            label="Nome do Produto"
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            value={newProduct.name || ""}
            required
            fullWidth
          />
          <Autocomplete
            id="category"
            options={categoryList}
            getOptionLabel={(option) => option.name}
            openOnFocus
            onChange={(event, newValue) => {
              setNewProduct({ ...newProduct, category: newValue });
            }}
            renderInput={(params) => <MDInput {...params} label="Categoria" />}
          />
          <Autocomplete
            id="unit"
            options={unitList}
            getOptionLabel={(option) => option.name}
            openOnFocus
            onChange={(event, newValue) => {
              setNewProduct({ ...newProduct, unit: newValue });
            }}
            renderInput={(params) => <MDInput {...params} label="Unidade" />}
          />
          <Autocomplete
            id="formulation"
            options={formulations}
            getOptionLabel={(option) => option.name}
            openOnFocus
            onChange={(event, newValue) => {
              setNewProduct({ ...newProduct, formulation: newValue });
            }}
            renderInput={(params) => <MDInput {...params} label="Formulação" />}
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={toggleDialog}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            autoFocus
            type="submit"
            onClick={() => submitNewProduct()}
          >
            salvar
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
