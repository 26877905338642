/* eslint-disable */

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import RealInput from "components/custom-inputs/RealInput";
import { setIsLoading, useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { quoteGenerateXlxs } from "../components/quote";

import Axios from "utils/axiosInstance";

export default function Step3({ planId, nextStep }) {
  const [controller, dispatch] = useMaterialUIController();
  const { isLoading } = controller;
  const [services, setServices] = useState([{}]);
  const [products, setProducts] = useState([{}]);
  const [productsComplete, setProductsComplete] = useState([{}]);
  const [categories, setCategories] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [unitPrices, setUnitPrices] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [plan, setPlan] = useState({});
  const [userName, setUserName] = useState();
  const [productQuote, setProductQuote] = React.useState([]);
  const [categoriesQuote, setCategoriesQuote] = React.useState([]);
  const [suppliersQuote, setSuppliersQuote] = React.useState([]);

  const [newSupplier, setNewSupplier] = useState({
    id: "",
    name: "",
  });

  const [supplier, setSupplier] = useState({ name: "" });
  const [suppliers, setSuppliers] = useState([]);

  const { id } = useParams();

  const axios = Axios();

  const idPlan = id || planId;

  useEffect(() => {
    setIsLoading(dispatch, !isLoading);
    axios.get(`/v1/plans/${idPlan}`).then((res) => {
      setPlan(res.data);
      let servicesList = res.data.services.map((service, serviceIndex) => ({
        ...service,
        products: service.products.map((product, productIndex) => ({
          ...product,
          uniqueIndex: `${serviceIndex}-${productIndex}`,
        })),
      }));

      let productsList = servicesList.flatMap((service) => service.products) || [];

      setServices(servicesList);

      productsList.sort((a, b) => a.name.localeCompare(b.name));

      const uniqueCategories = [...new Set(productsList.map((product) => product.category))];

      setCategories(uniqueCategories);
      setIsLoading(dispatch, false);

      // Se o produto ja existir na lista de productsList, adicionar o supplier e unitPrice no que não tiver (se for o mesmo produto)

      const productsWithSupplierAndUnitPrice = productsList.map((product) => {
        const matchingProduct = productsList.find(
          (p) => p.name === product.name && p.unit === product.unit
        );

        if (matchingProduct) {
          return {
            ...product,
            supplier: matchingProduct.supplier,
            unitPrice: matchingProduct.unitPrice,
          };
        }

        return product;
      });

      const allFieldsFilled = productsWithSupplierAndUnitPrice.every(
        (product) =>
          product.name &&
          product.unit &&
          product.perHectare &&
          product.unitPrice &&
          product.supplier
      );

      const withoutDuplicates = productsWithSupplierAndUnitPrice.filter(
        (product, index, self) =>
          index === self.findIndex((t) => t.name === product.name && t.unit === product.unit)
      );

      setProductsComplete(productsWithSupplierAndUnitPrice);
      setProducts(withoutDuplicates);
      setDisabled(!allFieldsFilled);
    });

    axios.get(`/v1/supplier`).then((res) => {
      let suppliersList = res.data.data.map((user) => ({ id: user.id, name: user.name }));
      suppliersList.sort((a, b) => a.name.localeCompare(b.name));

      setSuppliers(suppliersList);
    });
  }, [id, planId]);

  const subtotal = () => {
    let total = 0;
    for (let j = 0; j < products.length; j += 1) {
      let perHectare = 0;
      let unitPrice = 0;
      if (!Number.isNaN(products[j].perHectare)) {
        perHectare = products[j].perHectare;
      }
      if (unitPrices[products[j].uniqueIndex] !== undefined) {
        unitPrice = unitPrices[products[j].uniqueIndex];
      }
      total += perHectare * unitPrice;
    }

    return total;
  };

  const updProduct = (raw) => {
    const productList = JSON.parse(JSON.stringify(products));
    for (let i = 0; i < productList.length; i += 1) {
      if (raw.uniqueIndex === productList[i].uniqueIndex) {
        productList[i] = {
          ...productList[i],
          ...raw,
        };
      }
    }

    const productListComplete = JSON.parse(JSON.stringify(productsComplete));
    for (let i = 0; i < productListComplete.length; i += 1) {
      if (raw.id === productListComplete[i].id) {
        delete raw.uniqueIndex;
        productListComplete[i] = {
          ...productListComplete[i],
          ...raw,
        };
      }
    }

    const allProductsFilled = productList.every((product) => product.unitPrice && product.supplier);

    if (subtotal() > 0 && allProductsFilled) setDisabled(false);
    setProducts(productList);
    setProductsComplete(productListComplete);
  };

  const handleUnitPriceChange = (uniqueIndex, newPrice, id) => {
    setUnitPrices((prevPrices) => ({ ...prevPrices, [uniqueIndex]: newPrice }));
    updProduct({ uniqueIndex, unitPrice: newPrice, id });
  };

  const hectarePrice = (hectare, unitPrice) => {
    let myPerHectare = 0;
    let myUnitPrice = 0;
    if (!Number.isNaN(hectare)) myPerHectare = hectare;
    if (unitPrice && !Number.isNaN(unitPrice)) myUnitPrice = unitPrice;
    const total = myPerHectare * myUnitPrice;
    return total.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const totalGeral = () => {
    let rawSubtotal = 0;
    for (const product of products) {
      let unitPrice = unitPrices[product.id] || product.unitPrice || 0.0;
      let perHectare = product.perHectare || 0;
      rawSubtotal += perHectare * unitPrice;
    }
    return rawSubtotal.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  };

  const submitOnStep4 = () => {
    const updatedServices = services.map((service) => {
      const updatedServiceProducts = service.products.map((serviceProduct) => {
        const matchingProduct = productsComplete.find(
          (product) => product.uniqueIndex === serviceProduct.uniqueIndex
        );

        return matchingProduct ? matchingProduct : serviceProduct;
      });

      return {
        ...service,
        products: updatedServiceProducts,
      };
    });

    updatedServices.forEach((service) => {
      service.products.forEach((product) => {
        delete product.uniqueIndex;
      });
    });

    axios
      .patch(`/v1/plans/status/${idPlan}`, { services: updatedServices, status: "FINISH" })
      .then(() => {
        nextStep(idPlan);
        // setNotification(dispatch, {
        //   title: "Planejamento salvo.",
        //   color: "success",
        //   icon: "success",
        //   open: true,
        // });
      });
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
    if (openDialog) {
      setNewSupplier({ id: "", name: "" });
    }
  };

  const submitSupplier = () => {
    if (newSupplier) {
      axios.post("/v1/supplier", { name: newSupplier.name }).then((res) => {
        const newSupplierData = res.data;

        const newSuppliers = [...suppliers, { name: newSupplierData.name }];
        newSuppliers.sort((a, b) => a.name.localeCompare(b.name));

        setSuppliers(newSuppliers);
        toggleDialog();
      });
    }
  };

  const handleClearData = () => {
    const clearedProducts = products.map((product) => ({
      ...product,
      unitPrice: undefined,
      supplier: "",
    }));

    setProducts(clearedProducts);
    setUnitPrices({});
    setConfirmDialogOpen(false);
    setDisabled(true);
  };

  function removeDuplicateDates(dateArray) {
    const uniqueDates = [...new Set(dateArray)];
    return uniqueDates;
  }

 useEffect(() => {
  setUserName(JSON.parse(localStorage.getItem("user"))?.name);

  if (plan) {
    const getCategory = plan.services?.flatMap((service) => {
      return service.products?.map((product) => product.category) || [];
    }) || [];

    const getSuppliers = plan.services?.flatMap((service) => {
      return service.products?.map((product) => product.supplier) || [];
    }) || [];

    const getProducts = plan.services?.flatMap((service) => {
      return service.products?.map((product) => product) || [];
    }) || [];

    const productsConsolidated = getProducts.reduce((acc, product) => {
      const productIndex = acc.findIndex((item) => item.name === product.name);
      if (productIndex === -1) {
        return [...acc, product];
      } else {
        const productFound = acc[productIndex];

        const productFoundHectare = productFound.perHectare.toString().includes(",")
          ? productFound.perHectare.replace(",", ".")
          : productFound.perHectare;
        const productHectare = product.perHectare.includes(",")
          ? product.perHectare.replace(",", ".")
          : product.perHectare;

        const productUpdated = {
          ...productFound,
          perHectare: parseFloat(productFoundHectare) + parseFloat(productHectare),
        };
        return [...acc.slice(0, productIndex), productUpdated, ...acc.slice(productIndex + 1)];
      }
    }, []);

    setCategoriesQuote(removeDuplicateDates(getCategory));
    setSuppliersQuote(removeDuplicateDates(getSuppliers));
    setProductQuote(productsConsolidated);
  }
}, [plan]);


  const handleGenerateXLSX = () => {
    quoteGenerateXlxs(plan, categoriesQuote, productQuote, userName);
  };

  return (
    <>
      <Paper
        sx={{ width: "100%" }}
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        {categories.map((category) => (
          <div key={category}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <h4>{category}</h4>
              <MDButton
                style={{ marginBottom: "10px" }}
                variant="gradient"
                color="dark"
                onClick={() => setOpenDialog(true)}
              >
                Cadastrar fornecedor
              </MDButton>
            </Grid>
            {products
              .filter((product) => product.category === category)
              .map((product) => {
                const unitPrice = unitPrices[product.uniqueIndex] || product.unitPrice || 0.0;
                return (
                  <div key={product.id} style={{ marginBottom: "1em" }}>
                    <Grid container spacing={2} columns={15}>
                      <Grid item xs={12} md={3} sm={3}>
                        <MDInput
                          type="text"
                          label="Produto"
                          value={product.name}
                          readOnly
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sm={2}>
                        <MDInput
                          type="text"
                          label="Unidade"
                          value={product.unit}
                          readOnly
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sm={2}
                        style={{
                          display: "none",
                        }}
                      >
                        <MDInput
                          type="number"
                          label="Dose/ha"
                          value={product.perHectare}
                          readOnly
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={2} sm={2}>
                        <RealInput
                          label="Custo Unitário"
                          value={unitPrice}
                          style={{
                            backgroundColor: `${unitPrice ? "#fff" : "rgba(244,67,54,0.2)"}`,
                          }}
                          onChange={(e) => {
                            handleUnitPriceChange(product.uniqueIndex, e.target.value, product.id);
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sm={2}
                        style={{
                          display: "none",
                        }}
                      >
                        <MDInput
                          type="text"
                          label="Custo Dose/ha"
                          value={hectarePrice(product.perHectare, unitPrice)}
                          readOnly
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={3}>
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            id="supplier"
                            options={suppliers}
                            value={{ id: 0, name: product.supplier || "" }}
                            getOptionLabel={(option) => option.name}
                            openOnFocus
                            onChange={(e, newValue) => {
                              setSupplier(newValue);
                              updProduct({
                                uniqueIndex: product.uniqueIndex,
                                supplier: newValue.name,
                                id: product.id,
                              });
                            }}
                            renderInput={(params) => (
                              <MDInput
                                {...params}
                                label="Fornecedor"
                                style={{
                                  backgroundColor: `${
                                    product.supplier ? "#fff" : "rgba(244,67,54,0.2)"
                                  }`,
                                }}
                                fullWidth
                                required
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
          </div>
        ))}

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} textAlign="end" display="none">
            <h2>Total: {totalGeral()}</h2>
          </Grid>
          <Grid item xs={12} textAlign="end">
            <Stack direction="row-reverse" spacing={2} style={{ textAlign: "end" }}>
              <MDButton variant="gradient" color="dark" onClick={submitOnStep4} disabled={disabled}>
                continuar planejamento
              </MDButton>
              <MDButton variant="gradient" color="error" onClick={() => setConfirmDialogOpen(true)}>
                Limpar Dados
              </MDButton>
              <MDButton
                variant="gradient"
                color="dark"
                textColor="white"
                style={{ color: "white" }}
                onClick={handleGenerateXLSX}
              >
                Relatório Cotação
              </MDButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <DialogContent id="alert-dialog-description">
            Tem certeza de que deseja limpar os dados de custo unitário e fornecedor?
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => setConfirmDialogOpen(false)}>
            Cancelar
          </MDButton>
          <MDButton variant="text" color="error" autoFocus onClick={handleClearData}>
            Limpar
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cadastro de Novo Fornecedor</DialogTitle>
        <DialogContent>
          <MDInput
            type="text"
            label="Nome do Fornecedor"
            onChange={(e) => setNewSupplier({ name: e.target.value })}
            value={newSupplier.name || ""}
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" color="secondary" onClick={() => toggleDialog()}>
            fechar
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            autoFocus
            type="submit"
            onClick={() => submitSupplier()}
          >
            salvar
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
