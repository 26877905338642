/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all
copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop
    inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for
  the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title
  text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
/*eslint-disable*/

// Material Dashboard 2 React layouts
import AdminCreate from "layouts/admin/adminCreate";
import AdminList from "layouts/admin/adminList";
import CategoriesList from "layouts/categories/categoriesList";
import CropsList from "layouts/crops/cropsList";
import CustomerCreate from "layouts/customers/customerCreate";
import CustomersList from "layouts/customers/customersList";
import FarmCreate from "layouts/farms/farmCreate";
import FarmsList from "layouts/farms/farmsList";
import HarvestTimeList from "layouts/harvestTime/harvestTimeList";
import HarvestsList from "layouts/harvests/harvestsList";
import Planning from "layouts/plans/planList";
import ProductsList from "layouts/products/productsList";
import ReportsList from "layouts/reports/reportsList";
import ServicesList from "layouts/services/servicesList";
import SoilsList from "layouts/soils/soilsList";
import SupplierList from "layouts/supplier/supplierList";
import UnitsList from "layouts/units/unitsList";

import SignIn from "layouts/auth/login";
import Page404 from "layouts/pages/page404";

// @mui icons
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import BalanceIcon from "@mui/icons-material/Balance";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import ClassIcon from "@mui/icons-material/Class";
import CottageIcon from "@mui/icons-material/Cottage";
import InventoryIcon from "@mui/icons-material/Inventory";
import SpaIcon from "@mui/icons-material/Spa";
import Icon from "@mui/material/Icon";

import AuthGuard from "context/auth.context";
import PasswordReset from "layouts/auth/passwordReset";
import CollaboratorSoilView from "layouts/collaborator/soil";
import CollaboratorCreate from "layouts/collaborators/collaboratorCreate";
import CollaboratorList from "layouts/collaborators/collaboratorsList";
import Agenda from "layouts/orders/Agenda";
import OrderList from "layouts/orders/orderList";
import CreatePlan from "layouts/plans/createPlan";
import ReportProgramManagement from "layouts/reports/ReportProgramManagement";
import BudgetReport from "layouts/reports/budgetReport";
import QuoteReports from "layouts/reports/quoteReports";
import ReportActivities from "layouts/reports/reportActivities";
import ReportShopping from "layouts/reports/reportShopping";

const routes = [
  {
    type: "collapse",
    name: "Clientes",
    key: "clientes",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/clientes",
    component: (
      <AuthGuard>
        <CustomersList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Fazendas",
    key: "fazendas",
    icon: <CottageIcon fontSize="small" />,
    route: "/fazendas",
    component: (
      <AuthGuard>
        <FarmsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Glebas",
    key: "glebas",
    icon: <ArchitectureIcon fontSize="small" />,
    route: "/glebas",
    component: (
      <AuthGuard>
        <SoilsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Safras",
    key: "safras",
    icon: <AgricultureIcon fontSize="small" />,
    route: "/safras",
    component: (
      <AuthGuard>
        <HarvestsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Épocas",
    key: "epocas",
    icon: <Brightness5Icon fontSize="small" />,
    route: "/epocas",
    component: (
      <AuthGuard>
        <HarvestTimeList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Culturas",
    key: "culturas",
    icon: <SpaIcon fontSize="small" />,
    route: "/culturas",
    component: (
      <AuthGuard>
        <CropsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Fornecedores",
    key: "fornecedores",
    icon: <AddBusinessIcon fontSize="small" />,
    route: "/fornecedores",
    component: (
      <AuthGuard>
        <SupplierList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Unidades",
    key: "unidades",
    icon: <BalanceIcon fontSize="small" />,
    route: "/unidades",
    component: (
      <AuthGuard>
        <UnitsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Categorias",
    key: "categorias",
    icon: <ClassIcon fontSize="small" />,
    route: "/categorias",
    component: (
      <AuthGuard>
        <CategoriesList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Produtos",
    key: "produtos",
    icon: <InventoryIcon fontSize="small" />,
    route: "/produtos",
    component: (
      <AuthGuard>
        <ProductsList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Serviços",
    key: "servicos",
    icon: <CarpenterIcon fontSize="small" />,
    route: "/servicos",
    component: (
      <AuthGuard>
        <ServicesList />
      </AuthGuard>
    ),
  },
  {
    type: "collapse",
    name: "Funcionários",
    key: "funcionarios",
    icon: <BadgeIcon fontSize="small" />,
    route: "/funcionarios",
    component: (
      <AuthGuard>
        <CollaboratorList />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Novo Funcionário",
    key: "new-collaborator",
    icon: <BadgeIcon fontSize="small" />,
    route: "/funcionarios/novo",
    component: (
      <AuthGuard>
        <CollaboratorCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Editar Funcionário",
    key: "edit-collaborator",
    icon: <BadgeIcon fontSize="small" />,
    route: "/funcionarios/:id",
    component: (
      <AuthGuard>
        <CollaboratorCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Novo cliente",
    key: "new-customer",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/clientes/novo",
    component: (
      <AuthGuard>
        <CustomerCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Editar cliente",
    key: "edit-customer",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/clientes/:id",
    component: (
      <AuthGuard>
        <CustomerCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <SignIn />,
  },
  {
    type: "hidden",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "hidden",
    name: "Reset Password",
    key: "resetpass",
    icon: <Icon fontSize="small">Nova Senha</Icon>,
    route: "/auth/resetPassword",
    component: <PasswordReset />,
  },
  {
    type: "hidden",
    name: "Página não encontrada!",
    key: "page-404",
    icon: <Icon fontSize="small">login</Icon>,
    route: "*",
    component: <Page404 />,
  },
  {
    type: "hidden",
    name: "Criar fazenda",
    key: "new-farm",
    icon: <CottageIcon fontSize="small" />,
    route: "/fazendas/novo",
    component: (
      <AuthGuard>
        <FarmCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Editar fazenda",
    key: "edit-farm",
    icon: <CottageIcon fontSize="small" />,
    route: "/fazendas/:id",
    component: (
      <AuthGuard>
        <FarmCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Planejamentos",
    key: "planning",
    icon: <CottageIcon fontSize="small" />,
    route: "/planejamentos",
    component: (
      <AuthGuard>
        <Planning />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "relatórios",
    key: "reports",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorios",
    component: <ReportsList />,
  },
  {
    type: "hidden",
    name: "Planejamentos",
    key: "planning-new",
    icon: <CottageIcon fontSize="small" />,
    route: "/planejamentos/novo",
    component: (
      <AuthGuard>
        <CreatePlan />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Planejamentos",
    key: "planning-new",
    icon: <CottageIcon fontSize="small" />,
    route: "/planejamentosnovo/novo",
    component: (
      <AuthGuard>
        <CreatePlan />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Planejamentos editar",
    key: "planning-edit",
    icon: <CottageIcon fontSize="small" />,
    route: "/planejamentos/:id",
    component: (
      <AuthGuard>
        <CreatePlan />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Planejamentos editar",
    key: "planning-edit",
    icon: <CottageIcon fontSize="small" />,
    route: "/planejamentosnovo/:id",
    component: <CreatePlan />,
  },
  {
    type: "hidden",
    name: "Ordens de serviço",
    key: "orders",
    icon: <CottageIcon fontSize="small" />,
    route: "/agenda",
    component: (
      <AuthGuard>
        <OrderList />
      </AuthGuard>
    ),
  },
  // {
  //   type: "hidden",
  //   name: "collaborators-home",
  //   key: "coolaborators-home",
  //   icon: <CottageIcon fontSize="small" />,
  //   route: "/colaborador",
  //   component: <CollaboratorHome />,
  // },
  {
    type: "hidden",
    name: "collaborators-soil",
    key: "coolaborators-soil",
    icon: <CottageIcon fontSize="small" />,
    route: "/colaborador/servicos",
    component: <CollaboratorSoilView />,
  },
  {
    type: "hidden",
    name: "agenda",
    key: "agenda",
    icon: <CottageIcon fontSize="small" />,
    route: "/agenda/:id",
    component: <Agenda />,
  },
  {
    type: "hidden",
    name: "exibirRel",
    key: "exibirRel",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorioAtividade",
    component: <ReportActivities />,
  },
  {
    type: "hidden",
    name: "relatorioCompras",
    key: "compras",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorioCompras",
    component: <ReportShopping />,
  },
  {
    type: "hidden",
    name: "relatorioProgramManejo",
    key: "programaManejo",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorioProgramaManejo",
    component: <ReportProgramManagement />,
  },
  {
    type: "hidden",
    name: "relatorioOrçamento",
    key: "orçamento",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorioOrcamento",
    component: <BudgetReport />,
  },
  {
    type: "hidden",
    name: "relatorioCotacao",
    key: "cotacao",
    icon: <CottageIcon fontSize="small" />,
    route: "/relatorioCotacao",
    component: <QuoteReports />,
  },
  {
    type: "collapse",
    name: "Administradores",
    key: "administradores",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/administradores",
    component: (
      <AuthGuard>
        <AdminList />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Novo Administrador",
    key: "new-admin",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/administradores/:id",
    component: (
      <AuthGuard>
        <AdminCreate />
      </AuthGuard>
    ),
  },
  {
    type: "hidden",
    name: "Novo Administrador",
    key: "new-admin",
    icon: <AssignmentIndIcon fontSize="small" />,
    route: "/administradores/novo",
    component: (
      <AuthGuard>
        <AdminCreate />
      </AuthGuard>
    ),
  },
];

export default routes;
